import React, { useEffect, useState } from 'react'
import $ from "jquery";
import EditIcon from '../../../../assets/icons/EditIcon';
import CustomSnackbar from '../../../../utilities/SnackBar';
import ModalDialog from '../../../../utilities/ModalDialog';
import { MenuItem, Switch, Tab, TextField } from '@mui/material';
import axios from 'axios';
import { SpinnerPulse } from '../../../../utilities/SpinnerPulse';
import TabList from '@mui/lab/TabList'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'


export default function UserList() {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#userListTable").DataTable();
  });
  // For Initialize DataTable End ----------------


  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')

  const [allUsers, setAllUsers] = useState([]);
  const [inputUser, setInputUser] = useState({ name: '', email: '', role: 'UDDOKTA' });

  const [editableUser, setEditableUser] = useState({});
  const [inputPassword, setInputPassword] = useState({})

  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [tabIndex, setTabIndex] = useState('1');

  // console.log(allUsers)

  // create user
  const createUser = () => {
    setLoading(true);
    axios.post('/api/chairman/create-user', inputUser).then(res => {
      if (res.status === 200) {
        getAllUsers();
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenAddUserModal(false)
        setInputUser({ name: '', email: '', role: 'user' })
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // get all users
  const getAllUsers = () => {
    setLoading(true);
    axios.get('/api/chairman/all-users').then(res => {
      if (res.status === 200) {
        setAllUsers(res.data.users)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // update user
  const updateUser = () => {
    setLoading(true);
    axios.put('/api/chairman/update-user', editableUser).then(res => {
      if (res.status === 200) {
        getAllUsers();
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenEditUserModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // update password
  const updatePassword = () => {
    if (inputPassword.password !== inputPassword.confirm_password) {
      setError('Password does not match')
      return;
    }
    setLoading(true);
    axios.put(`/api/chairman/update-password/${editableUser.id}`, inputPassword).then(res => {
      if (res.status === 200) {
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setInputPassword({})
        setOpenEditUserModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    getAllUsers();
  }, [])

  return (
    <div className="tab-pane fade" id="nav-userList" role="tabpanel" aria-labelledby="nav-contact-tab">


      <div className="mt-1 py-3 rounded main-bg">

        {/* add new user button */}
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 ml-auto">
            <button type="button" className="btn btn-block btn-lightblue" onClick={() => setOpenAddUserModal(true)}>
              <i class="fa fa-plus mr-2"></i> নতুন ইউজার তৈরি করুন
            </button>
          </div>
        </div>

        <div className="table-responsive py-4">
          {allUsers.length > 0 ?
            <table id="userListTable" className="table table-striped" width="100%;">
              <thead>
                <tr>
                  <th scope="col" className="text-center">ক্রমিক</th>
                  <th scope="col">নাম</th>
                  <th scope="col">ইমেইল</th>
                  <th scope="col">ভূমিকা</th>
                  <th scope="col" className="text-center">অবস্থা</th>
                  <th scope="col" className="text-center actionTable">একশন</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{user.name}</td>
                      <td><a href={`mailto:${user.email}`} target='_blank' rel="noreferrer">{user.email}</a></td>
                      <td>{user.role === 'UDDOKTA' ? 'উদ্যোক্তা' : 'রেজিস্টার অপারেটর'}</td>
                      <td className={`text-center ${parseInt(user.status) === 1 ? 'text-success' : 'text-danger'}`}>
                        {parseInt(user.status) === 1 ? 'চলমান' : 'স্থগিত'}
                      </td>
                      <td className="text-center">
                        <div className="">
                          <button type="button" className="btn-icon btn-outline-lightblue dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            Action
                          </button>
                          <ul className="dropdown-menu">
                            {/* <li>
                              <NavLink to="#" target="_blank" className="dropdown-item" data-toggle="modal" data-target="#exampleModalCenter">
                                <EyeIcon /> View
                              </NavLink>
                            </li> */}
                            <li>
                              <button className="dropdown-item" onClick={() => {
                                setOpenEditUserModal(true); setEditableUser(user);
                              }}>
                                <EditIcon /> Edit
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            : <SpinnerPulse color={'dark'} />}
        </div>

      </div>
      {/* ---------------- Tab Content End ---------------- */}



      {/* create user modal */}
      <ModalDialog
        title={'নতুন ইউজার তৈরি করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="নাম" fullWidth value={inputUser.name}
              onChange={(e) => setInputUser({ ...inputUser, name: e.target.value })} margin='normal' size='small' />

            <TextField label="ইমেইল" fullWidth value={inputUser.email}
              onChange={(e) => setInputUser({ ...inputUser, email: e.target.value })} margin='normal' size='small' />

            <TextField label="ভূমিকা" select fullWidth value={inputUser.role}
              onChange={(e) => setInputUser({ ...inputUser, role: e.target.value })} margin='normal' size='small'>
              <MenuItem value='UDDOKTA'>উদ্যোক্তা</MenuItem>
              <MenuItem value='REGISTER_OPERATOR'>রেজিস্টার অপারেটর</MenuItem>
            </TextField>
          </div>
        }
        onOpen={openAddUserModal}
        onClose={() => setOpenAddUserModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={createUser}
        loading={loading}
      />


      {/* edit user modal */}
      <ModalDialog
        title={'ইউজার আপডেট'}
        onOpen={openEditUserModal}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TabContext value={tabIndex}>
              <TabList onChange={(_e, val) => { setTabIndex(val) }} className='mb-3 bg-light rounded'>
                <Tab label="মৌলিক তথ্য" value='1' className='w-50' />
                <Tab label="পাসওয়ার্ড" value='2' className='w-50' />
              </TabList>

              <TabPanel value='1' className='p-0'>
                <TextField label="নাম" fullWidth value={editableUser.name}
                  onChange={(e) => setEditableUser({ ...editableUser, name: e.target.value })} margin='normal' size='small' />

                <TextField label="ইমেইল" fullWidth value={editableUser.email}
                  onChange={(e) => setEditableUser({ ...editableUser, email: e.target.value })} margin='normal' size='small' />

                <TextField label="ভূমিকা" select fullWidth value={editableUser.role}
                  onChange={(e) => setEditableUser({ ...editableUser, role: e.target.value })} margin='normal' size='small'>
                  <MenuItem value='UDDOKTA'>উদ্যোক্তা</MenuItem>
                  <MenuItem value='REGISTER_OPERATOR'>রেজিস্টার অপারেটর</MenuItem>
                </TextField>

                {/* status switch */}
                <Switch checked={parseInt(editableUser.status) === 1 ? true : false}
                  onChange={(e) => setEditableUser({ ...editableUser, status: e.target.checked ? 1 : 0 })} />
                <label htmlFor="status">{parseInt(editableUser.status) === 1 ? 'চলমান' : 'স্থগিত'}</label>
              </TabPanel>

              <TabPanel value='2' className='p-0'>
                <TextField label="নতুন পাসওয়ার্ড" fullWidth value={inputPassword.password}
                  onChange={(e) => setInputPassword({ ...inputPassword, password: e.target.value })} margin='normal' size='small' type='password' />

                <TextField label="নতুন পাসওয়ার্ড আবার লিখুন" fullWidth value={inputPassword.confirm_password}
                  onChange={(e) => setInputPassword({ ...inputPassword, confirm_password: e.target.value })} margin='normal' size='small' type='password' />
              </TabPanel>
            </TabContext>
          </div>
        }
        onClose={() => setOpenEditUserModal(false)}
        confirmText={tabIndex === '1' ? 'তথ্য আপডেট' : 'পাসওয়ার্ড আপডেট'}
        onConfirm={tabIndex === '1' ? updateUser : updatePassword}
        loading={loading}
      />


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </div>
  )
}
