import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const TaxCollectionAdd = () => {

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const [taxMembers, setTaxMembers] = useState([]);

  let selectedMemberOptions = {
    id: "", holding_no: "", name_of_khana_prodhan: "", fathers_name: "", mothers_name: "",
    village: { name: "" }, word: { word: "" }, phone: ""
  }
  const [selectedTaxMember, setSelectedTaxMember] = useState(selectedMemberOptions);

  const [economicYears, setEconomicYears] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  // calculation of total tax
  let totalTax = (selectedTaxMember.house_type_of_tax_member?.map((houseType) => {
    return parseInt(houseType.situation === "করযোগ্য" ? houseType.house_tax.tax : 0)
  }).reduce((acc, curr) => acc + curr, 0)
    + parseInt(selectedTaxMember.collected_taxes_sum_due_amount | 0)
    + selectedTaxMember.business_of_tax_member?.map((businessType) => {
      return parseInt(businessType.business_tax.tax)
    }).reduce((acc, curr) => acc + curr, 0))
    * (selectedTaxMember.economic_years_count ? selectedTaxMember.economic_years_count : 1);

  let collectOptions = {
    tax_member_id: '', economic_year_id: '', paid_amount: '', due_amount: '', payment_method_id: '', comment: '', payment_date: convertDate(new Date())
  };
  const [taxCollectionInfo, setTaxCollectionInfo] = useState(collectOptions);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTaxCollectionInfo({ ...taxCollectionInfo, [name]: value });
  };

  const handleVoterIDChange = (e) => {
    const member = taxMembers.find((member) => member.id === e.value);
    setSelectedTaxMember(member);
    setTaxCollectionInfo({
      ...taxCollectionInfo,
      tax_member_id: member.id,
      economic_year_id: economicYears[0]?.id,
      payment_method_id: paymentMethods.find((method) => method.name === 'ক্যাশ')?.id,
    });
  };

  // console.log(selectedTaxMember)

  // get all tax members
  const getAllTaxMembers = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/tax-member`).then(res => {
      if (res.status === 200) {
        setTaxMembers(res.data.tax_members)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // get all years
  const getEconomicYears = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/economic-year`).then(res => {
      if (res.status === 200) {
        setEconomicYears(res.data.economic_years)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // get all payment methods
  const getPaymentMethods = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/payment-method`).then((res) => {
      if (res.status === 200) {
        setPaymentMethods(res.data.payment_methods);
      }
    }).catch((err) => {
      setError(err.message);
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false);
    });
  }, [role])

  // submit tax collection
  const submitTaxCollection = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post(`/api/${role}/tax-collection`, taxCollectionInfo).then(res => {
      if (res.status === 200) {
        setSuccess(res.data.message);
        getAllTaxMembers();
        setTaxCollectionInfo(collectOptions);
        setSelectedTaxMember(selectedMemberOptions);
        setTimeout(() => { setSuccess('') }, 5000);
      } else {
        setError(res.data.message);
        setTimeout(() => { setError('') }, 5000);
      }
    }).catch(err => {
      setError(err.response.data.message);
      setTimeout(() => { setError('') }, 5000);
    }).finally(() => {
      setLoading(false);
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getAllTaxMembers();
      getEconomicYears();
      getPaymentMethods();
    }
  }, [getAllTaxMembers, getEconomicYears, getPaymentMethods, role]);

  const options = taxMembers.map((member, index) => ({
    value: member.id,
    label: `${member.nid ?? ''} - ${member.name_of_khana_prodhan}`,
  }));


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">কর আদায় ফরম</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">কর আদায় ফরম</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/tax-collection-list`); }}>
                  সবগুলো দেখুন
                </button>
              </div>
            </div>


            {/* Search by NID no */}
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label htmlFor="text">ভোটার আইডি নং</label>
                  <Select options={options} onChange={handleVoterIDChange} />
                </div>
              </div>
            </div>


            {/* Tax member info */}
            <div className="row mt-5 p-3 rounded main-bg">
              {/* bsic information of tax member */}
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">হোল্ডিং নং</label>
                  <input type="text" className="form-control" id="text" placeholder="হোল্ডিং নং"
                    value={selectedTaxMember.holding_no} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">খানা প্রধানের নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="খানা প্রধানের নাম"
                    value={selectedTaxMember.name_of_khana_prodhan} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পিতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="পিতার নাম"
                    value={selectedTaxMember.fathers_name} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">মাতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="মাতার নাম"
                    value={selectedTaxMember.mothers_name} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">গ্রাম/মহল্লা</label>
                  <input type="text" className="form-control" id="text" placeholder="গ্রাম/মহল্লা"
                    value={selectedTaxMember.village?.name} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ওয়ার্ড নং</label>
                  <input type="text" className="form-control" id="text" placeholder="ওয়ার্ড নং"
                    value={selectedTaxMember.word?.word} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">মোবাইল নং</label>
                  <input type="text" className="form-control" id="text" placeholder="মোবাইল নং"
                    value={selectedTaxMember.phone} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">কর টাকা (নিয়মিত)</label>
                  <input type="text" className="form-control" id="text" placeholder="কর টাকা"
                    value={(totalTax - parseInt(selectedTaxMember.collected_taxes_sum_due_amount | 0))
                      / (selectedTaxMember.economic_years_count ? selectedTaxMember.economic_years_count : 1)}
                    readOnly />
                </div>
              </div>


              {/* tax collection part */}
              {/* fillable forms section */}
              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center font-weight-bolder">
                  সর্বমোট কর ৳{totalTax}
                  <small>(বকেয়াসহ)</small>
                </h4>
              </div>

              {/* economic year */}
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">অর্থ বছর</label>
                  <select className="form-control" name="economic_year_id" value={taxCollectionInfo.economic_year_id} onChange={handleChange}>
                    <option value="">অর্থ বছর নির্বাচন করুন</option>
                    {economicYears.map((year, index) => (
                      <option key={index} value={year.id}>{year.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              {/* paid amount */}
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পরিশোধিত টাকার পরিমাণ</label>
                  <input type="number" className="form-control" id="text" placeholder="পরিশোধিত টাকার পরিমাণ"
                    name="paid_amount" value={taxCollectionInfo.paid_amount} onChange={(e) => {
                      if (e.target.value > totalTax) {
                        alert('পরিশোধিত টাকার পরিমাণ মোট কর-এর চেয়ে বেশি হতে পারে না');
                        return;
                      }
                      setTaxCollectionInfo({ ...taxCollectionInfo, paid_amount: e.target.value, due_amount: totalTax - e.target.value | 0 });
                    }} />
                </div>
              </div>

              {/* due amount */}
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">বকেয়া টাকার পরিমাণ</label>
                  <input type="text" className="form-control" id="text" placeholder="বকেয়া টাকার পরিমাণ"
                    name="due_amount" value={taxCollectionInfo.due_amount} readOnly />
                </div>
              </div>

              {/* date */}
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">তারিখ</label>
                  <input type="date" className="form-control" id="text" placeholder="তারিখ"
                    name="payment_date" value={taxCollectionInfo.payment_date} onChange={handleChange} />
                </div>
              </div>

              {/* payment method */}
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">পরিশোধের মাধ্যম</label>
                  <select className="form-control" name="payment_method_id" value={taxCollectionInfo.payment_method_id} onChange={handleChange}>
                    <option value="">নির্বাচন করুন</option>
                    {paymentMethods.map((method, index) => (
                      <option key={index} value={method.id}>{method.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              {/* comment */}
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label htmlFor="text">মন্তব্য (যদি থাকে)</label>
                  <textarea type="text" className="form-control" id="text" placeholder="মন্তব্য (যদি থাকে)"
                    name="comment" value={taxCollectionInfo.comment} onChange={handleChange} />
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4"></div>

              {/* submit button */}
              <div className="col-lg-4 col-md-4 col-sm-4">
                <button type="button" className="btn btn-block btn-lightblue"
                  onClick={submitTaxCollection} disabled={loading | selectedTaxMember.id === undefined}>
                  {loading ? <SpinnerPulse /> : 'জমা দিন'}
                </button>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4"></div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>



      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );


  // convert date function
  function convertDate(inputDate) {
    const date = new Date(inputDate);

    // Get the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');

    // Assemble the formatted date
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
};

export default TaxCollectionAdd;
