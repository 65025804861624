import React, { useCallback, useEffect, useState } from 'react'
import GalleryAddIcon from "../../../../assets/icons/GalleryAddIcon";
import axios from 'axios';
import { SpinnerPulse } from '../../../../utilities/SpinnerPulse';
import config from '../../../../config';
import CustomSnackbar from '../../../../utilities/SnackBar';

export default function AccountSettings() {

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')
  const [userDetails, setUserDetails] = useState({})

  // For Image Show Start ----------------
  const [image, setImage] = useState(null);

  const onImageChange = (event) => {
    setUserDetails({ ...userDetails, photo: event.target.files[0] })
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      document.getElementById("previewImage").style.cssText =
        " display: block !important;";
      document.getElementById("hideElem1").style.cssText =
        " display: none !important;";
      document.getElementById("hideElem2").style.cssText =
        " display: none !important;";
      document.getElementById("showElem1").style.cssText =
        " display: block !important;";
    }
  };
  // For Image Show End ----------------

  // console.log(userDetails)

  // get user details
  const getUser = useCallback(() => {
    axios.get(`/api/${role}/profile`).then(res => {
      if (res.status === 200) {
        setUserDetails(res.data.user)
        if (res.data.user.photo !== null) {
          setImage(`${config.baseUrl}/storage/${res.data.user.photo}`)
          document.getElementById("previewImage").style.cssText =
            " display: block !important;";
          document.getElementById("hideElem1").style.cssText =
            " display: none !important;";
          document.getElementById("hideElem2").style.cssText =
            " display: none !important;";
          document.getElementById("showElem1").style.cssText =
            " display: block !important;";
        }
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    });
  }, [role])

  // update user
  const updateUser = (e) => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append('name', userDetails.name)
    formData.append('address', userDetails.address)
    formData.append('nid', userDetails.nid)
    formData.append('phone', userDetails.phone)
    formData.append('other_info', userDetails.other_info)
    formData.append('photo', userDetails.photo)
    axios.post(`/api/${role}/profile`, formData).then(res => {
      if (res.status === 200) {
        localStorage.setItem('user', JSON.stringify(res.data.user))
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        window.location.reload()
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getUser();
    }
  }, [getUser, role])


  return (
    <div className="tab-pane fade show active" id="nav-accountSetting" role="tabpanel" aria-labelledby="nav-home-tab">
      {/* ---------------- Tab Content Start ---------------- */}
      <form onSubmit={updateUser}>
        <div className="row mt-5">

          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="form-group">
              <label htmlFor="text">আপনার ছবি</label>
              <div className="customFile">
                <div className="customFileIcon" id="hideElem1">
                  <GalleryAddIcon />
                </div>

                <input type="file" onChange={onImageChange} className="inputFile" id="customFile" accept="image/*" />
                <label className="customFileLebel font-roboto font-weight-normal" id="hideElem2" htmlFor="customFile">
                  Upload Your Photo
                </label>

                <img src={image} className="previewFile d-none" id="previewImage" alt="preview" />
                <label className="customFileLebel2 font-roboto font-weight-normal d-none" id="showElem1" htmlFor="customFile">
                  Change Your Photo
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6"></div>


          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="form-group">
              <label htmlFor="text12">নাম</label>
              <input type="text" className="form-control" id="text12" value={userDetails.name}
                onChange={e => setUserDetails({ ...userDetails, name: e.target.value })} />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="form-group">
              <label htmlFor="text10">ঠিকানা</label>
              <input type="text" className="form-control" id="text10" value={userDetails.address}
                onChange={e => setUserDetails({ ...userDetails, address: e.target.value })} />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="form-group">
              <label htmlFor="text11">ভোটার আইডি নং</label>
              <input type="text" className="form-control" id="text11" value={userDetails.nid}
                onChange={e => setUserDetails({ ...userDetails, nid: e.target.value })} />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="form-group">
              <label htmlFor="text9">মোবাইল</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">+880</span>
                </div>
                <input type="text" className="form-control" id="text9" value={userDetails.phone}
                  onChange={e => setUserDetails({ ...userDetails, phone: e.target.value })} />
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
              <label htmlFor="text8">বিস্তারিত</label>
              <textarea type="text" className="form-control" id="text8" value={userDetails.other_info}
                onChange={e => setUserDetails({ ...userDetails, other_info: e.target.value })} />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4"></div>
          <div className="col-lg-4 col-md-4 col-sm-4 mt-3">
            <button type="submit" className="btn btn-block btn-lightblue" disabled={loading}>
              {loading ? <SpinnerPulse /> : 'জমা দিন'}
            </button>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
      </form>
      {/* ---------------- Tab Content End ---------------- */}



      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </div>
  )
}
