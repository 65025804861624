import React from "react";

const HeirIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.12491 0C0.501635 0 0 0.405531 0 0.909017V19.091C0 19.5946 0.501846 20 1.12491 20H16.8751C17.4984 20 18 19.5945 18 19.091V0.909017C18 0.40536 17.4982 0 16.8751 0H1.12491ZM2.53118 3.63647H4.78125V5.45471H2.53118V3.63647ZM7.31243 4.09098H14.9063C15.217 4.09098 15.4687 4.29454 15.4687 4.54549C15.4687 4.79661 15.2168 5 14.9063 5H7.31243C7.00167 5 6.74998 4.79644 6.74998 4.54549C6.74998 4.29437 7.00188 4.09098 7.31243 4.09098ZM2.53118 7.27275H4.78125V9.09098H2.53118V7.27275ZM7.31243 7.72725H14.9063C15.217 7.72725 15.4687 7.93081 15.4687 8.18176C15.4687 8.43288 15.2168 8.63627 14.9063 8.63627H7.31243C7.00167 8.63627 6.74998 8.43271 6.74998 8.18176C6.74998 7.93064 7.00188 7.72725 7.31243 7.72725ZM2.53118 10.909H4.78125V12.7273H2.53118V10.909ZM7.31243 11.3635H14.9063C15.217 11.3635 15.4687 11.5671 15.4687 11.818C15.4687 12.0692 15.2168 12.2725 14.9063 12.2725H7.31243C7.00167 12.2725 6.74998 12.069 6.74998 11.818C6.74998 11.5669 7.00188 11.3635 7.31243 11.3635ZM2.53118 14.5453H4.78125V16.3635H2.53118V14.5453ZM7.31243 14.9998H14.9063C15.217 14.9998 15.4687 15.2034 15.4687 15.4543C15.4687 15.7054 15.2168 15.9088 14.9063 15.9088H7.31243C7.00167 15.9088 6.74998 15.7053 6.74998 15.4543C6.74998 15.2032 7.00188 14.9998 7.31243 14.9998Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
      </svg>
    </>
  );
};

export default HeirIcon;
