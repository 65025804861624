import React from "react";

const DeleteIcon = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.33337 4.08337H11.6667"
          stroke="#47B5FD"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="iconBGColors"
        />
        <path
          d="M5.83337 6.41663V9.91663"
          stroke="#47B5FD"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="iconBGColors"
        />
        <path
          d="M8.16663 6.41663V9.91663"
          stroke="#47B5FD"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="iconBGColors"
        />
        <path
          d="M2.91663 4.08337L3.49996 11.0834C3.49996 11.3928 3.62288 11.6895 3.84167 11.9083C4.06046 12.1271 4.35721 12.25 4.66663 12.25H9.33329C9.64271 12.25 9.93946 12.1271 10.1583 11.9083C10.377 11.6895 10.5 11.3928 10.5 11.0834L11.0833 4.08337"
          stroke="#47B5FD"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="iconBGColors"
        />
        <path
          d="M5.25 4.08333V2.33333C5.25 2.17862 5.31146 2.03025 5.42085 1.92085C5.53025 1.81146 5.67862 1.75 5.83333 1.75H8.16667C8.32138 1.75 8.46975 1.81146 8.57915 1.92085C8.68854 2.03025 8.75 2.17862 8.75 2.33333V4.08333"
          stroke="#47B5FD"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="iconBGColors"
        />

        <defs>
          <clipPath id="clip0_51_3894">
            <rect width="14" height="14" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default DeleteIcon;
