import React from "react";

const BeneficiaryIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.30005 0H11.7V1.33329H6.30005V0Z" className="iconBGColor" fill="#9A9AA9" />
        <path
          d="M16.1998 0H13.4998V1.33329C13.4998 2.06654 12.6898 2.66657 11.6999 2.66657H6.3C5.31013 2.66657 4.5001 2.06654 4.5001 1.33329V0H1.80015C0.810278 0 0.000244141 0.600036 0.000244141 1.33329V16.6669H17.9998V1.33329C17.9998 0.600036 17.1897 0 16.1999 0L16.1998 0ZM6.82611 12.8761L5.13637 14.1277C4.96066 14.2579 4.73032 14.3231 4.5 14.3231C4.26967 14.3231 4.03953 14.2579 3.86363 14.1277L2.96359 13.461C2.61197 13.2006 2.61197 12.7787 2.96359 12.5184C3.31521 12.258 3.8847 12.2579 4.23618 12.5184L4.49985 12.7137L5.55322 11.9334C5.90484 11.6729 6.47433 11.6729 6.82581 11.9334C7.17724 12.1938 7.17743 12.6157 6.82581 12.8761L6.82611 12.8761ZM6.82611 9.54278L5.13637 10.7945C4.96066 10.9246 4.73032 10.9898 4.5 10.9898C4.26967 10.9898 4.03953 10.9246 3.86363 10.7945L2.96359 10.1277C2.61197 9.86728 2.61197 9.44543 2.96359 9.18507C3.31521 8.92475 3.8847 8.92461 4.23618 9.18507L4.49985 9.38038L5.55322 8.6001C5.90484 8.33963 6.47433 8.33963 6.82581 8.6001C7.17724 8.86056 7.17743 9.28242 6.82581 9.54278L6.82611 9.54278ZM6.82611 6.2095L5.13637 7.46118C4.96066 7.59134 4.73032 7.65649 4.5 7.65649C4.26967 7.65649 4.03953 7.59134 3.86363 7.46118L2.96359 6.79446C2.61197 6.534 2.61197 6.11214 2.96359 5.85179C3.31521 5.59146 3.8847 5.59132 4.23618 5.85179L4.49985 6.0471L5.55322 5.26681C5.90484 5.00635 6.47433 5.00635 6.82581 5.26681C7.17724 5.52728 7.17743 5.94914 6.82581 6.20949L6.82611 6.2095ZM14.4 13.6566H9.00005C8.50302 13.6566 8.1 13.3581 8.1 12.9899C8.1 12.6218 8.50304 12.3232 9.00005 12.3232H14.4C14.8973 12.3232 15.3 12.6218 15.3 12.9899C15.3 13.3581 14.8975 13.6566 14.4 13.6566ZM14.4 10.3234H9.00005C8.50302 10.3234 8.1 10.0248 8.1 9.65664C8.1 9.28846 8.50304 8.98993 9.00005 8.98993H14.4C14.8973 8.98993 15.3 9.28848 15.3 9.65664C15.3 10.0248 14.8975 10.3234 14.4 10.3234ZM14.4 6.99007H9.00005C8.50302 6.99007 8.1 6.69152 8.1 6.32336C8.1 5.95518 8.50304 5.65664 9.00005 5.65664H14.4C14.8973 5.65664 15.3 5.95519 15.3 6.32336C15.3 6.69154 14.8975 6.99007 14.4 6.99007Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
        <path
          d="M0 18H18V18.6667C18 19.4 17.19 20 16.2001 20H1.80019C0.810323 20 0.000289122 19.4 0.000289122 18.6667V18H0Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
      </svg>
    </>
  );
};

export default BeneficiaryIcon;
