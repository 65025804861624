import React, { useCallback, useEffect, useState } from "react";
import GovBDLogo from "../../../assets/images/GovBDLogo.png";
import QRCode from 'qrcode.react';
import "../../../assets/css/TradeLicenseBanglaPrint.scss";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import { useLocalStorage } from "usehooks-ts";
import config from "../../../config";

const TradeLicenseBanglaPrint = () => {

  const license_id = useLocation().state.id;
  const [userDetails] = useLocalStorage('user', {})

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const [licenseInfo, setLicenseInfo] = useState({});

  // console.log(licenseInfo);

  // get information of tradelicense by id
  const getTradeLicenseInfo = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/trade-license/${license_id}`).then(res => {
      if (res.status === 200) {
        setLicenseInfo(res.data.trade_license)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false);
    })
  }, [license_id, role])


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getTradeLicenseInfo();
    }
  }, [getTradeLicenseInfo, role]);


  const Print = () => {
    document.getElementById("printBtn").style.cssText = " display: none;";
    document.getElementById("closetBtn").style.cssText = " display: none;";
    window.print();
    document.getElementById("printBtn").style.cssText = " display: block;";
    document.getElementById("closetBtn").style.cssText = " display: block;";
  };

  // NavBar Scroll Start ----------------
  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() { }
  // NavBar Scroll End ----------------

  return (
    <>
      {loading ? <SpinnerPulse color={'dark'} />
        :
        <div className="row main-bg p-5 m-0 watermark border-gray"
          style={{ backgroundImage: `url(${config.baseUrl}/storage/${userDetails.union?.logo})` }}>
          <div className="col-lg-12 col-md-12 col-sm-12 p-2">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 p-1">
                <button type="button" className="btn btn-block btn-danger" onClick={() => navigate(-1)} id="closetBtn">
                  ফিরে যান
                </button>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
              <div className="col-lg-2 col-md-2 col-sm-2 p-1">
                <button type="button" className="btn btn-block btn-lightblue" onClick={Print} id="printBtn">
                  প্রিন্ট
                </button>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-left">
            <img src={GovBDLogo} alt="GovBDLogo" style={{ width: "100px" }} className="" />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8 p-2 text-center">
            <h4 className="main-color-green">
              গণপ্রজাতন্ত্রী বাংলাদেশ সরকার ( স্থানীয় সরকার বিভাগ )
            </h4>
            <h1 className="font-weight-bolder main-color-red">
              {userDetails.union.bn_name} কার্যালয়
            </h1>
            <h4 className="main-color-green">
              উপজেলাঃ {userDetails.union?.upazila}, জেলাঃ {userDetails.union?.district}
            </h4>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-right">
            {userDetails.union?.logo &&
              <img src={`${config.baseUrl}/storage/${userDetails.union?.logo}`} alt="union logo" style={{ width: "100px" }} className="" />}
          </div>


          {/* QR code and owner's photo */}
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4 col-sm-4 p-2 text-left">
                <QRCode
                  value={
                    `${userDetails.union?.bn_name} | লাইসেন্স নং - TRAD/${licenseInfo.license_no} | মালিকের নাম: ${licenseInfo.owners_name} | প্রতিষ্ঠানের নাম: ${licenseInfo.business_name} | ইস্যুর তারিখ: ${new Date(licenseInfo.issue_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric' })} | মেয়াদ: ${new Date(licenseInfo.renewed_trade_license ? licenseInfo.renewed_trade_license.expiry_date : licenseInfo.expiry_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric' })}${licenseInfo.renewed_trade_license ? ` | সর্বশেষ নবায়ন: ${new Date(licenseInfo.renewed_trade_license.renew_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric' })}` : ''}`
                  }
                  size={150}
                  fgColor="#000000"
                  bgColor="#ffffff"
                // includeMargin={true}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-2 text-center border-rl-4-black">
                <h1 className="font-weight-bolder">ই-ট্রেড লাইসেন্স</h1>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-2 text-right">
                <img src={`${config.baseUrl}/storage/${licenseInfo.photo}`} alt="PhotoTradeLicense" style={{ width: "150px" }} className="" />
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <h3 className="mt-4">
              <span className="font-weight-bolder">লাইসেন্স নং -</span>{" "}
              <span className="font-poppins">TRAD/{licenseInfo.license_no}</span>
            </h3>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 p-2 text-left">
            <h5 className="">
              ইস্যুর তারিখঃ {new Date(licenseInfo.issue_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric' })}
            </h5>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 p-2 text-right">
            <h5 className="">অর্থ বছরঃ {licenseInfo.renewed_trade_license ? licenseInfo.renewed_trade_license.economic_year?.name : licenseInfo.economic_year?.name}</h5>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4"></div>


          {/* __________________Owners information________________ */}
          <div className="col-lg-12 col-md-12 col-sm-12 table-responsive border-gray">
            <table className="table table-sm table-borderless mb-4 mt-4" width="100%;">
              <tbody>
                <tr className="vendorListHeading">
                  <td className="actionTable2">মালিকের নাম</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="actionTable">{licenseInfo.owners_name}</td>
                  <td className="text-center actionTable"> </td>
                  <td className="actionTable2">এন আই ডি</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="actionTable">{licenseInfo.nid}</td>
                </tr>
                <tr className="vendorListHeading">
                  <td className="">পিতার নাম</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.fathers_name}</td>
                  <td className="text-center actionTable"> </td>
                  <td className="">গ্রাম</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.village?.name}</td>
                </tr>
                <tr className="vendorListHeading">
                  <td className="">মাতার নাম</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.mothers_name}</td>
                  <td className="text-center actionTable"> </td>
                  <td className="">জেলা</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{userDetails.union.district}</td>
                </tr>
                <tr className="vendorListHeading">
                  <td className="">লিঙ্গ</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.gender}</td>
                  <td className="text-center actionTable"> </td>
                  <td className="">উপজেলা</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{userDetails.union.upazila}</td>
                </tr>
                <tr className="vendorListHeading">
                  <td className="">মোবাইল নম্বর</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.phone}</td>
                  <td className="text-center actionTable"> </td>
                  <td className="">ডাকঘর</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.post_office}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4"></div>


          {/* ___________________Business Information___________________ */}
          <div className="col-lg-12 col-md-12 col-sm-12 table-responsive border-gray">
            <table className="table table-sm table-borderless mb-4 mt-4" width="100%;">
              <tbody>
                <tr className="vendorListHeading">
                  <td className="actionTable2">প্রতিষ্ঠানের নাম</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="actionTable">{licenseInfo.business_name}</td>
                  <td className="text-center actionTable"> </td>
                  <td className="actionTable2"></td>
                  <td className="text-center actionTable3"></td>
                  <td className="actionTable"></td>
                </tr>
                <tr className="vendorListHeading">
                  <td className="">ব্যবসার ধরণ</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.business_type}</td>
                  <td className="text-center actionTable"> </td>
                  <td className="">উপজেলা</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.business_upazila}</td>
                </tr>
                <tr className="vendorListHeading">
                  <td className="">প্রতিষ্ঠানের ঠিকানা</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.business_village}</td>
                  <td className="text-center actionTable"> </td>
                  <td className="">ডাকঘর</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.business_post_office}</td>
                </tr>
                <tr className="vendorListHeading">
                  <td className="">জেলা</td>
                  <td className="text-center actionTable3">:</td>
                  <td className="">{licenseInfo.business_district}</td>
                  <td className="text-center actionTable"> </td>
                  <td className=""></td>
                  <td className="text-center"></td>
                  <td className=""></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4"></div>


          {/* __________________Fee information________________ */}
          <div className="col-lg-5 col-md-5 col-sm-5 table-responsive p-0">
            <table className="table table-sm table-bordered b-gray-1 m-0" width="100%;">
              <tbody>
                <tr className="vendorListHeading3">
                  <td className="text-center actionTable2">লাইসেন্স ফি</td>
                  <td className="text-center actionTable3 border-r-0">৳</td>
                  <td className="text-right border-l-0">
                    {licenseInfo.license_fee}
                  </td>
                </tr>
                <tr className="vendorListHeading3">
                  <td className="text-center">ভ্যাট</td>
                  <td className="text-center actionTable3 border-r-0">৳</td>
                  <td className="text-right border-l-0">
                    {licenseInfo.license_vat}
                  </td>
                </tr>
                <tr className="vendorListHeading3">
                  <td className="text-center">মূলধন কর</td>
                  <td className="text-center actionTable3 border-r-0">৳</td>
                  <td className="text-right border-l-0">
                    {licenseInfo.capital_tax}
                  </td>
                </tr>
                <tr className="vendorListHeading3">
                  <td className="text-center">মোট</td>
                  <td className="text-center actionTable3 border-r-0">৳</td>
                  <td className="text-right border-l-0">
                    {parseInt(licenseInfo.license_fee) + parseInt(licenseInfo.license_vat) + parseInt(licenseInfo.capital_tax)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2"></div>
          <div className="col-lg-5 col-md-5 col-sm-5">
            <h5 className="text-center border-gray p-3">
              অনুমোদিত মূলধনঃ ৳ {licenseInfo.renewed_trade_license ? licenseInfo.renewed_trade_license.permitted_capital : licenseInfo.permitted_capital}
            </h5>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <h3 className="font-weight-bolder mt-5 mb-3">
              লাইসেন্সের মেয়াদ {new Date(
                licenseInfo.renewed_trade_license ? licenseInfo.renewed_trade_license.expiry_date : licenseInfo.expiry_date
              ).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric' })} তারিখ পর্যন্ত বিদ্যমান
            </h3>
            {licenseInfo.renewed_trade_license && <p>সর্বশেষ নবায়নের তারিখঃ {
              new Date(licenseInfo.renewed_trade_license.renew_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric' })}</p>}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>

          <div className="col-lg-6 col-md-6 col-sm-6 p-2 mt-5 text-left">
            <h5 className="font-weight-bolder">ইউপি সচিব - স্বাক্ষর ও সীল</h5>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 p-2 mt-5 text-right">
            <h5 className="font-weight-bolder">চেয়ারম্যান - স্বাক্ষর ও সীল</h5>
          </div>
        </div>
      }


      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );


  // function getTwoYearsAfterDate(inputDate) {
  //   if (!inputDate) return '';

  //   // Split the inputDate into year, month, and day
  //   const [year, month, day] = inputDate.split('-');
  //   // Create a new Date object with the input date
  //   const currentDate = new Date(year, month - 1, day);
  //   // Add 2 years to the current date
  //   currentDate.setFullYear(currentDate.getFullYear() + 2);

  //   // Extract the year, month, and day from the new date
  //   const newYear = currentDate.getFullYear();
  //   let newMonth = currentDate.getMonth() + 1; // Months are zero-indexed
  //   let newDay = currentDate.getDate();

  //   // Ensure month and day are two digits
  //   newMonth = newMonth < 10 ? `0${newMonth}` : newMonth;
  //   newDay = newDay < 10 ? `0${newDay}` : newDay;

  //   // Return the new date string in the format 'YYYY-MM-DD'
  //   return `${newYear}-${newMonth}-${newDay}`;
  // }
};

export default TradeLicenseBanglaPrint;
