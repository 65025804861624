import React from "react";

const BettermentProjectIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 0V0.562572H17.3288V13.6171H18V14.1797H14.2353V17.4375L15.7949 19.4062L15.9547 19.6094L15.6223 20L15.4625 19.7968L14.2351 18.2501V19.8828H13.7748V18.2501L12.5412 19.7968L12.3814 20L12.049 19.6094L12.2088 19.4062L13.7748 17.4375V14.1797H4.23174V17.4375L5.79135 19.4062L5.95758 19.6094L5.62513 20L5.45891 19.7968L4.23157 18.2501V19.8828H3.77127V18.2501L2.53126 19.7968L2.37146 20L2.03902 19.6015L2.19882 19.3984L3.77129 17.4375V14.1797H0V13.6171H0.671161V0.562581H0V8.85528e-06L18 0ZM12.4262 1.32023L12.0298 1.60935L12.1512 1.85157L12.7136 2.97649L12.9054 3.35925L13.1036 2.98434L13.3976 2.42176L13.5255 2.17955L13.1355 1.87491L13.0077 2.11712L12.9182 2.29673L12.5474 1.55457L12.426 1.3202L12.4262 1.32023ZM11.2756 1.98427H2.11566V2.54684H11.2756V1.98427ZM12.4262 3.31235L12.0298 3.60915L12.1512 3.84352L12.7136 4.96844L12.9054 5.35905L13.1036 4.97628L13.3976 4.41371L13.5255 4.17934L13.1355 3.86685L13.0077 4.10907L12.9182 4.28868L12.5474 3.55437L12.426 3.31216L12.4262 3.31235ZM11.2756 3.99211H2.11566V4.55468H11.2756V3.99211ZM12.4262 5.14823L12.0298 5.43736L12.1512 5.67957L12.7136 6.8045L12.9054 7.19511L13.1036 6.81234L13.3976 6.24977L13.5255 6.00756L13.1355 5.70291L13.0077 5.94513L12.9182 6.12474L12.5474 5.39043L12.426 5.14822L12.4262 5.14823ZM11.2756 5.84348H2.11566V6.40605H11.2756V5.84348ZM3.37507 7.53097H2.11577V12.5621H3.37507V7.53097ZM9.63267 8.39032H8.37337V12.5622H9.63267V8.39032ZM12.7712 8.5232H11.5119V12.5622H12.7712V8.5232ZM6.50044 9.3592H5.24114V12.5624H6.50044V9.3592ZM15.9033 10.4139H14.644V12.5622H15.9033V10.4139Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
      </svg>
    </>
  );
};

export default BettermentProjectIcon;
