import React from "react";
import { Outlet } from "react-router-dom";
import UserTopNav from "./TopNav";
import Footer from "./Footer";
import UserSideNav from "./SideNav";

const UserLayout = () => {
  return (
    <div>
      <UserTopNav />
      <UserSideNav />

      <Outlet />

      <Footer />
    </div>
  );
};

export default UserLayout;
