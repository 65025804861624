import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts';
import CustomSnackbar from '../../../../utilities/SnackBar';
import { SpinnerPulse } from '../../../../utilities/SpinnerPulse';

export default function LoginSecuritySettings() {

  const [passwords, setPasswords] = useState({
    oldPass: '',
    newPass: '',
    reNewPass: '',
  });
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [loading, setLoading] = useState()
  const [role, setRole] = useState('')
  const [userDetails] = useLocalStorage('user', {})

  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [passwordShown3, setPasswordShown3] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };
  const togglePassword3 = () => {
    setPasswordShown3(!passwordShown3);
  };
  // For Password Show End ----------------


  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwords.newPass !== passwords.reNewPass) {
      setError('Password does not match')
      return;
    }
    setLoading(true);

    axios.post(`/api/${role}/change-password`, passwords).then(res => {
      if (res.status === 200) {
        setSuccess(res.data.message)
        setPasswords({ oldPass: '', newPass: '', reNewPass: '' })
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  };


  useEffect(() => {
    setRole(localStorage.getItem('role'));
  }, [role]);


  return (
    <div className="tab-pane fade" id="nav-loginSecurity" role="tabpanel" aria-labelledby="nav-profile-tab">
      {/* ---------------- Tab Content Start ---------------- */}
      <div className="row mt-5">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label htmlFor="text">বর্তমান পাসওয়ার্ড</label>
                <div className="input-group">
                  <input type={passwordShown ? "text" : "password"} className="form-control" id="text1" placeholder="বর্তমান পাসওয়ার্ড"
                    value={passwords.oldPass} onChange={(e) => setPasswords({ ...passwords, oldPass: e.target.value })} />
                  <span className="input-group-append">
                    <button type="button" className="btn btn-secondary" onClick={togglePassword}>
                      <i className={passwordShown ? "fas fa-eye" : "fas fa-eye-slash"}></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label htmlFor="text">নতুন পাসওয়ার্ড</label>
                <div className="input-group">
                  <input type={passwordShown2 ? "text" : "password"} className="form-control" id="text2" placeholder="নতুন পাসওয়ার্ড"
                    value={passwords.newPass} onChange={(e) => setPasswords({ ...passwords, newPass: e.target.value })} />
                  <span className="input-group-append">
                    <button type="button" className="btn btn-secondary" onClick={togglePassword2}>
                      <i className={passwordShown2 ? "fas fa-eye" : "fas fa-eye-slash"}></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group input-group ">
                <label htmlFor="text">আবার পাসওয়ার্ড দিন</label>
                <div className="input-group">
                  <input type={passwordShown3 ? "text" : "password"} className="form-control" id="text3" placeholder="আবার পাসওয়ার্ড দিন"
                    value={passwords.reNewPass} onChange={(e) => setPasswords({ ...passwords, reNewPass: e.target.value })} />
                  <span className="input-group-append">
                    <button type="button" className="btn btn-secondary" onClick={togglePassword3}>
                      <i className={passwordShown3 ? "fas fa-eye" : "fas fa-eye-slash"}></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="form-group">
            <label htmlFor="text">বর্তমান ইমেইল</label>
            <input type="text" className="form-control" id="text4" placeholder="বর্তমান ইমেইল" value={userDetails.email} readOnly />
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-4"></div>
        <div className="col-lg-4 col-md-4 col-sm-4">
          <button type="button" className="btn btn-block btn-lightblue" disabled={loading} onClick={handleSubmit}>
            {loading ? <SpinnerPulse /> : 'জমা দিন'}
          </button>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4"></div>
      </div>
      {/* ---------------- Tab Content End ---------------- */}



      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </div>
  )
}
