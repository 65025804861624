import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import EyeIcon from "../../../assets/icons/EyeIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import $ from "jquery";
import axios from "axios";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import ModalDialog from "../../../utilities/ModalDialog";
import { TextField } from "@mui/material";

const CertificateListByType = () => {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#certificateListTable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const certificateType = useLocation().state.type;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [certificates, setCertificates] = useState([]);
  const [deletableCertificate, setDeletableCertificate] = useState({});
  const [deleteCertificateInput, setDeleteCertificateInput] = useState('');
  const [openDeleteCertificateModal, setOpenDeleteCertificateModal] = useState(false);

  // get certificates
  const getCertificates = useCallback(() => {
    setLoading(true)
    axios.get(`/api/${role}/certificates/${certificateType.id}`).then(res => {
      if (res.status === 200) {
        setCertificates(res.data.certificates)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [certificateType.id, role])

  // delete category
  const deleteCertificate = () => {
    setLoading(true)
    axios.delete(`/api/${role}/certificate/${deletableCertificate.id}`).then(res => {
      if (res.status === 200) {
        getCertificates()
        setSuccess(res.data.message)
        setOpenDeleteCertificateModal(false)
        setDeleteCertificateInput('')
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getCertificates();
    }
  }, [getCertificates, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">সদস্যদের তালিকা</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">সদস্যদের তালিকা</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={() => navigate(`${role === 'user' ? '' : '/chairman'}/certificate-add`)}>
                  + যোগ করুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
                {certificates.length > 0 ?
                  <table id="certificateListTable" className="table table-striped" width="100%;">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ক্রমিক</th>
                        <th scope="col">নাম</th>
                        <th scope="col">বাবার নাম</th>
                        <th scope="col">এনআইডি নং</th>
                        <th scope="col">গ্রাম</th>
                        <th scope="col" className="text-center actionTable">একশন</th>
                      </tr>
                    </thead>
                    <tbody>
                      {certificates.map((certificate, index) => (
                        <tr key={certificate.id}>
                          <td className="text-center">{index + 1}</td>
                          <td>{certificate.applicants_name}</td>
                          <td>{certificate.fathers_name}</td>
                          <td>{certificate.nid}</td>
                          <td>{certificate.village?.name}</td>
                          <td className="text-center">
                            <div className="">
                              <button type="button" className="btn-icon btn-outline-lightblue dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                Action
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link to={`${role === 'user' ? '' : '/chairman'}/certificate-types/list/print`} state={{ id: certificate.id }} className="dropdown-item">
                                    <EyeIcon /> View
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`${role === 'user' ? '' : '/chairman'}/certificate-types/list/edit`} state={{ id: certificate.id }} className="dropdown-item">
                                    <EditIcon /> Edit
                                  </Link>
                                </li>
                                <li>
                                  <div className="dropdown-item" onClick={() => { setDeletableCertificate(certificate); setOpenDeleteCertificateModal(true) }}>
                                    <DeleteIcon /> Delete
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  : loading ? <SpinnerPulse color={'dark'} />
                    : 'কোনো তথ্য পাওয়া যায় নি'}
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* delete certificate modal */}
      <ModalDialog
        title={`প্রত্যয়ন ডিলেট করতে চান?`}
        content={
          <div className='mt-2' style={{ maxWidth: '350px' }}>
            <p className='mb-4 text-danger'>{"এই প্রক্রিয়াটি একবার সম্পন্ন হয়ে গেলে আর পূর্বাবস্থায় ফিরাতে পারবেন না!"}</p>

            {/* type the username to delete account */}
            <p className='mb-2'>নিশ্চিত করতে নিচের ইনপুট ফিল্ডে <b>{deletableCertificate.applicants_name}</b> টাইপ করুন।</p>
            <TextField placeholder={deletableCertificate.applicants_name} type="text" value={deleteCertificateInput}
              onChange={(e) => setDeleteCertificateInput(e.target.value)} fullWidth size='small' />
          </div>
        }
        onOpen={openDeleteCertificateModal}
        onClose={() => { setOpenDeleteCertificateModal(false); setDeleteCertificateInput(''); setDeletableCertificate({}) }}
        confirmText={'ডিলিট করুন'}
        actionColor={'error'}
        disabledAction={deleteCertificateInput !== deletableCertificate.applicants_name}
        onConfirm={deleteCertificate}
        loading={loading}
      />


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default CertificateListByType;
