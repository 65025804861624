import React from "react";
import { NavLink } from "react-router-dom";
import AccountSettings from "../../../../chairman/views/settings/software_settings/AccountSettings";
import LoginSecuritySettings from "../../../../chairman/views/settings/software_settings/LoginSecuritySettings";
import PaymentSettings from "../../../../chairman/views/settings/software_settings/PaymentSettings";
import { useLocalStorage } from "usehooks-ts";

const UserSettingsSoftware = () => {

  const [userDetails] = useLocalStorage('user', {})

  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">সফটওয়্যার সেটিংস</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">সফটওয়্যার সেটিংস</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12">

                {/* ---------------- All Tab Buttons ---------------- */}
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-home-tab" data-toggle="tab" data-target="#nav-accountSetting"
                    type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                    একাউন্ট সেটিং
                  </button>
                  <button className="nav-link" id="nav-profile-tab" data-toggle="tab" data-target="#nav-loginSecurity"
                    type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                    লগিন এবং সিকিউরিটি
                  </button>

                  {userDetails.role && userDetails.role === 'UDDOKTA' &&
                    <>
                      <button className="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#nav-paymentSetting"
                        type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
                        পেমেন্ট সেটিং
                      </button>
                    </>
                  }
                </div>


                <div className="tab-content" id="nav-tabContent">
                  {/* ---------------- Account settings ---------------- */}
                  <AccountSettings />


                  {/* ---------------- Login Security Settings ---------------- */}
                  <LoginSecuritySettings />


                  {/* ---------------- Payment settings ---------------- */}
                  {userDetails.role && userDetails.role === 'UDDOKTA' &&
                    <PaymentSettings />
                  }

                </div>
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>
    </>
  );
};

export default UserSettingsSoftware;
