import React from "react";

const ReportIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.2 3.59998C5.77598 3.59998 4.38393 4.02225 3.1999 4.81339C2.01586 5.60454 1.09302 6.72903 0.548071 8.04465C0.00311962 9.36028 -0.139464 10.808 0.138349 12.2046C0.416163 13.6013 1.1019 14.8842 2.10883 15.8911C3.11577 16.8981 4.39869 17.5838 5.79535 17.8616C7.19202 18.1394 8.6397 17.9969 9.95532 17.4519C11.271 16.907 12.3954 15.9841 13.1866 14.8001C13.9777 13.616 14.4 12.224 14.4 10.8C14.4 10.3029 13.9971 9.89997 13.5 9.89997H9C8.50295 9.89997 8.1 9.49703 8.1 8.99998V4.49998C8.1 4.00292 7.69706 3.59998 7.2 3.59998Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
        <path
          d="M10.8001 0C10.5615 0 10.3325 0.0948211 10.1638 0.263604C9.99497 0.432387 9.90015 0.661305 9.90015 0.9V7.2C9.90015 7.4387 9.99497 7.66761 10.1638 7.8364C10.3325 8.00518 10.5615 8.1 10.8001 8.1L17.1001 8.1C17.3388 8.1 17.5678 8.00518 17.7365 7.83639C17.9053 7.66761 18.0001 7.43869 18.0001 7.2C18.0001 5.29044 17.2416 3.45909 15.8913 2.10883C14.5411 0.758569 12.7097 0 10.8001 0Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
        <path
          d="M10.8001 0C10.5615 0 10.3325 0.0948211 10.1638 0.263604C9.99497 0.432387 9.90015 0.661305 9.90015 0.9V7.2C9.90015 7.4387 9.99497 7.66761 10.1638 7.8364C10.3325 8.00518 10.5615 8.1 10.8001 8.1L17.1001 8.1C17.3388 8.1 17.5678 8.00518 17.7365 7.83639C17.9053 7.66761 18.0001 7.43869 18.0001 7.2C18.0001 5.29044 17.2416 3.45909 15.8913 2.10883C14.5411 0.758569 12.7097 0 10.8001 0Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
      </svg>
    </>
  );
};

export default ReportIcon;
