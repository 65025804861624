import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocalStorage } from "usehooks-ts";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const HeirApplicationView = () => {

  const application_id = useLocation().state.id;
  const [userDetails] = useLocalStorage('user', {})

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [role, setRole] = useState('')
  const navigate = useNavigate();

  const [heirInfo, setHeirInfo] = useState({});

  // get heir info
  const getHeirInfo = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/heir-application/${application_id}`).then(res => {
      if (res.status === 200) {
        setHeirInfo(res.data.heir_info)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [application_id, role]);


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getHeirInfo();
    }
  }, [getHeirInfo, role]);

  const Print = () => {
    document.getElementById("printBtn").style.cssText = " display: none;";
    document.getElementById("closetBtn").style.cssText = " display: none;";
    window.print();
    document.getElementById("printBtn").style.cssText = " display: block;";
    document.getElementById("closetBtn").style.cssText = " display: block;";
  };


  return (
    <>
      {loading ? <SpinnerPulse color={'dark'} />
        :
        <div className="row main-bg p-5 m-0 border-gray">
          <div className="col-lg-12 col-md-12 col-sm-12 p-2">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 p-1">
                <button type="button" className="btn btn-block btn-danger" onClick={() => navigate(-1)} id="closetBtn">
                  ফিরে যান
                </button>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
              <div className="col-lg-2 col-md-2 col-sm-2 p-1">
                <button type="button" className="btn btn-block btn-lightblue" onClick={Print} id="printBtn">
                  প্রিন্ট
                </button>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 p-2">
            <p>তারিখঃ {new Date(heirInfo.created_at).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
            <p className="pb-2"></p>
            <p className="m-0 mt-5">মাননীয়,</p>
            <p className="m-0">চেয়ারম্যান</p>
            <p className="m-0">{userDetails.union?.bn_name}</p>
            <p className="m-0">{userDetails.union?.address}, {`${userDetails.union?.upazila}, ${userDetails.union?.district}।`}</p>
            <p className="pb-5"></p>
            <p className="mt-5">বিষয়ঃ ওয়ারিশান সনদ পত্রের জন্য আবেদন পত্র।</p>
            <p className="pb-2"></p>
            <p className="mt-5">জনাব,</p>
            <p>
              বিনীত নিবেদন এই যে, আমি {heirInfo.applicants_name}, পিতাঃ{" "}
              {heirInfo.fathers_name}, স্বামী/স্ত্রীঃ{" "}
              {heirInfo.spouse_name}, গ্রামঃ {heirInfo.village?.name}, ওয়ার্ড নং{" "}
              {heirInfo.word?.word}, উপজেলা - {heirInfo.upazila}, জেলা -
              {heirInfo.district}, মৃত {heirInfo.name_of_dead}, গ্রামঃ{" "}
              {heirInfo.village_of_dead?.name}, ওয়ার্ড নং {heirInfo.word_of_dead?.word} - এর ওয়ারিশ সনদের জন্য
              আবেদন করছি। মৃত {heirInfo.nameOfDead} মৃত্যুর পর নিম্নোক্ত
              ওয়ারিশ/ওয়ারিশগণ রেখে যান।
            </p>
            <p className="pb-5"></p>
            <p className="mt-5">ওয়ারিশানদের তালিকাঃ</p>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
            <table className="table table-bordered" width="100%;">
              <thead>
                <tr>
                  <th scope="col" className="text-center">ক্রমিক নং</th>
                  <th scope="col">নাম</th>
                  <th scope="col" className="text-center">সম্পর্ক</th>
                  <th scope="col" className="text-center">মন্তব্য</th>
                </tr>
              </thead>
              <tbody>
                {heirInfo.heritors?.map((heritor, index) => (
                  <tr key={index} className="vendorListHeading">
                    <th scope="row" className="text-center">
                      {index + 1}
                    </th>
                    <td>{heritor.heir_name}</td>
                    <td className="text-center">{heritor.relationship}</td>
                    <td className="text-center">{heritor.comment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 p-2">
            <p className="pb-5"></p>
            <p className="mt-5">
              অতএব, সবিনয় নিবেদন এই যে, উল্লেখিত বর্ননা মোতাবেক ওয়ারিশান সনদ
              প্রদানে বাধিত করবেন।
            </p>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>

          <div className="col-lg-6 col-md-6 col-sm-6 p-2 mt-5 pt-5 text-left">
            <h5 className="font-weight-bolder mt-5">নিবেদক</h5>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 p-2 mt-5 pt-5 text-right">
            <h5 className="font-weight-bolder mt-5">সুপারিশকৃত</h5>
          </div>
        </div>
      }

      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default HeirApplicationView;
