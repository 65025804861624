import React, { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import EyeIcon from '../../../../assets/icons/EyeIcon'
import EditIcon from '../../../../assets/icons/EditIcon'
import GalleryAddIcon from '../../../../assets/icons/GalleryAddIcon';
import CustomSnackbar from '../../../../utilities/SnackBar';
import axios from 'axios';
import { SpinnerPulse } from '../../../../utilities/SpinnerPulse';
import config from '../../../../config';

export default function PaymentSettings() {

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [role, setRole] = useState('');

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [inputPaymentMethod, setInputPaymentMethod] = useState({ logo: "", name: "", number: "" });

  // For Image Show Start ----------------
  const [image2, setImage2] = useState(null);

  const onImageChange2 = (event) => {
    setInputPaymentMethod({ ...inputPaymentMethod, logo: event.target.files[0] });
    if (event.target.files && event.target.files[0]) {
      setImage2(URL.createObjectURL(event.target.files[0]));
      document.getElementById("previewImage2").style.cssText =
        " display: block !important;";
      document.getElementById("hideElem3").style.cssText =
        " display: none !important;";
      document.getElementById("hideElem4").style.cssText =
        " display: none !important;";
      document.getElementById("showElem2").style.cssText =
        " display: block !important;";
    }
  };
  // For Image Show End ----------------


  // get all payment methods
  const getPaymentMethods = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/payment-method`).then((res) => {
      if (res.status === 200) {
        setPaymentMethods(res.data.payment_methods);
      }
    }).catch((err) => {
      setError(err.message);
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false);
    });
  }, [role])

  // add payment method
  const addPaymentMethod = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("logo", inputPaymentMethod.logo);
    formData.append("name", inputPaymentMethod.name);
    formData.append("number", inputPaymentMethod.number);
    axios.post(`/api/${role}/payment-method`, formData).then((res) => {
      if (res.status === 200) {
        getPaymentMethods();
        setSuccess(res.data.message);
        setInputPaymentMethod({ logo: "", name: "", number: "" });
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message);
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch((err) => {
      setError(err.message);
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getPaymentMethods();
    }
  }, [getPaymentMethods, role]);


  return (
    <div className="tab-pane fade" id="nav-paymentSetting" role="tabpanel" aria-labelledby="nav-contact-tab"    >
      {/* ---------------- Tab Content Start ---------------- */}
      <div className="row mt-5">
        <div className="col-lg-6 col-md-6 col-sm-6 border-right">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
              <h4 className="text-center">পেমেন্ট মেথড তালিকা</h4>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
              <table className="table table-striped" width="100%;">
                <thead>
                  <tr>
                    <th scope="col" className="text-center">ক্রমিক</th>
                    <th scope="col" className="text-center">লোগো</th>
                    <th scope="col" className="text-center">নাম</th>
                    <th scope="col">একাউন্ট নাম্বার</th>
                    <th scope="col" className="text-center actionTable">একশন</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentMethods.length > 0 ?
                    paymentMethods.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center"><img src={`${config.baseUrl}/storage/${item.logo}`} className="" style={{ height: "40px" }} alt="LogoImage" /></td>
                        <td className="text-center">{item.name}</td>
                        <td>{item.number}</td>
                        <td className="text-center">
                          <div className="">
                            <button type="button" className="btn-icon btn-outline-lightblue dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                              Action
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <NavLink to="/vw" target="_blank" className="dropdown-item">
                                  <EyeIcon /> View
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/vw" className="dropdown-item">
                                  <EditIcon /> Edit
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))
                    :
                    <tr>
                      <td colSpan={5} className="text-center">
                        {loading ? <SpinnerPulse color={'dark'} /> : 'কোন তথ্য পাওয়া যায়নি'}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>



        {/*--------------------- add new payment method ---------------------*/}
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="row m-1">
            <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
              <h4 className="text-center">
                নতুন পেমেন্ট মেথড যোগ করুন
              </h4>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="form-group">
                <label htmlFor="text">ছবি/লগো</label>
                <div className="customFile">
                  <div className="customFileIcon" id="hideElem3">
                    <GalleryAddIcon />
                  </div>
                  <input type="file" onChange={onImageChange2} className="inputFile" />
                  <label className="customFileLebel font-roboto font-weight-normal" id="hideElem4" htmlFor="customFile">
                    Upload Your Photo
                  </label>
                  <img src={image2} className="previewFile d-none" id="previewImage2" alt="preview" />
                  <label className="customFileLebel2 font-roboto font-weight-normal d-none" id="showElem2" htmlFor="customFile">
                    Change Your Photo
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
              <img src={image2} className="previewFile d-none" id="previewImage2" alt="preview" />
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label htmlFor="text">পেমেন্ট মেথড</label>
                <input type="text" className="form-control" id="name" placeholder="মেথডের নাম" value={inputPaymentMethod.name}
                  onChange={(e) => setInputPaymentMethod({ ...inputPaymentMethod, name: e.target.value })} />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <label htmlFor="text">একাউন্ট নাম্বার</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">+880</span>
                  </div>
                  <input type="text" className="form-control" id="number" placeholder="আপনার একাউন্ট নাম্বার" value={inputPaymentMethod.number}
                    onChange={(e) => setInputPaymentMethod({ ...inputPaymentMethod, number: e.target.value })} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4"></div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <button type="button" className="btn btn-block btn-lightblue" disabled={loading}
                onClick={addPaymentMethod}>{loading ? <SpinnerPulse /> : 'জমা দিন'}</button>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4"></div>
          </div>
        </div>
      </div>
      {/* ---------------- Tab Content End ---------------- */}



      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </div>
  )
}
