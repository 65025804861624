import React, { useCallback, useEffect, useState } from "react";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import EyeIcon from "../../../assets/icons/EyeIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import axios from "axios";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import CustomSnackbar from "../../../utilities/SnackBar";
import ModalDialog from "../../../utilities/ModalDialog";
import { TextField } from "@mui/material";

const TaxMemberList = () => {
  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#taxMemberListTable").DataTable();
  });
  // For Initialize DataTable End ----------------


  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')
  const navigate = useNavigate();

  const [taxMembers, setTaxMembers] = useState([]);

  const [deletableMember, setDeletableMember] = useState({});
  const [deleteMemberInput, setDeleteMemberInput] = useState('')
  const [showMemberDeleteModal, setShowMemberDeleteModal] = useState(false)

  // console.log(taxMembers)

  // get all tax members
  const getAllTaxMembers = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/tax-member`).then(res => {
      if (res.status === 200) {
        setTaxMembers(res.data.tax_members)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // delete tax member
  const deleteTaxMember = () => {
    setLoading(true);
    axios.delete(`/api/${role}/tax-member/${deletableMember.id}`).then(res => {
      if (res.status === 200) {
        getAllTaxMembers();
        setSuccess(res.data.message);
        setShowMemberDeleteModal(false);
        setDeletableMember({});
        setDeleteMemberInput('');
        setTimeout(() => { setSuccess('') }, 5000);
      } else {
        setError(res.data.message);
        setTimeout(() => { setError('') }, 5000);
      }
    }).catch(err => {
      setError(err.response.data.message);
      setTimeout(() => { setError('') }, 5000);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getAllTaxMembers();
    }
  }, [getAllTaxMembers, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">সদস্যদের তালিকা</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">হোম</Link>
                  </li>
                  <li className="breadcrumb-item active">সদস্যদের তালিকা</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue"
                  onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/add-tax-member`); }}>
                  + যোগ করুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive py-4 px-3">
                {taxMembers.length > 0 ?
                  <table id="taxMemberListTable" className="table table-striped" width="100%;">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ক্রমিক</th>
                        <th scope="col">হোল্ডিং নং</th>
                        <th scope="col">নাম</th>
                        <th scope="col">বাবার নাম</th>
                        <th scope="col">এনআইডি নং</th>
                        <th scope="col">গ্রাম</th>
                        <th scope="col" className="text-center actionTable">একশন</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taxMembers.map((taxMember, index) => (
                        <tr key={taxMember.id}>
                          <td className="text-center">{index + 1}</td>
                          <td>{taxMember.holding_no}</td>
                          <td>{taxMember.name_of_khana_prodhan}</td>
                          <td>{taxMember.fathers_name}</td>
                          <td>{taxMember.nid}</td>
                          <td>{taxMember.village?.name}</td>
                          <td className="text-center">
                            <div className="">
                              <button type="button" className="btn-icon btn-outline-lightblue dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                Action
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link to={`${role === 'user' ? '' : '/chairman'}/tax-member-list/profile`} state={{ taxMember: taxMember }} className="dropdown-item" >
                                    <EyeIcon /> View
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`${role === 'user' ? '' : '/chairman'}/tax-member-list/edit`} state={{ taxMember: taxMember }} className="dropdown-item">
                                    <EditIcon /> Edit
                                  </Link>
                                </li>
                                <li>
                                  <button className="dropdown-item" onClick={() => { setDeletableMember(taxMember); setShowMemberDeleteModal(true) }}>
                                    <DeleteIcon /> Delete
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  : loading ? <SpinnerPulse color={'dark'} />
                    : 'কোনো সদস্য পাওয়া যায় নি'}
              </div>

            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* delete tax member modal */}
      <ModalDialog
        title={`করদাতা সদস্য ডিলেট করতে চান?`}
        content={
          <div className='mt-2' style={{ maxWidth: '350px' }}>
            <p className='mb-4 text-danger'>{"এই প্রক্রিয়াটি একবার সম্পন্ন হয়ে গেলে আর পূর্বাবস্থায় ফিরাতে পারবেন না এবং এই সদস্যের সকল কর আদায়ের রেকর্ড ডিলেট হয়ে যাবে!"}</p>

            {/* type the username to delete account */}
            <p className='mb-2'>নিশ্চিত করতে নিচের ইনপুট ফিল্ডে <b>{deletableMember.name_of_khana_prodhan}</b> টাইপ করুন।</p>
            <TextField placeholder={deletableMember.name_of_khana_prodhan} type="text" value={deleteMemberInput}
              onChange={(e) => setDeleteMemberInput(e.target.value)} fullWidth size='small' />
          </div>
        }
        onOpen={showMemberDeleteModal}
        onClose={() => { setShowMemberDeleteModal(false); setDeleteMemberInput(''); setDeletableMember({}) }}
        confirmText={'ডিলিট করুন'}
        actionColor={'error'}
        disabledAction={deleteMemberInput !== deletableMember.name_of_khana_prodhan}
        onConfirm={deleteTaxMember}
        loading={loading}
      />


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default TaxMemberList;
