import React, { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

export default function UserTopNav() {

  const [userDetails] = useLocalStorage('user', {})

  const [theme, setTheme] = useState("sidebar-mini layout-fixed layout-navbar-fixed light-mode sidebar-open");

  const toggleTheme = () => {
    if (theme === "sidebar-mini layout-fixed layout-navbar-fixed dark-mode sidebar-open") {
      setTheme("sidebar-mini layout-fixed layout-navbar-fixed light-mode sidebar-open");
    } else {
      setTheme("sidebar-mini layout-fixed layout-navbar-fixed dark-mode sidebar-open");
    }
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light" id="navBar">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <button className="btn nav-link" data-widget="pushmenu">
              <i className="fas fa-bars" />
            </button>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <span className="nav-link text-dark navHeadA">
              {userDetails.union?.bn_name}
            </span>
          </li>
        </ul>


        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <button className="btn nav-link" data-widget="fullscreendd" onClick={() => toggleTheme()}>
              <i className="fas fa-moon"></i>
            </button>
          </li>
          <li className="nav-item">
            <button className="btn nav-link" data-widget="fullscreen">
              <i className="fas fa-expand-arrows-alt"></i>
            </button>
          </li>


          {/* Notifications Dropdown Menu */}
          {/* <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell" />
              <span className="badge badge-warning navbar-badge">15</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">
                15 Notifications
              </span>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2" /> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-users mr-2" /> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-file mr-2" /> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item dropdown-footer">
                See All Notifications
              </a>
            </div>
          </li> */}
        </ul>
      </nav>
    </div>
  );
}
