import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const CertificateTypes = () => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [role, setRole] = useState('')
  const navigate = useNavigate();

  const [certificateTypes, setCertificateTypes] = useState([]);

  // get all certificate category
  const getAllCertificateCategory = useCallback(() => {
    setLoading(true)
    axios.get(`/api/${role}/certificate-category`).then(res => {
      if (res.status === 200) {
        setCertificateTypes(res.data.categories)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getAllCertificateCategory();
    }
  }, [getAllCertificateCategory, role]);

  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">প্রত্যয়নপত্রের তালিকা</h1>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row mb-2">
              {certificateTypes.length > 0 ?
                certificateTypes.map((certificateType, index) => (
                  <div className="col-lg-3 col-6 pb-2">
                    <div className="NavLink" onClick={() => navigate(`${role === 'user' ? '' : '/chairman'}/certificate-types/list`, { state: { type: certificateType } })}>
                      <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 circle-link-lightblue">
                        <div className="circle-div">
                          <div className="main-color-lightblue text-center font-50">
                            {certificateType.title}
                          </div>
                        </div>
                        {/* <div className="circle-div">
                        <div className="main-color-lightblue pt-3">সনদপত্র</div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                ))
                : loading ? <SpinnerPulse color={'dark'} />
                  : 'কোনো তথ্য পাওয়া যায় নি'}

            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default CertificateTypes;
