import React from "react";

const PlusIcon = () => {
  return (
    <>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 46C21 48.2091 22.7909 50 25 50C27.2091 50 29 48.2091 29 46V29H46C48.2091 29 50 27.2091 50 25C50 22.7909 48.2091 21 46 21H29V4C29 1.79086 27.2091 0 25 0C22.7909 0 21 1.79086 21 4V21H4C1.79086 21 0 22.7909 0 25C0 27.2091 1.79086 29 4 29H21V46Z"
          className="iconBGColor"
          fill="white"
        />
      </svg>
    </>
  );
};

export default PlusIcon;
