import React from 'react'
import { useNavigate } from 'react-router-dom';
import GovBDLogo from "../../../assets/images/GovBDLogo.png";
import config from '../../../config';

export const SingleTaxPrintPage = (props) => {

  const { taxMember, userDetails, economicYear } = props;
  const navigate = useNavigate();

  let totalTax = taxMember.house_type_of_tax_member?.map((houseType) => {
    return parseInt(houseType.situation === "করযোগ্য" ? houseType.house_tax.tax : 0)
  }).reduce((acc, curr) => acc + curr, 0)
    + taxMember.business_of_tax_member?.map((businessType) => {
      return parseInt(businessType.business_tax.tax)
    }).reduce((acc, curr) => acc + curr, 0);

  // console.log(taxMember)

  const Print = () => {
    document.getElementById("printBtn").style.cssText = " display: none;";
    document.getElementById("closetBtn").style.cssText = " display: none;";
    window.print();
    document.getElementById("printBtn").style.cssText = " display: block;";
    document.getElementById("closetBtn").style.cssText = " display: block;";
  };

  const Close = () => {
    navigate(-1);
    window.close();
  };



  return (
    <>
      <div className="row main-bg p-5 m-0 watermark2"
        style={{ backgroundImage: `url(${config.baseUrl}/storage/${userDetails.union?.logo})` }}>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 p-2">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 p-1">
                <button type="button" className="btn btn-block btn-danger" onClick={Close} id="closetBtn">
                  ফিরে যান
                </button>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
              <div className="col-lg-2 col-md-2 col-sm-2 p-1">
                <button type="button" className="btn btn-block btn-lightblue" onClick={Print} id="printBtn">
                  প্রিন্ট
                </button>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-left">
            <img src={GovBDLogo} alt="GovBDLogo" style={{ width: "100px" }} className="" />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8 p-2 text-center">
            <h4 className="main-color-green">
              গণপ্রজাতন্ত্রী বাংলাদেশ সরকার ( স্থানীয় সরকার বিভাগ )
            </h4>
            <h1 className="font-weight-bolder main-color-red">
              {userDetails.union?.bn_name} কার্যালয়
            </h1>
            <h4 className="main-color-green">
              উপজেলাঃ {userDetails.union?.upazila}, জেলাঃ{" "}
              {userDetails.union?.district}
            </h4>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-right">
            {userDetails.union?.logo &&
              <img src={`${config.baseUrl}/storage/${userDetails.union?.logo}`} alt="union logo" style={{ width: "100px" }} className="" />}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 border-b-4-black pl-5 pr-5"></div>

          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <h3 className="main-color-green font-weight-bolder mt-4">
              বসত-বাড়ির কর বিল
            </h3>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-5 text-left">
            <div className="row pb-4">
              <div className="col-lg-5 col-md-5 col-sm-5 text-center">
                <button type="button" class="btn btn-block btn-success main-bg-green">
                  অফিস কপি
                </button>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-7 text-center"></div>
            </div>

            <h5 className="">{taxMember.name_of_khana_prodhan}</h5>
            <h5 className="">পিতাঃ {taxMember.fathers_name}</h5>
            <h5 className="">
              গ্রামঃ {taxMember.village?.name}, ওয়ার্ড নং- {taxMember.word?.word}
            </h5>
            <h5 className="">
              ডাকঘরঃ {`${taxMember.village?.post_office?.post_code}-${taxMember.village?.post_office?.name}`}, উপজেলাঃ {userDetails.union?.upazila}, জেলাঃ {userDetails.union?.district}।
            </h5>

            <table className="table table-sm table-bordered border-black mt-4" width="100%;">
              <thead>
                <tr className="vendorListHeading">
                  <th scope="col" className="text-center">
                    হোল্ডিং নং
                  </th>
                  <th scope="col" className="text-center">
                    {taxMember.holding_no}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="vendorListHeading">
                  <td className="text-center">ভোটার আইডি নং</td>
                  <td className="text-center">
                    {taxMember.nid}
                  </td>
                </tr>
                <tr className="vendorListHeading">
                  <td className="text-center">মোবাইল নাম্বার</td>
                  <td className="text-center">
                    {taxMember.phone}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row mt-5 pt-4">
              <div className="col-lg-5 col-md-5 col-sm-5 text-center">
                <h5 className="border-top">আদায়কারীর স্বাক্ষর</h5>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-7 text-center">
                <h5 className="border-top">চেয়ারম্যান - স্বাক্ষর ও সীল</h5>
              </div>
            </div>

            <h5 className="main-color-red mt-3">
              পরিষদের বিকাশ নাম্বারঃ {userDetails.union?.phone}
            </h5>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-2 text-right"> </div>

          <div className="col-lg-5 col-md-5 col-sm-5 text-right">
            <h5 className="">
              প্রস্তুতের অর্থ বছরঃ {economicYear?.name} ইং
            </h5>
            <h5 className="">
              বিল প্রস্তুতের তারিখঃ{" "}
              {new Date().toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric', })} ইং
            </h5>
            <h5 className="">
              বিল পরিশোধের শেষ তারিখঃ{" "}
              {new Date(new Date().setDate(new Date().getDate() + 15)).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric', })} ইং
            </h5>

            {/* <table className="table table-sm table-bordered border-black mt-3" width="100%;">
              <thead>
                <tr className="vendorListHeadingC">
                  <th scope="col" colSpan="2" className="text-center">
                    ঘর প্রতি নির্ধারিত কর
                  </th>
                </tr>
              </thead>
              <tbody>
                {houseTaxes.length > 0 ?
                  houseTaxes.map((houseTax, index) => (
                    <tr className="vendorListHeadingC" key={index}>
                      <td className="text-center">{houseTax.house_type}</td>
                      <td className="text-center">{houseTax.tax} টাকা</td>
                    </tr>
                  ))
                  :
                  <tr className="vendorListHeadingC">
                    <td className="text-center" colSpan="2">কোন ঘর প্রতি নির্ধারিত করা হয়নি।</td>
                  </tr>
                }
              </tbody>
            </table> */}

            <table className="table table-sm table-bordered border-black mt-3" width="100%;">
              <thead>
                <tr className="vendorListHeadingC">
                  <th scope="col" colSpan={1 + taxMember.house_type_of_tax_member.length} className="text-center">
                    চলমান অর্থ বছর
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="vendorListHeadingC">
                  <td className="text-center">বাড়ির ধরন</td>
                  {/* <td className="text-center">কাঁচা ঘর</td> */}
                  {taxMember.house_type_of_tax_member.map((houseType, index) =>
                    <td className="text-center" key={index}>{houseType.house_tax?.house_type}</td>
                  )}
                </tr>
                <tr className="vendorListHeadingC">
                  <td className="text-center">রুম সংখ্যা</td>
                  {taxMember.house_type_of_tax_member.map((houseType, index) =>
                    <td className="text-center" key={index}>{houseType.number_of_room}</td>
                  )}
                </tr>
                <tr className="vendorListHeadingC" style={{ borderBottom: '3px solid black' }}>
                  <td className="text-center">নির্ধারিত কর</td>
                  {taxMember.house_type_of_tax_member.map((houseType, index) =>
                    <td className="text-center" key={index}>{houseType.situation === "করযোগ্য" ? houseType.house_tax?.tax : 0} টাকা</td>
                  )}
                </tr>


                <tr className="vendorListHeadingC">
                  <td className="text-center">ব্যবসার ধরন</td>
                  {/* <td className="text-center">কাঁচা ঘর</td> */}
                  {taxMember.business_of_tax_member.map((businessType, index) =>
                    <td className="text-center" key={index}>{businessType.business_tax?.business_type}</td>
                  )}
                </tr>
                <tr className="vendorListHeadingC" style={{ borderBottom: '3px solid black' }}>
                  <td className="text-center">নির্ধারিত কর</td>
                  {taxMember.business_of_tax_member.map((businessType, index) =>
                    <td className="text-center" key={index}>{businessType.business_tax?.tax} টাকা</td>
                  )}
                </tr>


                <tr className="vendorListHeadingC">
                  <td className="text-center">বকেয়া (যদি থাকে)</td>
                  <td className="text-center" colSpan={taxMember.house_type_of_tax_member.length}>{taxMember.collected_taxes_sum_due_amount | 0} টাকা</td>
                </tr>
                <tr className="vendorListHeadingC">
                  <td className="text-center">মোট</td>
                  <td className="text-center" colSpan={taxMember.house_type_of_tax_member.length}>{totalTax + parseInt(taxMember.collected_taxes_sum_due_amount | 0)} টাকা</td>
                </tr>
              </tbody>
            </table>
          </div>


          <div className="col-lg-12 col-md-12 col-sm-12 border-bottom pl-5 pr-5 mt-4 mb-5"></div>





          {/* --------------------- Grahok Copy --------------------- */}

          <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-left">
            <img src={GovBDLogo} alt="GovBDLogo" style={{ width: "100px" }} className="" />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8 p-2 text-center">
            <h4 className="main-color-green">
              গণপ্রজাতন্ত্রী বাংলাদেশ সরকার ( স্থানীয় সরকার বিভাগ )
            </h4>
            <h1 className="font-weight-bolder main-color-red">
              {userDetails.union?.bn_name} কার্যালয়
            </h1>
            <h4 className="main-color-green">
              উপজেলাঃ {userDetails.union?.upazila}, জেলাঃ{" "}
              {userDetails.union?.district}
            </h4>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-right">
            {userDetails.union?.logo &&
              <img src={`${config.baseUrl}/storage/${userDetails.union?.logo}`} alt="union logo" style={{ width: "100px" }} className="" />}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 border-b-4-black pl-5 pr-5"></div>

          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <h3 className="main-color-green font-weight-bolder mt-4">
              বসত-বাড়ির কর বিল
            </h3>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-5 text-left">
            <div className="row pb-4">
              <div className="col-lg-5 col-md-5 col-sm-5 text-center">
                <button type="button" class="btn btn-block btn-success main-bg-green">
                  গ্রাহক কপি
                </button>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-7 text-center"></div>
            </div>

            <h5 className="">{taxMember.name_of_khana_prodhan}</h5>
            <h5 className="">পিতাঃ {taxMember.fathers_name}</h5>
            <h5 className="">
              গ্রামঃ {taxMember.village?.name}, ওয়ার্ড নং- {taxMember.word?.word}
            </h5>
            <h5 className="">
              ডাকঘরঃ {`${taxMember.village?.post_office?.post_code}-${taxMember.village?.post_office?.name}`}, উপজেলাঃ {userDetails.union?.upazila}, জেলাঃ {userDetails.union?.district}।
            </h5>

            <table className="table table-sm table-bordered border-black mt-4" width="100%;">
              <thead>
                <tr className="vendorListHeading">
                  <th scope="col" className="text-center">
                    হোল্ডিং নং
                  </th>
                  <th scope="col" className="text-center">
                    {taxMember.holding_no}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="vendorListHeading">
                  <td className="text-center">ভোটার আইডি নং</td>
                  <td className="text-center">
                    {taxMember.nid}
                  </td>
                </tr>
                <tr className="vendorListHeading">
                  <td className="text-center">মোবাইল নাম্বার</td>
                  <td className="text-center">
                    {taxMember.phone}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row mt-5 pt-4">
              <div className="col-lg-5 col-md-5 col-sm-5 text-center">
                <h5 className="border-top">আদায়কারীর স্বাক্ষর</h5>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-7 text-center">
                <h5 className="border-top">চেয়ারম্যান - স্বাক্ষর ও সীল</h5>
              </div>
            </div>

            <h5 className="main-color-red mt-3">
              পরিষদের বিকাশ নাম্বারঃ {userDetails.union?.phone}
            </h5>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-2 text-right"> </div>

          <div className="col-lg-5 col-md-5 col-sm-5 text-right">
            <h5 className="">
              প্রস্তুতের অর্থ বছরঃ {economicYear?.name} ইং
            </h5>
            <h5 className="">
              বিল প্রস্তুতের তারিখঃ{" "}
              {new Date().toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric', })} ইং
            </h5>
            <h5 className="">
              বিল পরিশোধের শেষ তারিখঃ{" "}
              {new Date(new Date().setDate(new Date().getDate() + 15)).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric', })} ইং
            </h5>

            {/* <table className="table table-sm table-bordered border-black mt-3" width="100%;">
              <thead>
                <tr className="vendorListHeadingC">
                  <th scope="col" colSpan="2" className="text-center">
                    ঘর প্রতি নির্ধারিত কর
                  </th>
                </tr>
              </thead>
              <tbody>
                {houseTaxes.length > 0 ?
                  houseTaxes.map((houseTax, index) => (
                    <tr className="vendorListHeadingC" key={index}>
                      <td className="text-center">{houseTax.house_type}</td>
                      <td className="text-center">{houseTax.tax} টাকা</td>
                    </tr>
                  ))
                  :
                  <tr className="vendorListHeadingC">
                    <td className="text-center" colSpan="2">কোন ঘর প্রতি নির্ধারিত করা হয়নি।</td>
                  </tr>
                }
              </tbody>
            </table> */}

            <table className="table table-sm table-bordered border-black mt-3" width="100%;">
              <thead>
                <tr className="vendorListHeadingC">
                  <th scope="col" colSpan={1 + taxMember.house_type_of_tax_member.length} className="text-center">
                    চলমান অর্থ বছর
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="vendorListHeadingC">
                  <td className="text-center">বাড়ির ধরন</td>
                  {/* <td className="text-center">কাঁচা ঘর</td> */}
                  {taxMember.house_type_of_tax_member.map((houseType, index) =>
                    <td className="text-center" key={index}>{houseType.house_tax?.house_type}</td>
                  )}
                </tr>
                <tr className="vendorListHeadingC">
                  <td className="text-center">রুম সংখ্যা</td>
                  {taxMember.house_type_of_tax_member.map((houseType, index) =>
                    <td className="text-center" key={index}>{houseType.number_of_room}</td>
                  )}
                </tr>
                <tr className="vendorListHeadingC" style={{ borderBottom: '3px solid black' }}>
                  <td className="text-center">নির্ধারিত কর</td>
                  {taxMember.house_type_of_tax_member.map((houseType, index) =>
                    <td className="text-center" key={index}>{houseType.situation === "করযোগ্য" ? houseType.house_tax?.tax : 0} টাকা</td>
                  )}
                </tr>


                <tr className="vendorListHeadingC">
                  <td className="text-center">ব্যবসার ধরন</td>
                  {/* <td className="text-center">কাঁচা ঘর</td> */}
                  {taxMember.business_of_tax_member.map((businessType, index) =>
                    <td className="text-center" key={index}>{businessType.business_tax?.business_type}</td>
                  )}
                </tr>
                <tr className="vendorListHeadingC" style={{ borderBottom: '3px solid black' }}>
                  <td className="text-center">নির্ধারিত কর</td>
                  {taxMember.business_of_tax_member.map((businessType, index) =>
                    <td className="text-center" key={index}>{businessType.business_tax?.tax} টাকা</td>
                  )}
                </tr>


                <tr className="vendorListHeadingC">
                  <td className="text-center">বকেয়া (যদি থাকে)</td>
                  <td className="text-center" colSpan={taxMember.house_type_of_tax_member.length}>{taxMember.collected_taxes_sum_due_amount | 0} টাকা</td>
                </tr>
                <tr className="vendorListHeadingC">
                  <td className="text-center">মোট</td>
                  <td className="text-center" colSpan={taxMember.house_type_of_tax_member.length}>{totalTax + parseInt(taxMember.collected_taxes_sum_due_amount | 0)} টাকা</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
