import React from "react";
import { NavLink } from "react-router-dom";

import '../assets/css/ComingSoon.scss';

const ComingSoon = () => {
  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Coming Soon</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    Coming Soon
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            {/* <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue">
                  + যোগ করুন
                </button>
              </div>
            </div> */}
            <div className="row mt-3 p-3 rounded main-bg bg-min-height">
              <div className="col-lg-12 col-md-12 col-sm-12 p-4 d-flex justify-content-center align-items-center">
                <h2 className="text-center text-uppercase font-montserrat textDesn">Coming <br></br>Soon!</h2>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <p className="text-center font-montserrat">We are currently working hard on this page.</p>
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>
    </>
  );
};

export default ComingSoon;
