import React, { useCallback, useEffect, useState } from 'react'
import $ from "jquery";
import EditIcon from '../../../../assets/icons/EditIcon'
import ModalDialog from '../../../../utilities/ModalDialog';
import { Grid, MenuItem, TextField } from '@mui/material';
import CustomSnackbar from '../../../../utilities/SnackBar';
import axios from 'axios';
import { SpinnerPulse } from '../../../../utilities/SpinnerPulse';

export default function TaxFeeManage() {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#houseTaxTable,#businessTaxTable,#licenseFeeTable,#certificateFeeTable,#warishanCertificateFeeTable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [inputHouseTax, setInputHouseTax] = useState({ house_type: 'পাঁকা ঘর', tax: '' })
  const [inputBsnsTax, setInputBsnsTax] = useState({ business_type: '', tax: '' })
  const [inputLicenseFee, setInputLicenseFee] = useState({ license_type: 'নতুন', fee: '', vat_p: '', capital_tax_p: '', })
  const [houseTaxes, setHouseTaxes] = useState([])
  const [businessTaxes, setBusinessTaxes] = useState([])
  const [licenseFees, setLicenseFees] = useState([])
  const [editableHouseTax, setEditableHouseTax] = useState({})
  const [editableBsnsTax, setEditableBsnsTax] = useState({})
  const [editableLicenseFee, setEditableLicenseFee] = useState({})

  const [openAddHouseTaxModal, setOpenAddHouseTaxModal] = useState(false);
  const [openEditHouseTaxModal, setOpenEditHouseTaxModal] = useState(false);
  const [openAddBsnsTaxModal, setOpenAddBsnsTaxModal] = useState(false);
  const [openEditBsnsTaxModal, setOpenEditBsnsTaxModal] = useState(false);
  const [openAddLicenseFeeModal, setOpenAddLicenseFeeModal] = useState(false);
  const [openEditLicenseFeeModal, setOpenEditLicenseFeeModal] = useState(false);


  // get all houseTaxes
  const getHouseTaxes = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/house-taxes`).then(res => {
      if (res.status === 200) {
        setHouseTaxes(res.data.taxes)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }, [role])

  // get all houseTaxes
  const getBusinessTaxes = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/business-taxes`).then(res => {
      if (res.status === 200) {
        setBusinessTaxes(res.data.taxes)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }, [role])

  // get all license fees
  const getLicenseFees = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/license-fees`).then(res => {
      if (res.status === 200) {
        setLicenseFees(res.data.fees)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }, [role])


  // create house tax
  const createHouseTax = () => {
    setLoading(true);
    axios.post(`/api/${role}/create-house-tax`, inputHouseTax).then(res => {
      if (res.status === 200) {
        getHouseTaxes();
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenAddHouseTaxModal(false)
        setInputHouseTax({ house_type: '', tax: '' })
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }

  // update house tax
  const updateHouseTax = () => {
    setLoading(true);
    axios.put(`/api/${role}/update-house-tax/${editableHouseTax.id}`, editableHouseTax).then(res => {
      if (res.status === 200) {
        getHouseTaxes();
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenEditHouseTaxModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }


  // create business tax
  const createBusinessTax = () => {
    setLoading(true);
    axios.post(`/api/${role}/create-business-tax`, inputBsnsTax).then(res => {
      if (res.status === 200) {
        getBusinessTaxes();
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenAddBsnsTaxModal(false)
        setInputBsnsTax({ business_type: '', tax: '' })
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }

  // update Business tax
  const updateBusinessTax = () => {
    setLoading(true);
    axios.put(`/api/${role}/update-business-tax/${editableBsnsTax.id}`, editableBsnsTax).then(res => {
      if (res.status === 200) {
        getBusinessTaxes();
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenEditBsnsTaxModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }


  // create trade license fee
  const createLicenseFee = () => {
    setLoading(true);
    axios.post(`/api/${role}/create-license-fee`, inputLicenseFee).then(res => {
      if (res.status === 200) {
        getLicenseFees();
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenAddLicenseFeeModal(false)
        setInputLicenseFee({ license_type: '', fee: '', vat_p: '', capital_tax_p: '' })
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }

  // update trade license fee
  const updateLicenseFee = () => {
    setLoading(true);
    axios.put(`/api/${role}/update-license-fee/${editableLicenseFee.id}`, editableLicenseFee).then(res => {
      if (res.status === 200) {
        getLicenseFees();
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenEditLicenseFeeModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getHouseTaxes();
      getBusinessTaxes();
      getLicenseFees();
    }
  }, [getBusinessTaxes, getHouseTaxes, getLicenseFees, role])


  return (
    <div className="tab-pane fade" id="navFeeSetting" role="tabpanel" aria-labelledby="nav-contact-tab">
      {/* ---------------- Tab Content Start ---------------- */}



      <div className="row">

        {/* ---------------- House tax ---------------- */}
        <div className="col-lg-12 col-md-12 col-sm-12 border-gray mt-4">
          <div className="row">

            <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3"></div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h4 className="text-center">বসত বাড়ির কর</h4>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <button type="button" className="btn btn-block btn-lightblue" onClick={() => setOpenAddHouseTaxModal(true)}>
                    + যোগ করুন
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
              {houseTaxes.length > 0 ?
                <table id="houseTaxTable" className="table table-striped" width="100%;">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">ক্রমিক</th>
                      <th scope="col">বসত বাড়ির ধরন</th>
                      <th scope="col" className="text-right">কর</th>
                      <th scope="col" className="text-center actionTable">একশন</th>
                    </tr>
                  </thead>
                  <tbody>
                    {houseTaxes.map((tax, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{tax.house_type}</td>
                          <td className="text-right">{tax.tax}</td>
                          <td className="text-center">
                            <button type="button" className="btn-icon btn-outline-lightblue" onClick={() => { setEditableHouseTax(tax); setOpenEditHouseTaxModal(true) }}>
                              <EditIcon /> Edit
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>

                : loading ? <SpinnerPulse color={'dark'} />
                  : 'কোনো ডেটা পাওয়া যায় নি'}
            </div>

          </div>
        </div>

        {/* ---------------- Business tax ---------------- */}
        <div className="col-lg-12 col-md-12 col-sm-12 border-gray mt-4">
          <div className="row">

            <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3"></div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h4 className="text-center">ব্যবসায়িক কর</h4>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <button type="button" className="btn btn-block btn-lightblue" onClick={() => setOpenAddBsnsTaxModal(true)}>
                    + যোগ করুন
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
              {businessTaxes.length > 0 ?
                <table id="businessTaxTable" className="table table-striped" width="100%;">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">ক্রমিক</th>
                      <th scope="col">ব্যবসার ধরন</th>
                      <th scope="col" className="text-right">কর</th>
                      <th scope="col" className="text-center actionTable">একশন</th>
                    </tr>
                  </thead>
                  <tbody>
                    {businessTaxes.map((tax, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{tax.business_type}</td>
                          <td className="text-right">{tax.tax}</td>
                          <td className="text-center">
                            <button type="button" className="btn-icon btn-outline-lightblue" onClick={() => { setEditableBsnsTax(tax); setOpenEditBsnsTaxModal(true) }}>
                              <EditIcon /> Edit
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>

                : loading ? <SpinnerPulse color={'dark'} />
                  : 'কোনো ডেটা পাওয়া যায় নি'}
            </div>

          </div>
        </div>


        {/* ---------------- trade license fee ---------------- */}
        <div className="col-lg-12 col-md-12 col-sm-12 border-gray mt-4">
          <div className="row">

            <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3"></div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h4 className="text-center">ট্রেড লাইসেন্সের ফি</h4>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <button className="btn btn-block btn-lightblue" onClick={() => setOpenAddLicenseFeeModal(true)}>
                    + যোগ করুন
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
              {licenseFees.length > 0 ?
                <table id="licenseFeeTable" className="table table-striped" width="100%;">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">ক্রমিক</th>
                      <th scope="col">ট্রেড লাইসেন্সের ধরন</th>
                      <th scope="col" className="text-right">ফি (৳)</th>
                      <th scope="col" className="text-right">ভ্যাট (%)</th>
                      <th scope="col" className="text-right">মূলধন কর (%)</th>
                      <th scope="col" className="text-center actionTable">একশন</th>
                    </tr>
                  </thead>
                  <tbody>
                    {licenseFees.map((fee, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{fee.license_type}</td>
                          <td className="text-right">{fee.fee}</td>
                          <td className="text-right">{fee.vat_p}</td>
                          <td className="text-right">{fee.capital_tax_p}</td>
                          <td className="text-center">
                            <button type="button" className="btn-icon btn-outline-lightblue" onClick={() => { setEditableLicenseFee(fee); setOpenEditLicenseFeeModal(true) }}>
                              <EditIcon /> Edit
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>

                : loading ? <SpinnerPulse color={'dark'} />
                  : 'কোনো ডেটা পাওয়া যায় নি'}
            </div>

          </div>
        </div>


        {/* ---------------- certificate fee ---------------- */}
        <div className="col-lg-12 col-md-12 col-sm-12 border-gray mt-4">
          <div className="row">

            <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3"></div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h4 className="text-center">প্রত্যয়ন পত্র ফি</h4>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <button
                    type="button"
                    className="btn btn-block btn-lightblue"
                    data-toggle="modal"
                    data-target="#addATFee"
                  >
                    + যোগ করুন
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
              <table id="certificateFeeTable" className="table table-striped" width="100%;">
                <thead>
                  <tr>
                    <th scope="col" className="text-center">ক্রমিক</th>
                    <th scope="col">প্রত্যয়ন পত্রের ধরন</th>
                    <th scope="col" className="text-right">ফি</th>
                    <th scope="col" className="text-center actionTable">একশন</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">১</td>
                    <td>নাগরিক সনদ</td>
                    <td className="text-right">৳৭০</td>
                    <td className="text-center">
                      <button type="button" className="btn-icon btn-outline-lightblue" data-toggle="modal" data-target="#editATFee">
                        <EditIcon /> Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>


        {/* ---------------- warishan certificate fee ---------------- */}
        <div className="col-lg-12 col-md-12 col-sm-12 border-gray mt-4">
          <div className="row">

            <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3"></div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h4 className="text-center">ওয়ারিশান সনদ ফি</h4>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <button
                    type="button"
                    className="btn btn-block btn-lightblue"
                    data-toggle="modal"
                    data-target="#addHFee"
                  >
                    + যোগ করুন
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
              <table id="warishanCertificateFeeTable" className="table table-striped" width="100%;"              >
                <thead>
                  <tr>
                    <th scope="col" className="text-center">ক্রমিক</th>
                    <th scope="col">ওয়ারিশান সনদের ধরন</th>
                    <th scope="col" className="text-right">ফি</th>
                    <th scope="col" className="text-center actionTable">একশন</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">১</td>
                    <td>নতুন ওয়ারিশান সনদ তৈরী</td>
                    <td className="text-right">৳৭০</td>
                    <td className="text-center">
                      <button type="button" className="btn-icon btn-outline-lightblue" data-toggle="modal" data-target="#editHFee">
                        <EditIcon /> Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
      {/* ---------------- Tab Content End ---------------- */}



      {/* add house tax modal */}
      <ModalDialog
        title={'বসত বাড়ির কর যোগ করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="বসত বাড়ির ধরন" select fullWidth value={inputHouseTax.house_type}
              onChange={(e) => setInputHouseTax({ ...inputHouseTax, house_type: e.target.value })} margin='normal' size='small' >
              <MenuItem value={"পাঁকা ঘর"}>পাঁকা ঘর</MenuItem>
              <MenuItem value={"আধা পাঁকা ঘর"}>আধা পাঁকা ঘর</MenuItem>
              <MenuItem value={"টিনশেড ঘর"}>টিনশেড ঘর</MenuItem>
              <MenuItem value={"কাঁচা ঘর"}>কাঁচা ঘর</MenuItem>
            </TextField>

            <TextField label="ট্যাক্স" type='number' fullWidth value={inputHouseTax.tax}
              onChange={(e) => setInputHouseTax({ ...inputHouseTax, tax: e.target.value })} margin='normal' size='small' />
          </div>
        }
        onOpen={openAddHouseTaxModal}
        onClose={() => setOpenAddHouseTaxModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={createHouseTax}
        loading={loading}
      />

      {/* edit house tax modal */}
      <ModalDialog
        title={'বসত বাড়ির কর এডিট করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="বসত বাড়ির ধরন" select fullWidth value={editableHouseTax.house_type}
              onChange={(e) => setEditableHouseTax({ ...editableHouseTax, house_type: e.target.value })} margin='normal' size='small' >
              <MenuItem value={"পাঁকা ঘর"}>পাঁকা ঘর</MenuItem>
              <MenuItem value={"আধা পাঁকা ঘর"}>আধা পাঁকা ঘর</MenuItem>
              <MenuItem value={"টিনশেড ঘর"}>টিনশেড ঘর</MenuItem>
              <MenuItem value={"কাঁচা ঘর"}>কাঁচা ঘর</MenuItem>
            </TextField>

            <TextField label="ট্যাক্স" type='number' fullWidth value={editableHouseTax.tax}
              onChange={(e) => setEditableHouseTax({ ...editableHouseTax, tax: e.target.value })} margin='normal' size='small' />
          </div>
        }
        onOpen={openEditHouseTaxModal}
        onClose={() => setOpenEditHouseTaxModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={updateHouseTax}
        loading={loading}
      />


      {/* add business tax modal */}
      <ModalDialog
        title={'ব্যবসায়িক কর যোগ করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="ব্যবসার ধরন" fullWidth value={inputBsnsTax.house_type}
              onChange={(e) => setInputBsnsTax({ ...inputBsnsTax, business_type: e.target.value })} margin='normal' size='small' >
            </TextField>

            <TextField label="ট্যাক্স" type='number' fullWidth value={inputBsnsTax.tax}
              onChange={(e) => setInputBsnsTax({ ...inputBsnsTax, tax: e.target.value })} margin='normal' size='small' />
          </div>
        }
        onOpen={openAddBsnsTaxModal}
        onClose={() => setOpenAddBsnsTaxModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={createBusinessTax}
        loading={loading}
      />

      {/* edit business tax modal */}
      <ModalDialog
        title={'ব্যবসায়িক কর এডিট করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="ব্যবসার ধরন" fullWidth value={editableBsnsTax.business_type}
              onChange={(e) => setEditableBsnsTax({ ...editableBsnsTax, business_type: e.target.value })} margin='normal' size='small' >
            </TextField>

            <TextField label="ট্যাক্স" type='number' fullWidth value={editableBsnsTax.tax}
              onChange={(e) => setEditableBsnsTax({ ...editableBsnsTax, tax: e.target.value })} margin='normal' size='small' />
          </div>
        }
        onOpen={openEditBsnsTaxModal}
        onClose={() => setOpenEditBsnsTaxModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={updateBusinessTax}
        loading={loading}
      />


      {/* add license fee modal */}
      <ModalDialog
        title={'ট্রেড লাইসেন্স ফি যোগ করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="লাইসেন্স টাইপ" select fullWidth value={inputLicenseFee.license_type}
              onChange={(e) => setInputLicenseFee({ ...inputLicenseFee, license_type: e.target.value })} margin='normal' size='small' >
              <MenuItem value={"নতুন"}>নতুন</MenuItem>
              <MenuItem value={"নবায়ন"}>নবায়ন</MenuItem>
            </TextField>

            <TextField label="ফি (৳)" type='number' fullWidth value={inputLicenseFee.fee}
              onChange={(e) => setInputLicenseFee({ ...inputLicenseFee, fee: e.target.value })} margin='normal' size='small' />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField label="ভ্যাট (%)" type='number' fullWidth value={inputLicenseFee.vat_p}
                  onChange={(e) => setInputLicenseFee({ ...inputLicenseFee, vat_p: e.target.value })} margin='normal' size='small' />
              </Grid>
              <Grid item xs={6}>
                <TextField label="মূলধন কর (%)" type='number' fullWidth value={inputLicenseFee.capital_tax_p}
                  onChange={(e) => setInputLicenseFee({ ...inputLicenseFee, capital_tax_p: e.target.value })} margin='normal' size='small' />
              </Grid>
            </Grid>
          </div>
        }
        onOpen={openAddLicenseFeeModal}
        onClose={() => setOpenAddLicenseFeeModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={createLicenseFee}
        loading={loading}
      />

      {/* edit license fee modal */}
      <ModalDialog
        title={'ট্রেড লাইসেন্স ফি এডিট করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="লাইসেন্স টাইপ" select fullWidth value={editableLicenseFee.license_type}
              onChange={(e) => setEditableLicenseFee({ ...editableLicenseFee, license_type: e.target.value })} margin='normal' size='small' >
              <MenuItem value={"নতুন"}>নতুন</MenuItem>
              <MenuItem value={"নবায়ন"}>নবায়ন</MenuItem>
            </TextField>

            <TextField label="ফি (৳)" type='number' fullWidth value={editableLicenseFee.fee}
              onChange={(e) => setEditableLicenseFee({ ...editableLicenseFee, fee: e.target.value })} margin='normal' size='small' />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField label="ভ্যাট (%)" type='number' fullWidth value={editableLicenseFee.vat_p}
                  onChange={(e) => setEditableLicenseFee({ ...editableLicenseFee, vat_p: e.target.value })} margin='normal' size='small' />
              </Grid>
              <Grid item xs={6}>
                <TextField label="মূলধন কর (%)" type='number' fullWidth value={editableLicenseFee.capital_tax_p}
                  onChange={(e) => setEditableLicenseFee({ ...editableLicenseFee, capital_tax_p: e.target.value })} margin='normal' size='small' />
              </Grid>
            </Grid>
          </div>
        }
        onOpen={openEditLicenseFeeModal}
        onClose={() => setOpenEditLicenseFeeModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={updateLicenseFee}
        loading={loading}
      />


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </div>
  )
}
