import React from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import WordVillageManage from "./WordVillageManage";
import TaxFeeManage from "./TaxFeeManage";
import EconomicYear from "./EconomicYear";
import PostOffice from "./PostOffice";

const SettingsUnion = () => {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#example,#example2,#example3,#example4,#example5,#example6,#example7,#example8,#example9").DataTable();
  });
  // For Initialize DataTable End ----------------


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">ইউনিয়ন সেটিংস</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">ইউনিয়ন সেটিংস</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12">

                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {/* <button className="nav-link active" id="nav-home-tab" data-toggle="tab" data-target="#nav-accountSetting"
                    type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                    প্রাথমিক তথ্য
                  </button> */}

                  <button className="nav-link active" id="nav-home-tab" data-toggle="tab" data-target="#navWordVillage"
                    type="button" role="tab" aria-controls="nav-home" aria-selected="false">
                    ওয়ার্ড ও গ্রাম নির্ধারণ
                  </button>

                  <button className="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#navEconomicYear"
                    type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
                    অর্থবছর নির্ধারণ
                  </button>

                  <button className="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#navFeeSetting"
                    type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
                    কর/ফি নির্ধারণ
                  </button>

                  <button className="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#navPostOffice"
                    type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
                    পোস্ট অফিস
                  </button>
                </div>


                <div className="tab-content" id="nav-tabContent">
                  {/* ---------------- primary information ---------------- */}
                  {/* <PrimaryUnionInfo /> */}


                  {/* ---------------- word/village manage ---------------- */}
                  <WordVillageManage />


                  {/* ---------------- economic year manage ---------------- */}
                  <EconomicYear />


                  {/* ---------------- Tax/Fee manage ---------------- */}
                  <TaxFeeManage />


                  {/* ---------------- post office ---------------- */}
                  <PostOffice />

                </div>
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>
    </>
  );
};

export default SettingsUnion;
