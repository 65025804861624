import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom';
import UserDashboard from "./user/views/Dashboard";
import Error from "./utilities/Error";
import TaxMemberAdd from "./chairman/views/tax_member/TaxMemberAdd";
import TaxMemberEdit from "./chairman/views/tax_member/TaxMemberEdit";
import TaxMemberList from "./chairman/views/tax_member/TaxMemberList";
import TaxMemberRegisteredList from "./chairman/views/tax_member/TaxMemberRegisteredList";
import TaxMemberRegisteredListPrint from "./chairman/views/tax_member/TaxMemberRegisteredListPrint";
import TaxCollectionAdd from "./chairman/views/tax_collection/TaxCollectionAdd";
import TaxCollectionList from "./chairman/views/tax_collection/TaxCollectionList";
import TaxCollectionWordWiseList from "./chairman/views/tax_collection/TaxCollectionWordWiseList";
import TaxCollectionSingleView from "./chairman/views/tax_collection/TaxCollectionSingleView";
import TaxCollectionInvoicePrint from "./chairman/views/tax_collection/TaxCollectionInvoicePrint";
import TaxCollectionWordWiseListPrint from "./chairman/views/tax_collection/TaxCollectionWordWiseListPrint";
import TaxCollectionUserWiseList from "./chairman/views/tax_collection/TaxCollectionUserWiseList";
import TaxCollectionUserWiseListPrint from "./chairman/views/tax_collection/TaxCollectionUserWiseListPrint";
import TradeLicenseBanglaPrint from "./chairman/views/trade_license/TradeLicenseBanglaPrint";
import TradeLicenseAdd from "./chairman/views/trade_license/TradeLicenseAdd";
import TradeLicenseNewList from "./chairman/views/trade_license/TradeLicenseNewList";
import TradeLicenseRenewList from "./chairman/views/trade_license/TradeLicenseRenewList";
import TradeLicenseYearlyList from "./chairman/views/trade_license/TradeLicenseYearlyList";
import TradeLicenseRegisteredYearlyListPrint from "./chairman/views/trade_license/TradeLicenseRegisteredYearlyListPrint";
import OnlineApplicationTradeLicenseAdd from "./pages/OnlineApplicationTradeLicenseAdd";
import OnlineApplicationHeirAdd from "./pages/OnlineApplicationHeirAdd";
import OnlineApplicationProttoyonAdd from "./pages/OnlineApplicationProttoyonAdd";
import HeirCertificatePrint from "./chairman/views/heir_application/HeirCertificatePrint";
import HeirApplicationAdd from "./chairman/views/heir_application/HeirApplicationAdd";
import HeirApplicationList from "./chairman/views/heir_application/HeirApplicationList";
import HeirApplicationView from "./chairman/views/heir_application/HeirApplicationView";
import SettingsUnion from "./chairman/views/settings/union_settings/SettingsUnion";
import ComingSoon from "./pages/ComingSoon";
import Login from "./auth/Login";
import TradeLicenseEdit from "./chairman/views/trade_license/TradeLicenseEdit";
import TaxCollectionEdit from "./chairman/views/tax_collection/TaxCollectionEdit";
import HeirApplicationEdit from "./chairman/views/heir_application/HeirApplicationEdit";
import CertificateAdd from "./chairman/views/certificate/CertificateAdd";
import CertificateTypes from "./chairman/views/certificate/CertificateTypes";
import CertificateList from "./pages/CertificateList";
import CertificateListByType from "./chairman/views/certificate/CertificateListByTypes";
import CertificatePrint from "./chairman/views/certificate/CertificatePrint";
import CertificateCategory from "./chairman/views/certificate/CertificateCategory";
import CertificateEdit from "./chairman/views/certificate/CertificateEdit";
import PublicRoute from "./utilities/PublicRoute";
import UserLayout from "./user/layout/Layout";
import UserPrivateRoute from "./user/layout/PrivateRoute";
import ChairmanLayout from './chairman/layout/Layout';
import ChairmanPrivateRoute from './chairman/layout/PrivateRoute';
import ChairmanDashboard from './chairman/views/Dashboard';
import ChairmanSettingsSoftware from './chairman/views/settings/software_settings/SettingsSoftware';
import UserSettingsSoftware from './user/views/settings/software_settings/SettingsSoftware';
import { TaxMemberProfile } from './chairman/views/tax_member/TaxMemberProfile';
import ImportTaxMember from './chairman/views/tax_member/import_member/ImportTaxMember';

export default function AllRoutes() {
  return (
    <Routes>
      {/**
          |--------------------------------------------------------------------------
          | User routes
          |--------------------------------------------------------------------------
         */}
      <Route path="/" element={<UserPrivateRoute><UserLayout /></UserPrivateRoute>}>

        <Route index element={<UserDashboard />} />

        {/* settings */}
        <Route path="software-settings" element={<UserSettingsSoftware />} />
        <Route path="union-settings" element={<SettingsUnion />} />

        {/* tax member */}
        <Route path="add-tax-member" element={<TaxMemberAdd />} />
        <Route path="add-tax-member/import" element={<ImportTaxMember />} />
        <Route path="tax-member-list" element={<TaxMemberList />} />
        <Route path="tax-member-list/profile" element={<TaxMemberProfile />} />
        <Route path="tax-member-list/edit" element={<TaxMemberEdit />} />
        <Route path="tax-member-registered-list" element={<TaxMemberRegisteredList />} />

        {/* tax collection */}
        <Route path="add-tax-collection" element={<TaxCollectionAdd />} />
        <Route path="tax-collection-list" element={<TaxCollectionList />} />
        <Route path="tax-collection-list/view" element={<TaxCollectionSingleView />} />
        <Route path="tax-collection-list/edit" element={<TaxCollectionEdit />} />
        <Route path="tax-collection-list-word-wise" element={<TaxCollectionWordWiseList />} />
        <Route path="tax-collection-list-person-wise" element={<TaxCollectionUserWiseList />} />

        {/* trade license */}
        <Route path="trade-license-add" element={<TradeLicenseAdd />} />
        <Route path="trade-license-list-new" element={<TradeLicenseNewList />} />
        <Route path="trade-license-list-new/edit" element={<TradeLicenseEdit />} />
        <Route path="trade-license-list-renew" element={<TradeLicenseRenewList />} />
        <Route path="trade-license-list-yearly" element={<TradeLicenseYearlyList />} />

        {/* heir application */}
        <Route path="heir-application-add" element={<HeirApplicationAdd />} />
        <Route path="heir-application-list" element={<HeirApplicationList />} />
        <Route path="heir-application-list/edit" element={<HeirApplicationEdit />} />

        {/* certificates */}
        <Route path="certificate-category" element={<CertificateCategory />} />
        <Route path="certificate-add" element={<CertificateAdd />} />
        <Route path="certificate-types" element={<CertificateTypes />} />
        <Route path="certificate-types/list" element={<CertificateListByType />} />
        <Route path="certificate-types/list/edit" element={<CertificateEdit />} />


        <Route path="OnlineApplicationTradeLicenseAdd" element={<OnlineApplicationTradeLicenseAdd />} />
        <Route path="OnlineApplicationHeirAdd" element={<OnlineApplicationHeirAdd />} />
        <Route path="OnlineApplicationProttoyonAdd" element={<OnlineApplicationProttoyonAdd />} />
        <Route path="CertificateList" element={<CertificateList />} />

        <Route path="ComingSoon" element={<ComingSoon />} />
        <Route path="ComingSoon1" element={<ComingSoon />} />
        <Route path="ComingSoon2" element={<ComingSoon />} />
        <Route path="ComingSoon3" element={<ComingSoon />} />
        <Route path="ComingSoon4" element={<ComingSoon />} />
        <Route path="ComingSoon5" element={<ComingSoon />} />
        <Route path="ComingSoon6" element={<ComingSoon />} />
        <Route path="ComingSoon7" element={<ComingSoon />} />
        <Route path="ComingSoon8" element={<ComingSoon />} />
        <Route path="ComingSoon9" element={<ComingSoon />} />
        <Route path="*" element={<Error />} />
      </Route>

      {/* without layout user routes */}
      <Route path="/" element={<UserPrivateRoute><Outlet /></UserPrivateRoute>}>

        {/* tax member */}
        <Route path="tax-member-registered-list/print" element={<TaxMemberRegisteredListPrint />} />

        {/* tax collection */}
        <Route path="tax-collection-list-person-wise/print" element={<TaxCollectionUserWiseListPrint />} />
        <Route path="tax-collection-invoice/print" element={<TaxCollectionInvoicePrint />} />
        <Route path="tax-collection-list-word-wise/print" element={<TaxCollectionWordWiseListPrint />} />

        {/* trade license */}
        <Route path="trade-license-list-new/bn/print" element={<TradeLicenseBanglaPrint />} />
        <Route path="trade-license-list-yearly/print" element={<TradeLicenseRegisteredYearlyListPrint />} />

        {/* heir application */}
        <Route path="heir-application/view" element={<HeirApplicationView />} />
        <Route path="heir-application/print" element={<HeirCertificatePrint />} />

        {/* certificate */}
        <Route path="certificate-types/list/print" element={<CertificatePrint />} />

      </Route>


      {/**
          |--------------------------------------------------------------------------
          | Chairman routes
          |--------------------------------------------------------------------------
         */}
      <Route path="/chairman" element={<ChairmanPrivateRoute><ChairmanLayout /></ChairmanPrivateRoute>}>

        <Route index element={<ChairmanDashboard />} />

        {/* settings */}
        <Route path="software-settings" element={<ChairmanSettingsSoftware />} />
        <Route path="union-settings" element={<SettingsUnion />} />

        {/* tax member */}
        <Route path="add-tax-member" element={<TaxMemberAdd />} />
        <Route path="add-tax-member/import" element={<ImportTaxMember />} />
        <Route path="tax-member-list" element={<TaxMemberList />} />
        <Route path="tax-member-list/profile" element={<TaxMemberProfile />} />
        <Route path="tax-member-list/edit" element={<TaxMemberEdit />} />
        <Route path="tax-member-registered-list" element={<TaxMemberRegisteredList />} />

        {/* tax collection */}
        <Route path="add-tax-collection" element={<TaxCollectionAdd />} />
        <Route path="tax-collection-list" element={<TaxCollectionList />} />
        <Route path="tax-collection-list/view" element={<TaxCollectionSingleView />} />
        <Route path="tax-collection-list/edit" element={<TaxCollectionEdit />} />
        <Route path="tax-collection-list-word-wise" element={<TaxCollectionWordWiseList />} />
        <Route path="tax-collection-list-person-wise" element={<TaxCollectionUserWiseList />} />

        {/* trade license */}
        <Route path="trade-license-add" element={<TradeLicenseAdd />} />
        <Route path="trade-license-list-new" element={<TradeLicenseNewList />} />
        <Route path="trade-license-list-new/edit" element={<TradeLicenseEdit />} />
        <Route path="trade-license-list-renew" element={<TradeLicenseRenewList />} />
        <Route path="trade-license-list-yearly" element={<TradeLicenseYearlyList />} />

        {/* heir application */}
        <Route path="heir-application-add" element={<HeirApplicationAdd />} />
        <Route path="heir-application-list" element={<HeirApplicationList />} />
        <Route path="heir-application-list/edit" element={<HeirApplicationEdit />} />

        {/* certificates */}
        <Route path="certificate-category" element={<CertificateCategory />} />
        <Route path="certificate-add" element={<CertificateAdd />} />
        <Route path="certificate-types" element={<CertificateTypes />} />
        <Route path="certificate-types/list" element={<CertificateListByType />} />
        <Route path="certificate-types/list/edit" element={<CertificateEdit />} />


        <Route path="OnlineApplicationTradeLicenseAdd" element={<OnlineApplicationTradeLicenseAdd />} />
        <Route path="OnlineApplicationHeirAdd" element={<OnlineApplicationHeirAdd />} />
        <Route path="OnlineApplicationProttoyonAdd" element={<OnlineApplicationProttoyonAdd />} />
        <Route path="CertificateList" element={<CertificateList />} />

        <Route path="ComingSoon" element={<ComingSoon />} />
        <Route path="ComingSoon1" element={<ComingSoon />} />
        <Route path="ComingSoon2" element={<ComingSoon />} />
        <Route path="ComingSoon3" element={<ComingSoon />} />
        <Route path="ComingSoon4" element={<ComingSoon />} />
        <Route path="ComingSoon5" element={<ComingSoon />} />
        <Route path="ComingSoon6" element={<ComingSoon />} />
        <Route path="ComingSoon7" element={<ComingSoon />} />
        <Route path="ComingSoon8" element={<ComingSoon />} />
        <Route path="ComingSoon9" element={<ComingSoon />} />
        <Route path="*" element={<Error />} />
      </Route>

      {/* without layout chairman routes */}
      <Route path="/chairman" element={<ChairmanPrivateRoute><Outlet /></ChairmanPrivateRoute>}>

        {/* tax member */}
        <Route path="tax-member-registered-list/print" element={<TaxMemberRegisteredListPrint />} />

        {/* tax collection */}
        <Route path="tax-collection-list-person-wise/print" element={<TaxCollectionUserWiseListPrint />} />
        <Route path="tax-collection-invoice/print" element={<TaxCollectionInvoicePrint />} />
        <Route path="tax-collection-list-word-wise/print" element={<TaxCollectionWordWiseListPrint />} />

        {/* trade license */}
        <Route path="trade-license-list-new/bn/print" element={<TradeLicenseBanglaPrint />} />
        <Route path="trade-license-list-yearly/print" element={<TradeLicenseRegisteredYearlyListPrint />} />

        {/* heir application */}
        <Route path="heir-application/view" element={<HeirApplicationView />} />
        <Route path="heir-application/print" element={<HeirCertificatePrint />} />

        {/* certificate */}
        <Route path="certificate-types/list/print" element={<CertificatePrint />} />

      </Route>



      {/**
          |--------------------------------------------------------------------------
          | Admin routes
          |--------------------------------------------------------------------------
         */}



      <Route path="/Login" element={<PublicRoute><Login /></PublicRoute>} />
      <Route path="*" element={<Error />} />
    </Routes>
  )
}
