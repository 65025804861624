import React from "react";

const AdvantageListIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.580791 0H17.4192C17.74 0 18 0.288912 18 0.645323V19.3547C18 19.7111 17.74 20 17.4192 20H0.580791C0.260016 20 0 19.7111 0 19.3547V0.645323C0 0.288906 0.260021 0 0.580791 0ZM2.81831 5.90082C2.7049 5.77481 2.52102 5.77481 2.40762 5.90082C2.29421 6.02683 2.29421 6.23114 2.40762 6.35714L3.09391 7.11969C3.21562 7.2547 3.4159 7.24344 3.52444 7.09516L4.79137 5.36483C4.89262 5.22668 4.87379 5.02349 4.74945 4.91099C4.62511 4.79848 4.44224 4.81941 4.34099 4.95756L3.27703 6.41071L2.81831 5.90082ZM2.81831 9.77166C2.7049 9.64566 2.52102 9.64566 2.40762 9.77166C2.29421 9.89767 2.29421 10.102 2.40762 10.228L3.09391 10.9905C3.21562 11.1258 3.4159 11.1143 3.52444 10.966L4.79137 9.23568C4.89262 9.09752 4.87379 8.89434 4.74945 8.78183C4.62511 8.66955 4.44224 8.69026 4.34099 8.82863L3.27703 10.2815L2.81831 9.77166ZM2.81831 13.6427C2.7049 13.5167 2.52102 13.5167 2.40762 13.6427C2.29421 13.7685 2.29421 13.9728 2.40762 14.0988L3.09391 14.8614C3.21562 14.9966 3.4159 14.9851 3.52444 14.8369L4.79137 13.1065C4.89262 12.9684 4.87379 12.7652 4.74945 12.6527C4.62511 12.5404 4.44224 12.5613 4.34099 12.6995L3.27703 14.1524L2.81831 13.6427ZM6.63785 10.4836H15.3474C15.5078 10.4836 15.6378 10.3393 15.6378 10.1611C15.6378 9.98292 15.5078 9.83847 15.3474 9.83847H6.63785C6.47746 9.83847 6.34765 9.98292 6.34765 10.1611C6.34765 10.3393 6.47746 10.4836 6.63785 10.4836ZM6.63785 14.3546H15.3474C15.5078 14.3546 15.6378 14.2102 15.6378 14.032C15.6378 13.8538 15.5078 13.7093 15.3474 13.7093H6.63785C6.47746 13.7093 6.34765 13.8538 6.34765 14.032C6.34765 14.2102 6.47746 14.3546 6.63785 14.3546ZM6.63785 6.61285H15.3474C15.5078 6.61285 15.6378 6.46839 15.6378 6.2904C15.6378 6.11219 15.5078 5.96774 15.3474 5.96774H6.63785C6.47746 5.96774 6.34765 6.11219 6.34765 6.2904C6.34765 6.46838 6.47746 6.61285 6.63785 6.61285Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
      </svg>
    </>
  );
};

export default AdvantageListIcon;
