import React, { useCallback, useEffect, useState } from "react";
import GovBDLogo from "../../../assets/images/GovBDLogo.png";
import "../../../assets/css/TaxCollectionInvoicePrint.scss";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CustomSnackbar from "../../../utilities/SnackBar";
import { useLocalStorage } from "usehooks-ts";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import config from "../../../config";

const HeirCertificatePrint = () => {

  const application_id = useLocation().state.id;
  const [userDetails] = useLocalStorage('user', {})

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [role, setRole] = useState('')
  const navigate = useNavigate();

  const [heirInfo, setHeirInfo] = useState({});

  // get heir info
  const getHeirInfo = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/heir-application/${application_id}`).then(res => {
      if (res.status === 200) {
        setHeirInfo(res.data.heir_info)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [application_id, role]);


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getHeirInfo();
    }
  }, [getHeirInfo, role]);

  const Print = () => {
    document.getElementById("printBtn").style.cssText = " display: none;";
    document.getElementById("closetBtn").style.cssText = " display: none;";
    window.print();
    document.getElementById("printBtn").style.cssText = " display: block;";
    document.getElementById("closetBtn").style.cssText = " display: block;";
  };


  return (
    <>
      {loading ? <SpinnerPulse color={'dark'} />
        :
        <div className="row main-bg p-5 m-0 watermark"
          style={{ backgroundImage: `url(${config.baseUrl}/storage/${userDetails.union?.logo})` }}>
          <div className="col-lg-12 col-md-12 col-sm-12 p-2">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 p-1">
                <button type="button" className="btn btn-block btn-danger" onClick={() => navigate(-1)} id="closetBtn">
                  ফিরে যান
                </button>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
              <div className="col-lg-2 col-md-2 col-sm-2 p-1">
                <button type="button" className="btn btn-block btn-lightblue" onClick={Print} id="printBtn">
                  প্রিন্ট
                </button>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-left">
            <img src={GovBDLogo} alt="GovBDLogo" style={{ width: "100px" }} className="" />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8 p-2 text-center">
            <h4 className="main-color-green">
              গণপ্রজাতন্ত্রী বাংলাদেশ সরকার ( স্থানীয় সরকার বিভাগ )
            </h4>
            <h1 className="font-weight-bolder main-color-red">
              {userDetails.union.bn_name} কার্যালয়
            </h1>
            <h4 className="main-color-green">
              উপজেলাঃ {userDetails.union?.upazila}, জেলাঃ {userDetails.union?.district}
            </h4>

            <h3 className="font-weight-bolder mt-4">{userDetails.union?.chairman?.name}</h3>
            <h5 className="font-weight-bolder main-color-green">চেয়ারম্যান</h5>
            <h5 className="main-color-green">মোবাঃ {userDetails.union?.chairman?.phone}</h5>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-right">
            {userDetails.union?.logo &&
              <img src={`${config.baseUrl}/storage/${userDetails.union?.logo}`} alt="union logo" style={{ width: "100px" }} className="" />}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 border-b-4-black pl-5 pr-5"></div>

          <div className="col-lg-6 col-md-6 col-sm-6 p-2 mt-5 text-left">
            <h5 className="main-color-green">স্বারক নংঃ {heirInfo.ref_no}</h5>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 p-2 mt-5 text-right">
            <h5 className="">
              তারিখঃ {new Date().toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric' })} ইং
            </h5>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 p-2 text-center">
            <h3 className="font-weight-bolder mt-4">ওয়ারিশান সনদ পত্র</h3>
            <p className="mt-5">
              এই মর্মে প্রত্যয়ন করা যাইতেছে যে, {heirInfo.name_of_dead}, পিতাঃ মৃত{" "}
              {heirInfo.fathers_name_of_dead}, গ্রামঃ {heirInfo.village_of_dead?.name}, ওয়ার্ড নং{" "}
              {heirInfo.word_of_dead?.word}, উপজেলাঃ {userDetails.union?.upazila}, জেলাঃ{" "}
              {userDetails.union?.district}। তিনি মৃত্যুর পর নিম্নোক্ত ওয়ারিশ/ওয়ারিশগণ
              রাখিয়া যায়।
            </p>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 p-2">
            <p className="mt-5">ওয়ারিশানদের তালিকাঃ</p>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
            <table className="table table-bordered border-black" width="100%;">
              <thead>
                <tr className="vendorListHeading">
                  <th scope="col" className="text-center">ক্রমিক নং</th>
                  <th scope="col">নাম</th>
                  <th scope="col" className="text-center">সম্পর্ক</th>
                  <th scope="col" className="text-center">মন্তব্য</th>
                </tr>
              </thead>
              <tbody>
                {heirInfo.heritors?.map((heritor, index) => (
                  <tr key={index} className="vendorListHeading">
                    <th scope="row" className="text-center">
                      {index + 1}
                    </th>
                    <td>{heritor.heir_name}</td>
                    <td className="text-center">{heritor.relationship}</td>
                    <td className="text-center">{heritor.comment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>
          <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>

          <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5 text-right">
            <h5 className="font-weight-bolder">চেয়ারম্যান - স্বাক্ষর ও সীল</h5>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>
          <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>
          <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>
        </div>
      }

      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default HeirCertificatePrint;
