import React, { useCallback, useEffect, useState } from 'react'
import $ from "jquery";
import EditIcon from '../../../../assets/icons/EditIcon';
import { SpinnerPulse } from '../../../../utilities/SpinnerPulse';
import CustomSnackbar from '../../../../utilities/SnackBar';
import ModalDialog from '../../../../utilities/ModalDialog';
import { TextField } from '@mui/material';
import axios from 'axios';

export default function EconomicYear() {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#economic_year_datatable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [inputSession, setInputSession] = useState({
    name: '', start_date: convertDate(new Date()), end_date: convertDate(new Date().setMonth(+17))
  })
  const [economicYears, setEconomicYears] = useState([])
  const [editableYear, setEditableYear] = useState({})

  const [openAddYearModal, setOpenAddYearModal] = useState(false);
  const [openEditYearModal, setOpenEditYearModal] = useState(false);

  // console.log(editableYear)

  // get all years
  const getEconomicYears = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/economic-year`).then(res => {
      if (res.status === 200) {
        setEconomicYears(res.data.economic_years)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // create economic year
  const createEconomicYear = () => {
    setLoading(true);
    axios.post(`/api/${role}/economic-year`, inputSession).then(res => {
      if (res.status === 200) {
        getEconomicYears()
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenAddYearModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // update economic year
  const updateEconomicYear = () => {
    setLoading(true);
    axios.put(`/api/${role}/economic-year/${editableYear.id}`, editableYear).then(res => {
      if (res.status === 200) {
        getEconomicYears()
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenEditYearModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getEconomicYears();
    }
  }, [getEconomicYears, role]);


  return (
    <div className="tab-pane fade" id="navEconomicYear" role="tabpanel" aria-labelledby="nav-contact-tab">

      <div className="row mt-1 p-3 rounded main-bg">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 ml-auto">
              <button type="button" className="btn btn-block btn-lightblue" onClick={() => setOpenAddYearModal(true)}>
                <i class="fa fa-plus mr-2"></i> যোগ করুন
              </button>
            </div>
          </div>
        </div>


        <div className="col-lg-12 col-md-12 col-sm-12 table-responsive py-4">
          {economicYears.length > 0 ?
            <table id="economic_year_datatable" className="table table-striped" width="100%;">
              <thead>
                <tr>
                  <th scope="col" className="text-center">ক্রমিক</th>
                  <th scope="col">অর্থবছর</th>
                  <th scope="col">সময়কাল</th>
                  <th scope="col" className="text-center actionTable">একশন</th>
                </tr>
              </thead>
              <tbody>
                {economicYears.map((year, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{year.name}</td>
                    <td>
                      {`${new Date(year.start_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long' })}
                      - ${new Date(year.end_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long' })}`}
                    </td>
                    <td className="text-center">
                      <button type="button" className="btn-icon btn-outline-lightblue" onClick={() => { setEditableYear(year); setOpenEditYearModal(true) }}>
                        <EditIcon /> Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            : loading ? <SpinnerPulse color={'dark'} />
              : 'কোনো ডেটা পাওয়া যায় নি'}
        </div>

      </div>



      {/* add econoimic year modal */}
      <ModalDialog
        title={'অর্থবছর যোগ করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="অর্থবছরের নাম" fullWidth value={inputSession.name}
              onChange={(e) => setInputSession({ ...inputSession, name: e.target.value })} margin='normal' size='small' />

            <TextField label="শুরুর মাস" type='month' fullWidth value={inputSession.start_date}
              onChange={(e) => {
                const startDate = new Date(e.target.value);
                const endDate = new Date(startDate.setMonth(startDate.getMonth() + 11));
                setInputSession({ ...inputSession, start_date: e.target.value, end_date: convertDate(endDate) });
              }} margin='normal' size='small' />

            <TextField label="শেষের মাস" type='month' fullWidth value={inputSession.end_date}
              onChange={(e) => setInputSession({ ...inputSession, end_date: e.target.value })} margin='normal' size='small' />
          </div>
        }
        onOpen={openAddYearModal}
        onClose={() => setOpenAddYearModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={createEconomicYear}
        loading={loading}
      />

      {/* edit economic year */}
      <ModalDialog
        title={'অর্থবছর সম্পাদনা করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="অর্থবছরের নাম" fullWidth value={editableYear.name}
              onChange={(e) => setEditableYear({ ...editableYear, name: e.target.value })} margin='normal' size='small' />

            <TextField label="শুরুর মাস" type='month' fullWidth value={convertDate(editableYear.start_date)}
              onChange={(e) => {
                const startDate = new Date(e.target.value);
                const endDate = new Date(startDate.setMonth(startDate.getMonth() + 11));
                setEditableYear({ ...editableYear, start_date: e.target.value, end_date: convertDate(endDate) });
              }} margin='normal' size='small' />

            <TextField label="শেষের মাস" type='month' fullWidth value={convertDate(editableYear.end_date)}
              onChange={(e) => setEditableYear({ ...editableYear, end_date: e.target.value })} margin='normal' size='small' />
          </div>
        }
        onOpen={openEditYearModal}
        onClose={() => setOpenEditYearModal(false)}
        confirmText={'সম্পাদনা করুন'}
        onConfirm={updateEconomicYear}
        loading={loading}
      />


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </div>
  );




  // convert date function
  function convertDate(inputDate) {
    const date = new Date(inputDate);

    // Get the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');

    // Assemble the formatted date
    const formattedDate = `${year}-${month}`;

    return formattedDate;
  }
}
