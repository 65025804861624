import React, { useCallback, useEffect, useState } from 'react'
import $ from "jquery";
import EditIcon from '../../../../assets/icons/EditIcon';
import { SpinnerPulse } from '../../../../utilities/SpinnerPulse';
import CustomSnackbar from '../../../../utilities/SnackBar';
import ModalDialog from '../../../../utilities/ModalDialog';
import { TextField } from '@mui/material';
import axios from 'axios';

export default function PostOffice() {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#post_office_datatable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [inputPostOffice, setInputPostOffice] = useState({
    post_code: '', name: ''
  })
  const [postOffices, setPostOffices] = useState([])
  const [editablePostOffice, setEditablePostOffice] = useState({})

  const [openAddPostOfficeModal, setOpenAddPostOfficeModal] = useState(false);
  const [openEditPostOfficeModal, setOpenEditPostOfficeModal] = useState(false);

  // console.log(editablePostOffice)

  // get all post offices
  const getPostOffices = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/post-office`).then(res => {
      if (res.status === 200) {
        setPostOffices(res.data.post_offices)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // create economic year
  const createPostOffice = () => {
    setLoading(true);
    axios.post(`/api/${role}/post-office`, inputPostOffice).then(res => {
      if (res.status === 200) {
        getPostOffices()
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenAddPostOfficeModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // update economic year
  const updatePostOffice = () => {
    setLoading(true);
    axios.put(`/api/${role}/post-office/${editablePostOffice.id}`, editablePostOffice).then(res => {
      if (res.status === 200) {
        getPostOffices()
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenEditPostOfficeModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getPostOffices();
    }
  }, [getPostOffices, role]);


  return (
    <div className="tab-pane fade" id="navPostOffice" role="tabpanel" aria-labelledby="nav-contact-tab">

      <div className="row mt-1 p-3 rounded main-bg">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 ml-auto">
              <button type="button" className="btn btn-block btn-lightblue" onClick={() => setOpenAddPostOfficeModal(true)}>
                <i class="fa fa-plus mr-2"></i> যোগ করুন
              </button>
            </div>
          </div>
        </div>


        <div className="col-lg-12 col-md-12 col-sm-12 table-responsive py-4">
          {postOffices.length > 0 ?
            <table id="post_office_datatable" className="table table-striped" width="100%;">
              <thead>
                <tr>
                  <th scope="col" className="text-center">ক্রমিক</th>
                  <th scope="col">পোস্ট কোড</th>
                  <th scope="col">নাম</th>
                  <th scope="col" className="text-center actionTable">একশন</th>
                </tr>
              </thead>
              <tbody>
                {postOffices.map((office, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{office.post_code}</td>
                    <td>{office.name}</td>
                    <td className="text-center">
                      <button type="button" className="btn-icon btn-outline-lightblue" onClick={() => { setEditablePostOffice(office); setOpenEditPostOfficeModal(true) }}>
                        <EditIcon /> Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            : loading ? <SpinnerPulse color={'dark'} />
              : 'কোনো ডেটা পাওয়া যায় নি'}
        </div>

      </div>



      {/* add econoimic year modal */}
      <ModalDialog
        title={'পোস্ট অফিস যোগ করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>

            <TextField label="পোস্ট কোড" fullWidth value={inputPostOffice.post_code}
              onChange={(e) => setInputPostOffice({ ...inputPostOffice, post_code: e.target.value })} margin='normal' size='small' />

            <TextField label="নাম" fullWidth value={inputPostOffice.name}
              onChange={(e) => setInputPostOffice({ ...inputPostOffice, name: e.target.value })} margin='normal' size='small' />

          </div>
        }
        onOpen={openAddPostOfficeModal}
        onClose={() => setOpenAddPostOfficeModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={createPostOffice}
        loading={loading}
      />

      {/* edit economic year */}
      <ModalDialog
        title={'পোস্ট অফিস সম্পাদনা করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>

            <TextField label="পোস্ট কোড" fullWidth value={editablePostOffice.post_code}
              onChange={(e) => setEditablePostOffice({ ...editablePostOffice, post_code: e.target.value })} margin='normal' size='small' />

            <TextField label="নাম" fullWidth value={editablePostOffice.name}
              onChange={(e) => setEditablePostOffice({ ...editablePostOffice, name: e.target.value })} margin='normal' size='small' />

          </div>
        }
        onOpen={openEditPostOfficeModal}
        onClose={() => setOpenEditPostOfficeModal(false)}
        confirmText={'সম্পাদনা করুন'}
        onConfirm={updatePostOffice}
        loading={loading}
      />


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </div>
  );
}
