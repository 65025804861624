import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

const TaxCollectionSingleView = () => {

  const taxCollection = useLocation().state.taxCollection;
  const [userDetails] = useLocalStorage('user', {});
  const navigate = useNavigate();

  // console.log(taxCollection);

  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="m-0">
                  {taxCollection.tax_member?.name_of_khana_prodhan}
                </h1> */}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">কর আদায়</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={() => navigate(-1)}>
                  সবগুলো দেখুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center font-weight-bolder">
                  ব্যক্তিগত তথ্য
                </h4>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 border-right">
                    <dl class="row">
                      <dt class="col-sm-4">নামঃ</dt>
                      <dd class="col-sm-8">
                        {taxCollection.tax_member?.name_of_khana_prodhan}
                      </dd>
                      <dt class="col-sm-4">পিতার নামঃ</dt>
                      <dd class="col-sm-8">
                        {taxCollection.tax_member?.fathers_name}
                      </dd>
                      <dt class="col-sm-4">গ্রাম/মহল্লাঃ</dt>
                      <dd class="col-sm-8">
                        {taxCollection.tax_member?.village?.name}
                      </dd>
                    </dl>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 border-right">
                    <dl class="row">
                      <dt class="col-sm-4">হোল্ডিং নম্বরঃ</dt>
                      <dd class="col-sm-8">
                        {taxCollection.tax_member?.holding_no}
                      </dd>
                      <dt class="col-sm-4">ভোটার আইডি নং</dt>
                      <dd class="col-sm-8">
                        {taxCollection.tax_member?.nid}
                      </dd>
                      <dt class="col-sm-4">মোবাইল নম্বরঃ</dt>
                      <dd class="col-sm-8">
                        {taxCollection.tax_member?.phone}
                      </dd>
                    </dl>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3">
                    <dl class="row">
                      <dt class="col-sm-4">ডাকঘরঃ</dt>
                      <dd class="col-sm-8">
                        {taxCollection.tax_member?.post_office}
                      </dd>
                      <dt class="col-sm-4">উপজেলাঃ</dt>
                      <dd class="col-sm-8">
                        {userDetails.union?.upazila}
                      </dd>
                      <dt class="col-sm-4">জেলাঃ</dt>
                      <dd class="col-sm-8">
                        {userDetails.union?.district}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>


            <div className="row mt-5 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center font-weight-bolder">আর্থিক তথ্য</h4>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
                <table className="table table-bordered" width="100%;">
                  <thead>
                    <tr className="vendorListHeading">
                      <th scope="col" className="text-center">তারিখ</th>
                      <th scope="col" className="text-center">অর্থ বছর</th>
                      <th scope="col" className="text-right">ডেবিট টাকা</th>
                      <th scope="col" className="text-right">ক্রেডিট টাকা</th>
                      <th scope="col" className="text-right">ব্যালেন্স টাকা</th>
                      <th scope="col" className="text-center">মাধ্যম</th>
                      <th scope="col" className="text-center">টি আর এক্স</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="vendorListHeading">
                      <td className="text-center">
                        {new Date(taxCollection.payment_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric', })}
                      </td>
                      <td className="text-center">
                        {taxCollection.economic_year?.name}
                      </td>
                      <td className="text-right">৳ {taxCollection.paid_amount}</td>
                      <td className="text-right">৳ {taxCollection.due_amount}</td>
                      <td className="text-right">৳ {taxCollection.paid_amount - taxCollection.due_amount}</td>
                      <td className="text-center">{taxCollection.payment_method?.name}</td>
                      <td className="text-center">-</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr className="vendorListHeading">
                      {/* <th className="text-center"> </th> */}
                      <th> </th>
                      <th scope="row" className="text-center">মোট কর</th>
                      <th scope="row" className="text-right">৳ {taxCollection.paid_amount}</th>
                      <th scope="row" className="text-right">৳ {taxCollection.due_amount}</th>
                      <th scope="row" className="text-right">৳ {taxCollection.paid_amount - taxCollection.due_amount}</th>
                      <th className="text-center"> </th>
                      <th className="text-center"> </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>
    </>
  );
};

export default TaxCollectionSingleView;
