import React from 'react'

export default function Footer() {

  return (
    <div>
      <footer className="main-footer">
        <strong>Copyright © {new Date().getFullYear().toString()} <a href="http://desndevtech.com">DesnDev Tech</a>. </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 1.0.0
        </div>
      </footer>
    </div>
  );
}
