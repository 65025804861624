import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const TradeLicenseYearlyList = () => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [role, setRole] = useState('')
  const navigate = useNavigate();

  const [economicYears, setEconomicYears] = useState([])

  // get all years
  const getEconomicYears = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/economic-year`).then(res => {
      if (res.status === 200) {
        setEconomicYears(res.data.economic_years)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getEconomicYears();
    }
  }, [getEconomicYears, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">ট্রেড লাইসেন্স রেজিস্টার তালিকা</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    ট্রেড লাইসেন্স রেজিস্টার তালিকা
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row mb-2">

              {economicYears.length > 0 ?
                economicYears.map((year, index) => (

                  <div className="col-lg-3 col-6 pb-2" key={index}>
                    <div className="navLink" onClick={() => navigate(`${role === 'user' ? '' : '/chairman'}/trade-license-list-yearly/print`, { state: { year: year } })}>
                      <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 circle-link-lightblue">
                        <div className="circle-div">
                          <div className="main-color-lightblue text-center font-50">
                            {year.name}
                          </div>
                        </div>
                        <div className="circle-div">
                          <div className="main-color-lightblue pt-3">অর্থ বছর</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : loading ? <SpinnerPulse color={'dark'} />
                  : 'কোনো ডেটা পাওয়া যায় নি'}
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default TradeLicenseYearlyList;
