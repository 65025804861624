import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import CustomSnackbar from "../../../utilities/SnackBar";

const TaxCollectionWordWiseList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [words, setWords] = useState([])

  const handleClick = (word_id) => {
    navigate(`${role === 'user' ? '' : '/chairman'}/tax-collection-list-word-wise/print`, { state: { word_id: word_id } });
  };


  // get all words
  const getWords = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/all-words`).then(res => {
      if (res.status === 200) {
        setWords(res.data.words)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getWords();
    }
  }, [getWords, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">ওয়ার্ড ভিত্তিক প্রিন্ট করুন</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    ওয়ার্ড ভিত্তিক প্রিন্ট করুন
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row mb-2">
              {words.length > 0 ?
                words.map((word, index) => (
                  <div className="col-lg-3 col-6 pb-2" key={index}>
                    <div className="NavLink" onClick={() => { handleClick(word.id); }}>
                      <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 circle-link-lightblue">
                        <div className="circle-div">
                          <div className="main-color-lightblue text-center font-50">
                            {word.word}
                          </div>
                        </div>
                        <div className="circle-div">
                          <div className="main-color-lightblue pt-3">ওয়ার্ড নং</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )) : loading ? <SpinnerPulse color={'dark'} />
                  : 'কোনো ডেটা পাওয়া যায় নি'}
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default TaxCollectionWordWiseList;
