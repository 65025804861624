import React from "react";
import "bootstrap";
import GovBDLogo from "../../../assets/images/GovBDLogo.png";
import "../../../assets/css/TaxCollectionInvoicePrint.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import config from "../../../config";

const TaxCollectionInvoicePrint = () => {

  const [userDetails] = useLocalStorage('user', {});
  const taxCollection = useLocation().state.taxCollection;
  const navigate = useNavigate();

  // console.log(userDetails)

  // let total_tax = taxCollection.tax_member.house_type_of_tax_member?.map((houseType) => {
  //   return parseInt(houseType.house_tax)
  // }).reduce((acc, curr) => acc + curr, 0);


  const Print = () => {
    document.getElementById("printBtn").style.cssText = " display: none;";
    document.getElementById("closetBtn").style.cssText = " display: none;";
    window.print();
    document.getElementById("printBtn").style.cssText = " display: block;";
    document.getElementById("closetBtn").style.cssText = " display: block;";
  };


  return (
    <>
      <div className="row main-bg p-5 m-0 watermark"
        style={{ backgroundImage: `url(${config.baseUrl}/storage/${userDetails.union?.logo})` }}>
        <div className="col-lg-12 col-md-12 col-sm-12 p-2">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2 p-1">
              <button type="button" className="btn btn-block btn-danger" onClick={() => navigate(-1)} id="closetBtn">
                ফিরে যান
              </button>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
            <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
            <div className="col-lg-2 col-md-2 col-sm-2 p-1">
              <button type="button" className="btn btn-block btn-lightblue" onClick={Print} id="printBtn">
                প্রিন্ট
              </button>
            </div>
          </div>
        </div>

        <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-left">
          <img src={GovBDLogo} alt="GovBDLogo" style={{ width: "100px" }} className="" />
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8 p-2 text-center">
          <h4 className="main-color-green">
            গণপ্রজাতন্ত্রী বাংলাদেশ সরকার ( স্থানীয় সরকার বিভাগ )
          </h4>
          <h1 className="font-weight-bolder main-color-red">
            {userDetails.union.bn_name} কার্যালয়
          </h1>
          <h4 className="main-color-green">উপজেলাঃ {userDetails.union?.upazila}, জেলাঃ {userDetails.union?.district}</h4>

          <h3 className="font-weight-bolder mt-4">{userDetails.union?.chairman?.name}</h3>
          <h5 className="font-weight-bolder main-color-green">চেয়ারম্যান</h5>
          <h5 className="main-color-green">মোবাঃ {userDetails.union?.chairman?.phone}</h5>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-right">
          {userDetails.union?.logo &&
            <img src={`${config.baseUrl}/storage/${userDetails.union?.logo}`} alt="union logo" style={{ width: "100px" }} className="" />}
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 border-b-4-black pl-5 pr-5"></div>

        <div className="col-lg-6 col-md-6 col-sm-6 p-2 mt-5 text-left">
          <h5 className="main-color-green">স্বারক নং- {taxCollection.ref_no}</h5>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 p-2 mt-5 text-right">
          <h5 className="">
            তারিখঃ {new Date(taxCollection.payment_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric', })}
          </h5>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 p-2 text-center">
          <h3 className="font-weight-bolder mt-4">কর পরিশোধের রশিদ</h3>
          <h5 className="">{taxCollection.tax_member?.name_of_khana_prodhan}</h5>
          <h5 className="">{`${taxCollection.tax_member?.village.name}, ${userDetails.union?.upazila}`}</h5>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 table-responsive mt-5">
          <table className="table table-bordered border-black" width="100%;">
            <thead>
              <tr className="vendorListHeading">
                <th scope="col" className="text-center">তারিখ</th>
                <th scope="col" className="text-center">অর্থ বছর</th>
                <th scope="col" className="text-right">ডেবিট টাকা</th>
                <th scope="col" className="text-right">ক্রেডিট টাকা</th>
                <th scope="col" className="text-right">ব্যালেন্স টাকা</th>
                <th scope="col" className="text-center">মাধ্যম</th>
                <th scope="col" className="text-center">টি আর এক্স</th>
              </tr>
            </thead>
            <tbody>
              <tr className="vendorListHeading">
                <td className="text-center">
                  {new Date(taxCollection.payment_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric', })}
                </td>
                <td className="text-center">
                  {taxCollection.economic_year?.name}
                </td>
                <td className="text-right">৳ {taxCollection.paid_amount}</td>
                <td className="text-right">৳ {taxCollection.due_amount}</td>
                <td className="text-right">৳ {taxCollection.paid_amount - taxCollection.due_amount}</td>
                <td className="text-center">{taxCollection.payment_method?.name}</td>
                <td className="text-center">-</td>
              </tr>
            </tbody>
            <tfoot>
              <tr className="vendorListHeading">
                {/* <th className="text-center"> </th> */}
                <th> </th>
                <th scope="row" className="text-center">মোট কর</th>
                <th scope="row" className="text-right">৳ {taxCollection.paid_amount}</th>
                <th scope="row" className="text-right">৳ {taxCollection.due_amount}</th>
                <th scope="row" className="text-right">৳ {taxCollection.paid_amount - taxCollection.due_amount}</th>
                <th className="text-center"> </th>
                <th className="text-center"> </th>
              </tr>
            </tfoot>
          </table>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>
        <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>

        <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5 text-right">
          <h5 className="font-weight-bolder">চেয়ারম্যান - স্বাক্ষর ও সীল</h5>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>
        <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>
        <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>
        <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>
        <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div>
      </div>
    </>
  );
};

export default TaxCollectionInvoicePrint;
