import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const TaxCollectionUserWiseList = () => {
  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#taxCollectionPersonWiseTable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [taxMembers, setTaxMembers] = useState([]);


  // get all tax members
  const getAllTaxMembers = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/tax-member`).then(res => {
      if (res.status === 200) {
        setTaxMembers(res.data.tax_members)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getAllTaxMembers();
    }
  }, [getAllTaxMembers, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">ব্যক্তিভিত্তিক বিল তালিকা</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    ব্যাক্তিভিত্তিক বিল তালিকা
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
                {taxMembers.length > 0 ?
                  <table id="taxCollectionPersonWiseTable" className="table table-striped" width="100%;">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ক্রমিক</th>
                        <th scope="col">নাম</th>
                        <th scope="col">মোবাইল</th>
                        <th scope="col">হোল্ডিং নং</th>
                        <th scope="col">ভোটার আইডি নং</th>
                        <th scope="col">গ্রাম</th>
                        <th scope="col">ওয়ার্ড নং</th>
                        <th scope="col" className="text-center">বিল পেপার</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taxMembers.map((member, index) => (
                        <tr key={member.id}>
                          <td className="text-center">{index + 1}</td>
                          <td>
                            {member.name_of_khana_prodhan}
                          </td>
                          <td>
                            {member.phone}
                          </td>
                          <td>
                            {member.holding_no}
                          </td>
                          <td>
                            {member.nid}
                          </td>
                          <td>
                            {member.village?.name}
                          </td>
                          <td>
                            {member.word?.word}
                          </td>
                          <td className="text-center">
                            <button onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/tax-collection-list-person-wise/print`, { state: { tax_member: member }, }); }}
                              target="_blank" className="btn-icon btn-outline-lightblue">
                              প্রিন্ট
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  : loading ? <SpinnerPulse color={'dark'} />
                    : 'কোনো সদস্য পাওয়া যায় নি'}
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default TaxCollectionUserWiseList;
