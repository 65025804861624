import { FormControlLabel, MenuItem, Switch, TextField } from '@mui/material'
import $ from "jquery";
import React, { useCallback, useEffect, useState } from 'react'
import EditIcon from '../../../../assets/icons/EditIcon'
import CustomSnackbar from '../../../../utilities/SnackBar';
import ModalDialog from '../../../../utilities/ModalDialog';
import axios from 'axios';
import { SpinnerPulse } from '../../../../utilities/SpinnerPulse';

export default function WordVillageManage() {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#wordTable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [inputWord, setInputWord] = useState({ word: '' })
  const [inputVillage, setInputVillage] = useState({ word_id: '', post_office_id: '', village_no: '', name: '' })
  const [words, setWords] = useState([])
  const [postOffices, setPostOffices] = useState([])
  const [editableWord, setEditableWord] = useState({})
  const [editableVillage, setEditableVillage] = useState({})

  const [openAddWordModal, setOpenAddWordModal] = useState(false);
  const [openAddVillageModal, setOpenAddVillageModal] = useState(false);
  const [openEditWordModal, setOpenEditWordModal] = useState(false);
  const [openEditVillageModal, setOpenEditVillageModal] = useState(false);

  // console.log(editableWord)

  // get all words
  const getWords = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/all-words`).then(res => {
      if (res.status === 200) {
        setWords(res.data.words)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])


  // get all post offices
  const getPostOffices = useCallback(() => {
    axios.get(`/api/${role}/post-office`).then(res => {
      if (res.status === 200) {
        setPostOffices(res.data.post_offices)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    });
  }, [role])

  // create word
  const createWord = () => {
    setLoading(true);
    axios.post(`/api/${role}/create-word`, inputWord).then(res => {
      if (res.status === 200) {
        getWords()
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenAddWordModal(false)
        setInputWord({ word: '' })
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // update word
  const updateWord = () => {
    setLoading(true);
    axios.put(`/api/${role}/update-word/${editableWord.id}`, editableWord).then(res => {
      if (res.status === 200) {
        getWords()
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenEditWordModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // create village
  const createVillage = () => {
    setLoading(true);
    axios.post(`/api/${role}/create-village`, inputVillage).then(res => {
      if (res.status === 200) {
        getWords()
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setInputVillage({ ...inputVillage, name: '' })
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // update village
  const updateVillage = () => {
    setLoading(true);
    axios.put(`/api/${role}/update-village/${editableVillage.id}`, editableVillage).then(res => {
      if (res.status === 200) {
        getWords()
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        setOpenEditVillageModal(false)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getWords();
      getPostOffices();
    }
  }, [getPostOffices, getWords, role]);


  return (
    <div className="tab-pane fade show active" id="navWordVillage" role="tabpanel" aria-labelledby="nav-home-tab">
      {/* ---------------- Tab Content Start ---------------- */}

      <div className="row mt-1 p-3 rounded main-bg">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 ml-auto">
              <button type="button" className="btn btn-block btn-lightblue" onClick={() => setOpenAddWordModal(true)}>
                <i class="fa fa-plus mr-2"></i> যোগ করুন
              </button>
            </div>
          </div>
        </div>


        <div className="col-lg-12 col-md-12 col-sm-12 table-responsive py-4">
          {words.length > 0 ?
            <table id="wordTable" className="table table-striped" width="100%;">
              <thead>
                <tr>
                  <th scope="col" className="text-center">ক্রমিক</th>
                  <th scope="col">ওয়ার্ড</th>
                  <th scope="col" className="text-center">গ্রাম</th>
                  <th scope="col" className="text-center">অবস্থা</th>
                  <th scope="col" className="text-center actionTable">একশন</th>
                </tr>
              </thead>
              <tbody>
                {words.map((word, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{word.word}</td>
                    <td className="text-center">
                      <button type="button" className="btn-icon btn-outline-lightblue"
                        onClick={() => { setInputVillage({ ...inputVillage, word_id: word.id }); setOpenAddVillageModal(true) }}>
                        যোগ/এডিট করুন
                      </button>
                    </td>
                    <td className={`text-center ${parseInt(word.status) === 1 ? 'text-success' : 'text-danger'}`}>
                      {parseInt(word.status) === 1 ? 'চলমান' : 'স্থগিত'}
                    </td>
                    <td className="text-center">
                      <button type="button" className="btn-icon btn-outline-lightblue" onClick={() => { setEditableWord(word); setOpenEditWordModal(true) }}>
                        <EditIcon /> Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            : loading ? <SpinnerPulse color={'dark'} />
              : 'কোনো ডেটা পাওয়া যায় নি'}
        </div>

      </div>
      {/* ---------------- Tab Content End ---------------- */}


      {/* add word modal */}
      <ModalDialog
        title={'ওয়ার্ড যোগ করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="ওয়ার্ড নম্বর" fullWidth value={inputWord.word}
              onChange={(e) => setInputWord({ ...inputWord, word: e.target.value })} margin='normal' size='small' />
          </div>
        }
        onOpen={openAddWordModal}
        onClose={() => setOpenAddWordModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={createWord}
        loading={loading}
      />

      {/* edit word modal */}
      <ModalDialog
        title={'ওয়ার্ড সম্পাদনা করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="ওয়ার্ড নম্বর" fullWidth value={editableWord.word}
              onChange={(e) => setEditableWord({ ...editableWord, word: e.target.value })} margin='normal' size='small' />

            <FormControlLabel control={
              <Switch checked={parseInt(editableWord.status) === 1}
                onChange={(e) => { setEditableWord({ ...editableWord, status: e.target.checked ? 1 : 0 }); }} />
            } label={`সচল`} />
          </div>
        }
        onOpen={openEditWordModal}
        onClose={() => setOpenEditWordModal(false)}
        confirmText={'সম্পাদনা করুন'}
        onConfirm={updateWord}
        loading={loading}
      />


      {/* add village modal */}
      <ModalDialog
        title={'গ্রাম যোগ/এডিট করুন'}
        content={
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-8 border-right">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                  <h4 className="text-center">
                    গ্রামসমূহ তালিকা
                  </h4>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
                  <table className="table table-striped" width="100%;">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ক্রমিক</th>
                        <th scope="col" className="text-center">গ্রামের নম্বর</th>
                        <th scope="col" className="">গ্রামের নাম</th>
                        <th scope="col" className="">পোস্ট অফিস</th>
                        <th scope="col" className="text-center">অবস্থা</th>
                        <th scope="col" className="text-center actionTables">একশন</th>
                      </tr>
                    </thead>
                    <tbody>
                      {words.find((word) => word.id === inputVillage.word_id)?.villages.length > 0 ?
                        words.find((word) => word.id === inputVillage.word_id)?.villages.map((village, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{village.village_no}</td>
                            <td className="">{village.name}</td>
                            <td className="">{`${village.post_office?.post_code} - ${village.post_office?.name}`}</td>
                            <td className={`text-center ${parseInt(village.status) === 1 ? 'text-success' : 'text-danger'}`}>
                              {parseInt(village.status) === 1 ? 'চলমান' : 'স্থগিত'}
                            </td>
                            <td className="text-center">
                              <button type="button" className="btn-icon btn-outline-lightblue" onClick={() => { setEditableVillage(village); setOpenEditVillageModal(true) }} >
                                <EditIcon /> Edit
                              </button>
                            </td>
                          </tr>
                        ))
                        : <tr><td className="text-center" colSpan={4}>কোনো গ্রাম পাওয়া যায় নি</td></tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* add village section */}
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="row m-1">
                <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                  <h4 className="text-center">নতুন গ্রাম যোগ করুন</h4>
                </div>

                <div className='p-2'>
                  <TextField label="গ্রামের ওয়ার্ড নং" disabled fullWidth value={words.find((word) => word.id === inputVillage.word_id)?.word}
                    margin='normal' size='small' />

                  <TextField label="পোস্ট অফিস" select fullWidth value={inputVillage.post_office_id}
                    onChange={(e) => setInputVillage({ ...inputVillage, post_office_id: e.target.value })} margin='normal' size='small' >
                    {postOffices.map((postOffice, index) => (
                      <MenuItem key={index} value={postOffice.id}>{`${postOffice.post_code} - ${postOffice.name}`}</MenuItem>
                    ))}
                  </TextField>

                  <TextField label="গ্রামের নম্বর" type='number' fullWidth value={inputVillage.village_no}
                    onChange={(e) => setInputVillage({ ...inputVillage, village_no: e.target.value })} margin='normal' size='small' />

                  <TextField label="গ্রামের নাম" fullWidth value={inputVillage.name}
                    onChange={(e) => setInputVillage({ ...inputVillage, name: e.target.value })} margin='normal' size='small' />
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3"></div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <button type="button" className="btn btn-block btn-lightblue" onClick={() => createVillage()} disabled={loading}>
                    {loading ? <SpinnerPulse /> : 'জমা দিন'}
                  </button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3"></div>
              </div>
            </div>
          </div>
        }
        onOpen={openAddVillageModal}
        onClose={() => setOpenAddVillageModal(false)}
        maxWidth={'lg'}
      />

      {/* update village modal */}
      <ModalDialog
        title={'গ্রাম সম্পাদনা করুন'}
        content={
          <div style={{ maxWidth: '350px' }}>
            <TextField label="গ্রামের ওয়ার্ড নং" disabled fullWidth value={words.find((word) => word.id === editableVillage.word_id)?.word}
              margin='normal' size='small' />

            <TextField label="পোস্ট অফিস" select fullWidth value={editableVillage.post_office_id}
              onChange={(e) => setEditableVillage({ ...editableVillage, post_office_id: e.target.value })} margin='normal' size='small' >
              {postOffices.map((postOffice, index) => (
                <MenuItem key={index} value={postOffice.id}>{`${postOffice.post_code} - ${postOffice.name}`}</MenuItem>
              ))}
            </TextField>

            <TextField label="গ্রামের নম্বর" type='number' fullWidth value={editableVillage.village_no}
              onChange={(e) => setEditableVillage({ ...editableVillage, village_no: e.target.value })} margin='normal' size='small' />

            <TextField label="গ্রামের নাম" fullWidth value={editableVillage.name}
              onChange={(e) => setEditableVillage({ ...editableVillage, name: e.target.value })} margin='normal' size='small' />

            <FormControlLabel control={
              <Switch checked={parseInt(editableVillage.status) === 1}
                onChange={(e) => { setEditableVillage({ ...editableVillage, status: e.target.checked ? 1 : 0 }); }} />
            } label={`সচল`} />
          </div>
        }
        onOpen={openEditVillageModal}
        onClose={() => setOpenEditVillageModal(false)}
        confirmText={'সম্পাদনা করুন'}
        onConfirm={updateVillage}
        loading={loading}
      />


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </div>
  )
}
