import React, { useCallback, useEffect, useState } from "react";
import "../../../assets/css/TaxCollectionWordWiseListPrint.scss";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { SingleTaxPrintPage } from "./SingleTaxPrintPage";
import { useLocalStorage } from "usehooks-ts";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const TaxCollectionWordWiseListPrint = () => {
  const word_id = useLocation().state.word_id;

  const [userDetails] = useLocalStorage('user', {});

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [economicYear, setEconomicYear] = useState({});
  const [houseTaxes, setHouseTaxes] = useState([])

  const [taxMembers, setTaxMembers] = useState([]);


  // get current economic year
  const getEconomicYear = useCallback(() => {
    axios.get(`/api/${role}/current-economic-year`).then(res => {
      if (res.status === 200) {
        setEconomicYear(res.data.current_year)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    });
  }, [role]);

  // get all houseTaxes
  const getHouseTaxes = useCallback(() => {
    axios.get(`/api/${role}/house-taxes`).then(res => {
      if (res.status === 200) {
        setHouseTaxes(res.data.taxes)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    });
  }, [role])

  // get all tax members
  const getAllTaxMembers = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/tax-member/${word_id}`).then(res => {
      if (res.status === 200) {
        setTaxMembers(res.data.tax_members)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role, word_id])


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getAllTaxMembers();
      getEconomicYear();
      getHouseTaxes();
    }
  }, [getAllTaxMembers, getEconomicYear, getHouseTaxes, role]);


  return (
    <>
      <div className="row main-bg pl-5 pr-5 m-0">
        {/* ---------------- Single Page Start ---------------- */}

        {taxMembers.length > 0 ?
          taxMembers.map((taxMember, index) => {
            return (
              <SingleTaxPrintPage key={index}
                taxMember={taxMember}
                userDetails={userDetails}
                economicYear={economicYear}
                houseTaxes={houseTaxes}
              />
            );
          })
          : loading ? <SpinnerPulse color={'dark'} />
            : 'কোনো ডেটা পাওয়া যায় নি'}

        {/* ---------------- Single Page End ---------------- */}
      </div>


      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};



// const SinglePage = (props) => {
//   const { collection } = props;
//   const navigate = useNavigate();

//   const Print = () => {
//     document.getElementById("printBtn").style.cssText = " display: none;";
//     document.getElementById("closetBtn").style.cssText = " display: none;";
//     window.print();
//     document.getElementById("printBtn").style.cssText = " display: block;";
//     document.getElementById("closetBtn").style.cssText = " display: block;";
//   };

//   const Close = () => {
//     navigate(-1);
//     window.close();
//   };

//   return (
//     <>
//       {" "}
//       <div className="col-lg-12 col-md-12 col-sm-12 bgFrame">
//         <div className="row">
//           <div className="col-lg-12 col-md-12 col-sm-12 p-2">
//             <div className="row">
//               <div className="col-lg-2 col-md-2 col-sm-2 p-1">
//                 <button
//                   type="button"
//                   className="btn btn-block btn-danger"
//                   onClick={Close}
//                   id="closetBtn"
//                 >
//                   ফিরে যান
//                 </button>
//               </div>
//               <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
//               <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
//               <div className="col-lg-2 col-md-2 col-sm-2 p-1">
//                 <button
//                   type="button"
//                   className="btn btn-block btn-lightblue"
//                   onClick={Print}
//                   id="printBtn"
//                 >
//                   প্রিন্ট
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-left">
//             <img
//               src={GovBDLogo}
//               alt="GovBDLogo"
//               style={{ width: "100px" }}
//               className=""
//             // style="opacity: .8"
//             />
//           </div>
//           <div className="col-lg-8 col-md-8 col-sm-8 p-2 text-center">
//             <h4 className="main-color-green">
//               গণপ্রজাতন্ত্রী বাংলাদেশ সরকার ( স্থানীয় সরকার বিভাগ )
//             </h4>
//             <h1 className="font-weight-bolder main-color-red">
//               ৭ নং জগদল ইউনিয়ন পরিষদ কার্যালয়
//             </h1>
//             <h4 className="main-color-green">
//               উপজেলাঃ {collection.taxMember.upozilla}, জেলাঃ{" "}
//               {collection.taxMember.district}
//             </h4>
//           </div>
//           <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-right">
//             <img
//               src={Muzib100Logo}
//               alt="Muzib100Logo"
//               style={{ width: "150px" }}
//               className=""
//             // style="opacity: .8"
//             />
//           </div>

//           <div className="col-lg-12 col-md-12 col-sm-12 border-b-4-black pl-5 pr-5"></div>

//           <div className="col-lg-12 col-md-12 col-sm-12 text-center">
//             <h3 className="main-color-green font-weight-bolder mt-4">
//               বসত-বাড়ির কর বিল
//             </h3>
//           </div>

//           <div className="col-lg-5 col-md-5 col-sm-5 text-left">
//             <div className="row pb-4">
//               <div className="col-lg-5 col-md-5 col-sm-5 text-center">
//                 <button
//                   type="button"
//                   class="btn btn-block btn-success main-bg-green"
//                 >
//                   অফিস কপি
//                 </button>
//               </div>
//               <div className="col-lg-7 col-md-7 col-sm-7 text-center"></div>
//             </div>

//             <h5 className="">{collection.taxMember.nameOfThanaProdhan}</h5>
//             <h5 className="">পিতাঃ {collection.taxMember.fathersName}</h5>
//             <h5 className="">
//               গ্রামঃ {collection.taxMember.upozilla}, ওয়ার্ড নং-{" "}
//               {collection.taxMember.wardNo}, ইউনিয়নঃ জগদল
//             </h5>
//             <h5 className="">
//               ডাকঘরঃ {collection.taxMember.postOffice}, উপজেলাঃ{" "}
//               {collection.taxMember.upozilla}, জেলাঃ{" "}
//               {collection.taxMember.district}
//             </h5>

//             <table
//               className="table table-sm table-bordered border-black mt-4"
//               width="100%;"
//             >
//               <thead>
//                 <tr className="vendorListHeading">
//                   <th scope="col" className="text-center">
//                     হোল্ডিং নং
//                   </th>
//                   <th scope="col" className="text-center">
//                     {collection.taxMember.holdingNo}
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr className="vendorListHeading">
//                   <td className="text-center">ভোটার আইডি নং</td>
//                   <td className="text-center">
//                     {collection.taxMember.voterIdNo}
//                   </td>
//                 </tr>
//                 <tr className="vendorListHeading">
//                   <td className="text-center">মোবাইল নাম্বার</td>
//                   <td className="text-center">
//                     {collection.taxMember.mobileNo}
//                   </td>
//                 </tr>
//               </tbody>
//             </table>

//             <div className="row mt-5 pt-4">
//               <div className="col-lg-5 col-md-5 col-sm-5 text-center">
//                 <h5 className="border-top">আদায়কারীর স্বাক্ষর</h5>
//               </div>
//               <div className="col-lg-7 col-md-7 col-sm-7 text-center">
//                 <h5 className="border-top">চেয়ারম্যান - স্বাক্ষর ও সীল</h5>
//               </div>
//             </div>

//             <h5 className="main-color-red mt-3">
//               পরিষদের বিকাশ নাম্বারঃ ০১৫০০১১২২৩৩১
//             </h5>
//           </div>

//           <div className="col-lg-2 col-md-2 col-sm-2 text-right"> </div>

//           <div className="col-lg-5 col-md-5 col-sm-5 text-right">
//             <h5 className="">
//               প্রস্তুতের অর্থ বছরঃ {collection.financialYear} ইং
//             </h5>
//             <h5 className="">
//               বিল প্রস্তুতের তারিখঃ{" "}
//               {new Date(collection.createdAt).toLocaleDateString()} ইং
//             </h5>
//             <h5 className="">
//               বিল পরিশোধের শেষ তারিখঃ{" "}
//               {new Date(collection.date).toLocaleDateString()} ইং
//             </h5>

//             <table
//               className="table table-sm table-bordered border-black mt-3"
//               width="100%;"
//             >
//               <thead>
//                 <tr className="vendorListHeadingC">
//                   <th scope="col" colSpan="2" className="text-center">
//                     ঘর প্রতি নির্ধারিত কর
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">পাকা</td>
//                   <td className="text-center">১২০ টাকা</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">আধাপাকা</td>
//                   <td className="text-center">১০০ টাকা</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">কাঁচা</td>
//                   <td className="text-center">৭০ টাকা</td>
//                 </tr>
//               </tbody>
//             </table>

//             <table
//               className="table table-sm table-bordered border-black"
//               width="100%;"
//             >
//               <thead>
//                 <tr className="vendorListHeadingC">
//                   <th scope="col" colSpan="2" className="text-center">
//                     চলমান অর্থ বছর
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">বাড়ির ধরন</td>
//                   <td className="text-center">কাঁচা ঘর</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">রুম সংখ্যা</td>
//                   <td className="text-center">১</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">নির্ধারিত কর</td>
//                   <td className="text-center">৭০ টাকা</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">বকেয়া (যদি থাকে)</td>
//                   <td className="text-center">১০ টাকা</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">মোট</td>
//                   <td className="text-center">৮০ টাকা</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>

//           {/* <div className="col-lg-12 col-md-12 col-sm-12 p-2 mt-5"> </div> */}

//           <div className="col-lg-12 col-md-12 col-sm-12 border-bottom pl-5 pr-5 mt-4 mb-5"></div>

//           <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-left">
//             <img
//               src={GovBDLogo}
//               alt="GovBDLogo"
//               style={{ width: "100px" }}
//               className=""
//             // style="opacity: .8"
//             />
//           </div>
//           <div className="col-lg-8 col-md-8 col-sm-8 p-2 text-center">
//             <h4 className="main-color-green">
//               গণপ্রজাতন্ত্রী বাংলাদেশ সরকার ( স্থানীয় সরকার বিভাগ )
//             </h4>
//             <h1 className="font-weight-bolder main-color-red">
//               ৭ নং জগদল ইউনিয়ন পরিষদ কার্যালয়
//             </h1>
//             <h4 className="main-color-green">উপজেলাঃ দিরাই, জেলাঃ সুনামগঞ্জ</h4>
//           </div>
//           <div className="col-lg-2 col-md-2 col-sm-2 p-2 text-right">
//             <img
//               src={Muzib100Logo}
//               alt="Muzib100Logo"
//               style={{ width: "150px" }}
//               className=""
//             // style="opacity: .8"
//             />
//           </div>

//           <div className="col-lg-12 col-md-12 col-sm-12 border-b-4-black pl-5 pr-5"></div>

//           <div className="col-lg-12 col-md-12 col-sm-12 text-center">
//             <h3 className="main-color-green font-weight-bolder mt-4">
//               বসত-বাড়ির কর বিল
//             </h3>
//           </div>

//           <div className="col-lg-5 col-md-5 col-sm-5 text-left">
//             <div className="row pb-4">
//               <div className="col-lg-5 col-md-5 col-sm-5 text-center">
//                 <button
//                   type="button"
//                   class="btn btn-block btn-success main-bg-green"
//                 >
//                   গ্রাহক কপি
//                 </button>
//               </div>
//               <div className="col-lg-7 col-md-7 col-sm-7 text-center"></div>
//             </div>

//             <h5 className="">{collection.nameOfThanaProdhan}</h5>
//             <h5 className="">পিতাঃ {collection.fathersName}</h5>
//             <h5 className="">
//               গ্রামঃ {collection.upozilla}, ওয়ার্ড নং-{" "}
//               {collection.taxMember.wardNo}, ইউনিয়নঃ জগদল
//             </h5>
//             <h5 className="">
//               ডাকঘরঃ {collection.taxMember.postOffice}, উপজেলাঃ{" "}
//               {collection.taxMember.upozilla}, জেলাঃ{" "}
//               {collection.taxMember.district}
//             </h5>

//             <table
//               className="table table-sm table-bordered border-black mt-4"
//               width="100%;"
//             >
//               <thead>
//                 <tr className="vendorListHeading">
//                   <th scope="col" className="text-center">
//                     হোল্ডিং নং
//                   </th>
//                   <th scope="col" className="text-center">
//                     {collection.taxMember.holdingNo}
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr className="vendorListHeading">
//                   <td className="text-center">ভোটার আইডি নং</td>
//                   <td className="text-center">
//                     {collection.taxMember.voterIdNo}
//                   </td>
//                 </tr>
//                 <tr className="vendorListHeading">
//                   <td className="text-center">মোবাইল নাম্বার</td>
//                   <td className="text-center">
//                     {collection.taxMember.mobileNo}
//                   </td>
//                 </tr>
//               </tbody>
//             </table>

//             <div className="row mt-5 pt-4">
//               <div className="col-lg-5 col-md-5 col-sm-5 text-center">
//                 <h5 className="border-top">আদায়কারীর স্বাক্ষর</h5>
//               </div>
//               <div className="col-lg-7 col-md-7 col-sm-7 text-center">
//                 <h5 className="border-top">চেয়ারম্যান - স্বাক্ষর ও সীল</h5>
//               </div>
//             </div>

//             <h5 className="main-color-red mt-3">
//               পরিষদের বিকাশ নাম্বারঃ ০১৫০০১১২২৩৩১
//             </h5>
//           </div>

//           <div className="col-lg-2 col-md-2 col-sm-2 text-right"> </div>

//           <div className="col-lg-5 col-md-5 col-sm-5 text-right">
//             <h5 className="">
//               প্রস্তুতের অর্থ বছরঃ {collection.financialYear} ইং
//             </h5>
//             <h5 className="">
//               বিল প্রস্তুতের তারিখঃ{" "}
//               {new Date(collection.createdAt).toLocaleDateString()} ইং
//             </h5>
//             <h5 className="">
//               বিল পরিশোধের শেষ তারিখঃ{" "}
//               {new Date(collection.date).toLocaleDateString()} ইং
//             </h5>

//             <table
//               className="table table-sm table-bordered border-black mt-3"
//               width="100%;"
//             >
//               <thead>
//                 <tr className="vendorListHeadingC">
//                   <th scope="col" colSpan="2" className="text-center">
//                     ঘর প্রতি নির্ধারিত কর
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">পাকা</td>
//                   <td className="text-center">১২০ টাকা</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">আধাপাকা</td>
//                   <td className="text-center">১০০ টাকা</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">কাঁচা</td>
//                   <td className="text-center">৭০ টাকা</td>
//                 </tr>
//               </tbody>
//             </table>

//             <table
//               className="table table-sm table-bordered border-black"
//               width="100%;"
//             >
//               <thead>
//                 <tr className="vendorListHeadingC">
//                   <th scope="col" colSpan="2" className="text-center">
//                     চলমান অর্থ বছর
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">বাড়ির ধরন</td>
//                   <td className="text-center">কাঁচা ঘর</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">রুম সংখ্যা</td>
//                   <td className="text-center">১</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">নির্ধারিত কর</td>
//                   <td className="text-center">৭০ টাকা</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">বকেয়া (যদি থাকে)</td>
//                   <td className="text-center">১০ টাকা</td>
//                 </tr>
//                 <tr className="vendorListHeadingC">
//                   <td className="text-center">মোট</td>
//                   <td className="text-center">৮০ টাকা</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//       ;
//     </>
//   );
// };

export default TaxCollectionWordWiseListPrint;
