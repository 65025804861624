import React from "react";

const TaxMemberIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0V20H18V5.71429H11.4545V0H0ZM13.0909 0.418531V4.28571H17.5206L13.0909 0.418531ZM4.09091 2.85714H5.72727V4.28571H4.09091V2.85714ZM9.23984 3.06633L10.3968 4.07637L4.66953 9.07637L3.51257 8.06633L9.23984 3.06633ZM8.18186 7.85735H9.81823V9.28592H8.18186V7.85735ZM11.4546 7.85735H14.7273V9.28592H11.4546V7.85735ZM3.27277 11.4288H14.7273V12.8573H3.27277V11.4288ZM3.27277 15.0002H14.7273V16.4288H3.27277V15.0002Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
      </svg>
    </>
  );
};

export default TaxMemberIcon;
