import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import CustomSnackbar from "../../../utilities/SnackBar";
import { useLocalStorage } from "usehooks-ts";

const TradeLicenseRegisteredYearlyListPrint = () => {

  const [userDetails] = useLocalStorage('user', {});
  const navigate = useNavigate();
  const year = useLocation().state.year;
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [tradeLicenseList, setTradeLicenseList] = React.useState([]);


  // get trade license list by year_id
  const getTradeLicenseList = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/trade-licenses/yearly/${year.id}`).then(res => {
      if (res.status === 200) {
        setTradeLicenseList(res.data.trade_licenses)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }, [role, year.id])


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getTradeLicenseList();
    }
  }, [getTradeLicenseList, role]);


  const Print = () => {
    document.getElementById("printBtn").style.cssText = " display: none;";
    document.getElementById("closetBtn").style.cssText = " display: none;";
    window.print();
    document.getElementById("printBtn").style.cssText = " display: block;";
    document.getElementById("closetBtn").style.cssText = " display: block;";
  };


  return (
    <>
      <div className="row main-bg p-2 m-0">
        <div className="col-lg-12 col-md-12 col-sm-12 p-2">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2 p-1">
              <button type="button" className="btn btn-block btn-danger" onClick={() => navigate(-1)} id="closetBtn">
                ফিরে যান
              </button>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
            <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
            <div className="col-lg-2 col-md-2 col-sm-2 p-1">
              <button type="button" className="btn btn-block btn-lightblue" onClick={Print} id="printBtn">
                প্রিন্ট
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 p-2 text-center">
          <h2>ট্রেড লাইসেন্স রেজিস্টার তালিকা</h2>
          <h4>{`${userDetails.union?.address}, ${userDetails.union?.upazila}`}</h4>
          <h4>অর্থ বছরঃ {year.name}</h4>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 table-responsive mt-5">
          {tradeLicenseList.length > 0 ?
            <table className="table table-striped" width="100%;">
              <thead>
                <tr>
                  <th scope="col" className="text-center">ক্রমিক</th>
                  <th scope="col">তারিখ</th>
                  <th scope="col">লাইসেন্স নম্বর</th>
                  <th scope="col">প্রতিষ্ঠানের নাম</th>
                  <th scope="col">লাইসেন্সধারীর নাম</th>
                  <th scope="col">পিতার নাম</th>
                  <th scope="col">গ্রাম</th>
                  <th scope="col">ব্যবসায়ের ধরন</th>
                  <th scope="col" className="text-right">ফি</th>
                  <th scope="col" className="text-right">ভ্যাট</th>
                  <th scope="col" className="text-right">কর</th>
                  <th scope="col">অনুস্বাক্ষর</th>
                </tr>
              </thead>
              <tbody>
                {tradeLicenseList.map((tradeLicense, index) => (
                  <tr key={tradeLicense.id}>
                    <th scope="row" className="text-center">{index + 1}</th>
                    <td>{new Date(tradeLicense.issue_date).toLocaleDateString('bn-BD', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                    <td>{123124}</td>
                    <td>{tradeLicense.business_name}</td>
                    <td>{tradeLicense.owners_name}</td>
                    <td>{tradeLicense.fathers_name}</td>
                    <td>{tradeLicense.village?.name}</td>
                    <td>{tradeLicense.business_type}</td>
                    <td className="text-right">৳ {tradeLicense.license_fee}</td>
                    <td className="text-right">৳ {tradeLicense.license_vat}</td>
                    <td className="text-right">৳ {tradeLicense.capital_tax}</td>
                    <td> </td>
                  </tr>
                ))}
              </tbody>
            </table>

            : loading ? <SpinnerPulse color={'dark'} />
              : 'কোনো তথ্য পাওয়া যায় নি'}
        </div>
      </div>


      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default TradeLicenseRegisteredYearlyListPrint;
