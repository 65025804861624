import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts';

export const TaxMemberProfile = () => {

  const taxMember = useLocation().state.taxMember;
  const [userDetails] = useLocalStorage('user', {});
  const navigate = useNavigate();

  // console.log(taxMember);

  return (
    <div>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="m-0">
                  {taxMember.name_of_khana_prodhan}
                </h1> */}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">কর আদায়</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={() => navigate(-1)}>
                  সবগুলো দেখুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center font-weight-bolder">
                  ব্যক্তিগত তথ্য
                </h4>
              </div>

              {/* merital_status: "বিবাহিত", male_citizen: "", female_citizen: "", digital_birth_reg: "আছে", bank_account: "আছে",
              money_trnsfer: "", telecommunication: "", paved_bathroom: "আছে", tubewell: "আছে", foreigner: "নাই", electricity: "আছে", tv: "আছে", tv_color: "রঙিন",
              dish_line: "আছে", internet: "আছে", digital_device: "", internet_usage: "", educational_qualification: "", family_status: "", freedom_fighter: "",
              healthcare: "", govt_facility: "", */}

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 border-right">
                    <dl class="row">
                      <dt class="col-sm-4">নাম:</dt>
                      <dd class="col-sm-8">
                        {taxMember.name_of_khana_prodhan}
                      </dd>
                      <dt class="col-sm-4">পিতার নাম:</dt>
                      <dd class="col-sm-8">
                        {taxMember.fathers_name}
                      </dd>
                      <dt class="col-sm-4">মাতার নাম:</dt>
                      <dd class="col-sm-8">
                        {taxMember.mothers_name}
                      </dd>
                      <dt class="col-sm-4">স্বামী/স্ত্রী-র নাম:</dt>
                      <dd class="col-sm-8">
                        {taxMember.spouse_name}
                      </dd>
                      <dt class="col-sm-4">লিঙ্গ:</dt>
                      <dd class="col-sm-8">
                        {taxMember.gender}
                      </dd>
                      <dt class="col-sm-4">জন্ম তারিখ:</dt>
                      <dd class="col-sm-8">
                        {new Date(taxMember.date_of_birth).toLocaleDateString('bn-BD', { day: 'numeric', month: 'long', year: 'numeric' })}
                      </dd>
                      <dt class="col-sm-4">ধর্ম:</dt>
                      <dd class="col-sm-8">
                        {taxMember.religion}
                      </dd>
                      <dt class="col-sm-4">বৈবাহিক অবস্থা:</dt>
                      <dd class="col-sm-8">
                        {taxMember.merital_status}
                      </dd>
                    </dl>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 border-right">
                    <dl class="row">
                      <dt class="col-sm-5">মোবাইল নম্বর:</dt>
                      <dd class="col-sm-7">
                        {taxMember.phone}
                      </dd>
                      {taxMember.email !== null &&
                        <>
                          <dt class="col-sm-5">ই-মেইল:</dt>
                          <dd class="col-sm-7">
                            {taxMember.email}
                          </dd>
                        </>}
                      <dt class="col-sm-5">ভোটার আইডি নং:</dt>
                      <dd class="col-sm-7">
                        {taxMember.nid}
                      </dd>
                      <dt class="col-sm-5">পেশা:</dt>
                      <dd class="col-sm-7">
                        {taxMember.occupation}
                      </dd>
                      <dt class="col-sm-5">পুরুষ সংখ্যা:</dt>
                      <dd class="col-sm-7">
                        {taxMember.number_of_male}
                      </dd>
                      <dt class="col-sm-5">নারী সংখ্যা:</dt>
                      <dd class="col-sm-7">
                        {taxMember.number_of_female}
                      </dd>
                      <dt class="col-sm-5">পুরুষ ভোটার:</dt>
                      <dd class="col-sm-7">
                        {taxMember.male_citizen}
                      </dd>
                      <dt class="col-sm-5">নারী ভোটার:</dt>
                      <dd class="col-sm-7">
                        {taxMember.female_citizen}
                      </dd>
                    </dl>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <dl class="row">
                      <dt class="col-sm-5">হোল্ডিং নম্বর:</dt>
                      <dd class="col-sm-7">
                        {taxMember.holding_no}
                      </dd>
                      <dt class="col-sm-5">আগের হোল্ডিং নম্বর:</dt>
                      <dd class="col-sm-7">
                        {taxMember.previous_holding_no}
                      </dd>
                      <dt class="col-sm-5">ওয়ার্ড নং:</dt>
                      <dd class="col-sm-7">
                        {taxMember.word?.word}
                      </dd>
                      <dt class="col-sm-5">গ্রাম/মহল্লা:</dt>
                      <dd class="col-sm-7">
                        {taxMember.village?.name}
                      </dd>
                      <dt class="col-sm-5">পোস্ট অফিস:</dt>
                      <dd class="col-sm-7">
                        {`${taxMember.village?.post_office?.post_code} - ${taxMember.village?.post_office?.name}`}
                      </dd>
                      {taxMember.road_no !== null &&
                        <>
                          <dt class="col-sm-5">রোড নং:</dt>
                          <dd class="col-sm-7">
                            {taxMember.road_no}
                          </dd>
                        </>}
                      {taxMember.block !== null &&
                        <>
                          <dt class="col-sm-5">ব্লক:</dt>
                          <dd class="col-sm-7">
                            {taxMember.block}
                          </dd>
                        </>}
                      <dt class="col-sm-5">উপজেলাঃ</dt>
                      <dd class="col-sm-7">
                        {userDetails.union?.upazila}
                      </dd>
                      <dt class="col-sm-5">জেলাঃ</dt>
                      <dd class="col-sm-7">
                        {userDetails.union?.district}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>


            {/* house info */}
            <div className="row mt-4 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center font-weight-bolder">
                  বসত ঘর/অবকাঠামোর ধরন
                </h4>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
                <table className="table table-striped" width="100%;">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">#</th>
                      <th scope="col" className="text-center">বাড়ির ধরন</th>
                      <th scope="col" className="text-center">ঘরের সংখ্যা</th>
                      <th scope="col" className="text-center">অবস্থা</th>
                      <th scope="col" className="text-right">কর</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taxMember.house_type_of_tax_member.map((houseInfo, index) => (
                      <tr>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{houseInfo.house_tax?.house_type}</td>
                        <td className="text-center">{houseInfo.number_of_room}</td>
                        <td className="text-center">{houseInfo.situation}</td>
                        <td className="text-right">
                          ৳ {houseInfo.situation === "করযোগ্য" ? houseInfo.house_tax?.tax : 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th> </th>
                      <th> </th>
                      <th> </th>
                      <th scope="row" className="text-center">মোট কর</th>
                      <th scope="row" className="text-right">
                        ৳ {taxMember.house_type_of_tax_member.reduce((acc, houseInfo) => acc +
                          parseInt(houseInfo.situation === "করযোগ্য" ? houseInfo.house_tax?.tax : 0), 0)}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>


              {/* business tax source */}
              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center font-weight-bolder">
                  ব্যবসায়ীক করের উৎস
                </h4>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
                <table className="table table-striped" width="100%;">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">#</th>
                      <th scope="col" className="text-center">ব্যবসার ধরন</th>
                      <th scope="col" className="text-right">কর</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taxMember.business_of_tax_member.map((businessInfo, index) => (
                      <tr>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{businessInfo.business_tax?.business_type}</td>
                        <td className="text-right">
                          ৳ {businessInfo.business_tax?.tax}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th> </th>
                      <th scope="row" className="text-center">মোট কর</th>
                      <th scope="row" className="text-right">
                        ৳ {taxMember.business_of_tax_member.reduce((acc, businessInfo) => acc + parseInt(businessInfo.business_tax?.tax), 0)}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>

            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
