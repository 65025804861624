import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../utilities/SnackBar";
import FileInput from "../../../utilities/FileInput";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const TradeLicenseEdit = () => {

  const license_id = useLocation().state.id;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const [economicYears, setEconomicYears] = useState([]);
  const [words, setWords] = useState([])
  const [licenseFees, setLicenseFees] = useState([])

  const [selectedLicenseFee, setSelectedLicenseFee] = useState({});

  const [tradeLicense, setTradeLicense] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTradeLicense({ ...tradeLicense, [name]: value });
  };


  // get information of tradelicense by id
  const getTradeLicenseInfo = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/trade-license/${license_id}`).then(res => {
      if (res.status === 200) {
        setTradeLicense(res.data.trade_license)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false);
    })
  }, [license_id, role])

  // get all years
  const getEconomicYears = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/economic-year`).then(res => {
      if (res.status === 200) {
        setEconomicYears(res.data.economic_years)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // get all words
  const getWords = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/all-words`).then(res => {
      if (res.status === 200) {
        setWords(res.data.words)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // get all license fees
  const getLicenseFees = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/license-fees`).then(res => {
      if (res.status === 200) {
        setLicenseFees(res.data.fees)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }, [role])


  // create trade license
  const updateTradeLicense = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('word_id', tradeLicense.word_id);
    formData.append('village_id', tradeLicense.village_id);
    formData.append('economic_year_id', tradeLicense.economic_year_id);
    formData.append('owners_name', tradeLicense.owners_name);
    formData.append('nid', tradeLicense.nid);
    formData.append('phone', tradeLicense.phone);
    formData.append('fathers_name', tradeLicense.fathers_name);
    formData.append('mothers_name', tradeLicense.mothers_name);
    formData.append('spouse_name', tradeLicense.spouse_name);
    formData.append('gender', tradeLicense.gender);
    formData.append('date_of_birth', tradeLicense.date_of_birth);
    formData.append('post_office', tradeLicense.post_office);
    formData.append('photo', tradeLicense.photo);
    formData.append('business_name', tradeLicense.business_name);
    formData.append('business_type', tradeLicense.business_type);
    formData.append('business_village', tradeLicense.business_village);
    formData.append('business_post_office', tradeLicense.business_post_office);
    formData.append('business_upazila', tradeLicense.business_upazila);
    formData.append('business_district', tradeLicense.business_district);
    formData.append('license_fee_id', tradeLicense.license_fee_id);
    formData.append('license_fee', tradeLicense.license_fee);
    formData.append('license_vat', tradeLicense.license_vat);
    formData.append('permitted_capital', tradeLicense.permitted_capital);
    formData.append('capital_tax', tradeLicense.capital_tax);
    formData.append('issue_date', tradeLicense.issue_date);

    axios.post(`/api/${role}/trade-license-update/${tradeLicense.id}`, formData).then(res => {
      if (res.status === 200) {
        setSuccess(res.data.message);
        setTimeout(() => { setSuccess('') }, 5000);
        navigate(-1);
      } else {
        setError(res.data.message);
        setTimeout(() => { setError('') }, 5000);
      }
    }).catch(err => {
      setError(err.response.data.message);
      setTimeout(() => { setError('') }, 5000);
    }).finally(() => {
      setLoading(false);
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getTradeLicenseInfo();
      getWords();
      getEconomicYears();
      getLicenseFees();
    }
  }, [getEconomicYears, getLicenseFees, getTradeLicenseInfo, getWords, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">ট্রেড লাইসেন্স ফরম</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">ট্রেড লাইসেন্স ফরম</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/trade-license-list-new`); }}>
                  সবগুলো দেখুন
                </button>
              </div>
            </div>
            {loading ? <SpinnerPulse color={'dark'} />
              :
              <div className="row mt-3 p-3 rounded main-bg">
                <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                  <h4 className="text-center">ব্যক্তিগত তথ্য</h4>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="text">মালিকের নাম</label>
                    <input type="text" className="form-control" id="text" placeholder="মালিকের নাম" name="owners_name"
                      value={tradeLicense.owners_name} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">ভোটার আইডি নং</label>
                    <input type="text" className="form-control" id="text" placeholder="ভোটার আইডি নং" name="nid"
                      value={tradeLicense.nid} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">মোবাইল নং</label>
                    <input type="text" className="form-control" id="text" placeholder="মোবাইল নং" name="phone"
                      value={tradeLicense.phone} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="text">পিতার নাম</label>
                    <input type="text" className="form-control" id="text" placeholder="পিতার নাম" name="fathers_name"
                      value={tradeLicense.fathers_name} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="text">মাতার নাম</label>
                    <input type="text" className="form-control" id="text" placeholder="মাতার নাম" name="mothers_name"
                      value={tradeLicense.mothers_name} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="text">স্বামী/স্ত্রী-র নাম</label>
                    <input type="text" className="form-control" id="text" placeholder="স্বামী/স্ত্রী-র নাম" name="spouse_name"
                      value={tradeLicense.spouse_name} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">লিঙ্গ</label>
                    <select className="form-control" name="gender" value={tradeLicense.gender} onChange={handleChange}>
                      <option value={"পুরুষ"}>পুরুষ</option>
                      <option value={"নারী"}>নারী</option>
                      <option value={"অন্যান্য"}>অন্যান্য</option>
                    </select>
                  </div>
                </div>
                {/* date of birth */}
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">জন্ম তারিখ</label>
                    <input type="date" className="form-control" id="text" placeholder="জন্ম তারিখ" name="date_of_birth"
                      value={tradeLicense.date_of_birth} onChange={handleChange} />
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">ওয়ার্ড নং</label>
                    <select className="form-control" name="word_id" value={tradeLicense.word_id}
                      onChange={(e) => setTradeLicense({ ...tradeLicense, word_id: e.target.value, village_id: '' })}>
                      <option value=''>নির্বাচন করুন</option>
                      {words.map((word, index) =>
                        <option key={index} value={word.id}>{word.word}</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">গ্রাম/মহল্লা</label>
                    <select className="form-control" name="village_id" value={tradeLicense.village_id} onChange={handleChange}
                      disabled={tradeLicense.word_id === '' ? true : false}>
                      <option value=''>নির্বাচন করুন</option>
                      {words.find(word => word.id === parseInt(tradeLicense.word_id)) &&
                        words.find(word => word.id === parseInt(tradeLicense.word_id)).villages.map((village, index) =>
                          <option key={index} value={village.id}>{village.name}</option>
                        )}
                    </select>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">ডাকঘর</label>
                    <input type="text" className="form-control" id="text" placeholder="ডাকঘর" name="post_office"
                      value={tradeLicense.post_office} onChange={handleChange} />
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">মালিকের ছবি জমা দিন</label>
                    <FileInput label={'ছবি আপলোড করুন'} state={tradeLicense.photo}
                      onUpload={(e) => setTradeLicense({ ...tradeLicense, photo: e.target.files[0] })}
                      onDelete={() => { setTradeLicense({ ...tradeLicense, photo: null }) }}
                      formats={'.jpg, .jpeg, .png, .gif, .svg, .apng, .jfif, .pjpeg, .pjp'} />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                  <h4 className="text-center">ব্যবসায়ীক তথ্য</h4>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="text">প্রতিষ্ঠানের নাম</label>
                    <input type="text" className="form-control" id="text" placeholder="প্রতিষ্ঠানের নাম" name="business_name"
                      value={tradeLicense.business_name} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="text">ব্যবসায়ের ধরণ</label>
                    <input type="text" className="form-control" id="text" placeholder="ব্যবসায়ের ধরণ" name="business_type"
                      value={tradeLicense.business_type} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">গ্রাম/মহল্লা</label>
                    <input type="text" className="form-control" id="text" placeholder="গ্রাম/মহল্লা" name="business_village"
                      value={tradeLicense.business_village} onChange={handleChange} />
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">ডাকঘর</label>
                    <input type="text" className="form-control" id="text" placeholder="ডাকঘর" name="business_post_office"
                      value={tradeLicense.business_post_office} onChange={handleChange} />
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">উপজেলা</label>
                    <input type="text" className="form-control" id="text" placeholder="উপজেলা" name="business_upazila"
                      value={tradeLicense.business_upazila} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">জেলা</label>
                    <input type="text" className="form-control" id="text" placeholder="জেলা" name="business_district"
                      value={tradeLicense.business_district} onChange={handleChange} />
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">অর্থ বছর</label>
                    <select className="form-control" name="economic_year_id" value={tradeLicense.economic_year_id} onChange={handleChange}>
                      <option value="">অর্থ বছর নির্বাচন করুন</option>
                      {economicYears.map((year, index) => (
                        <option key={index} value={year.id}>{year.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">লাইসেন্স টাইপ</label>
                    <select className="form-control" name="license_fee_id" value={tradeLicense.license_fee_id}
                      onChange={(e) => {
                        let licenseFee = licenseFees.find(fee => fee.id === parseInt(e.target.value));
                        setTradeLicense({
                          ...tradeLicense,
                          license_fee_id: e.target.value,
                          license_fee: licenseFee.fee,
                          license_vat: (licenseFee.fee * licenseFee.vat_p / 100).toFixed(0),
                        });
                        setSelectedLicenseFee(licenseFee);
                      }}>
                      <option value={""}>নির্বাচন করুন</option>
                      {licenseFees.map((fee, index) =>
                        <option key={index} value={fee.id}>{fee.license_type}</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">লাইসেন্স ফি</label>
                    <input type="text" className="form-control" id="text" placeholder="লাইসেন্স ফি" readOnly
                      value={tradeLicense.license_fee} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">ভ্যাট</label>
                    <input type="text" className="form-control" id="text" placeholder="ভ্যাট" readOnly
                      value={tradeLicense.license_vat} />
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">অনুমোদিত মূলধন</label>
                    <input type="text" className="form-control" id="text" placeholder="অনুমোদিত মূলধন" name="permitted_capital"
                      value={tradeLicense.permitted_capital} onChange={(e) => {
                        setTradeLicense({
                          ...tradeLicense,
                          permitted_capital: e.target.value,
                          capital_tax: (e.target.value * selectedLicenseFee.capital_tax_p / 100).toFixed(0),
                        });
                      }} disabled={tradeLicense.license_fee_id === ''} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">মূলধন কর</label>
                    <input type="text" className="form-control" id="text" placeholder="মূলধন কর" readOnly
                      value={tradeLicense.capital_tax} />
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="form-group">
                    <label htmlFor="text">ইস্যুর তারিখ</label>
                    <input type="date" className="form-control" id="text" placeholder="ইস্যুর তারিখ" name="issue_date"
                      value={tradeLicense.issue_date} onChange={handleChange} />
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4"></div>
                <div className="col-lg-4 col-md-4 col-sm-4 my-3">
                  <button type="button" className="btn btn-block btn-lightblue" onClick={updateTradeLicense} disabled={loading}>
                    {loading ? <SpinnerPulse /> : 'জমা দিন'}
                  </button>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
            }
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>



      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default TradeLicenseEdit;
