import React from "react";

const GalleryAddIcon = () => {
  return (
    <>
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0144 8.54335C10.7453 8.05498 11.6046 7.79431 12.4836 7.79431C13.048 7.79431 13.5055 8.25183 13.5055 8.8162C13.5055 9.38058 13.048 9.83809 12.4836 9.83809C12.0088 9.83809 11.5446 9.97889 11.1498 10.2427C10.755 10.5065 10.4473 10.8814 10.2656 11.3201C10.0839 11.7588 10.0363 12.2415 10.129 12.7072C10.2216 13.1729 10.4503 13.6007 10.786 13.9364C11.1218 14.2722 11.5495 14.5008 12.0152 14.5935C12.4809 14.6861 12.9637 14.6386 13.4023 14.4569C13.841 14.2752 14.216 13.9674 14.4798 13.5726C14.7436 13.1778 14.8844 12.7137 14.8844 12.2389C14.8844 11.6745 15.3419 11.217 15.9063 11.217C16.4706 11.217 16.9281 11.6745 16.9281 12.2389C16.9281 13.1179 16.6675 13.9772 16.1791 14.7081C15.6907 15.439 14.9966 16.0087 14.1845 16.3451C13.3723 16.6815 12.4787 16.7695 11.6165 16.598C10.7544 16.4265 9.96242 16.0032 9.34084 15.3816C8.71926 14.76 8.29596 13.9681 8.12447 13.1059C7.95297 12.2438 8.04099 11.3501 8.37739 10.538C8.71378 9.72587 9.28345 9.03172 10.0144 8.54335Z"
          className="iconBGColors"
          fill="#8D98AA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.6859 13.7203C35.2421 13.7203 35.6931 14.1807 35.6931 14.7485V23.3575C35.6931 27.7615 34.8412 31.1119 32.6746 33.336C30.5066 35.5615 27.2393 36.4369 22.9461 36.4369H12.8837C8.59185 36.4369 5.32457 35.5618 3.15627 33.3366C0.989307 31.1127 0.136719 27.7624 0.136719 23.3575V19.8961C0.136719 19.3282 0.587651 18.8679 1.1439 18.8679C1.70016 18.8679 2.15109 19.3282 2.15109 19.8961V23.3575C2.15109 27.5616 2.97714 30.2369 4.58444 31.8864C6.1904 33.5346 8.793 34.3806 12.8837 34.3806H22.9461C27.0381 34.3806 29.6406 33.5342 31.2463 31.886C32.8532 30.2364 33.6787 27.5611 33.6787 23.3575V14.7485C33.6787 14.1807 34.1296 13.7203 34.6859 13.7203Z"
          className="iconBGColors"
          fill="#8D98AA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.19819 3.91787C5.39532 1.73803 8.70609 0.880493 13.0582 0.880493H19.8622C20.4232 0.880493 20.8779 1.33167 20.8779 1.88823C20.8779 2.44478 20.4232 2.89596 19.8622 2.89596H13.0582C8.90653 2.89596 6.26444 3.72605 4.63465 5.34302C3.00485 6.95999 2.16818 9.58128 2.16818 13.7002C2.16818 14.2568 1.71342 14.708 1.15245 14.708C0.591476 14.708 0.136719 14.2568 0.136719 13.7002C0.136719 9.38241 1.00106 6.09771 3.19819 3.91787Z"
          className="iconBGColors"
          fill="#8D98AA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8516 6.80652C22.8516 6.26105 23.3249 5.81885 23.9088 5.81885H33.6465C34.2303 5.81885 34.7037 6.26105 34.7037 6.80652C34.7037 7.352 34.2303 7.7942 33.6465 7.7942H23.9088C23.3249 7.7942 22.8516 7.352 22.8516 6.80652Z"
          className="iconBGColors"
          fill="#8D98AA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.7846 0.880493C29.33 0.880493 29.7722 1.35383 29.7722 1.93771V11.6754C29.7722 12.2593 29.33 12.7326 28.7846 12.7326C28.2391 12.7326 27.7969 12.2593 27.7969 11.6754V1.93771C27.7969 1.35383 28.2391 0.880493 28.7846 0.880493Z"
          className="iconBGColors"
          fill="#8D98AA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.8374 20.313L20.8473 26.364L20.8287 26.3796C19.9789 27.0803 18.9126 27.4634 17.8121 27.4634C16.7117 27.4634 15.6454 27.0803 14.7956 26.3796C14.7864 26.3721 14.7774 26.3644 14.7685 26.3565L14.2289 25.8788C13.777 25.5142 13.222 25.3011 12.6424 25.2702C12.0592 25.239 11.4812 25.3937 10.9913 25.7121L2.69862 31.3248C2.23574 31.6381 1.60707 31.516 1.29444 31.0521C0.981819 30.5883 1.10363 29.9582 1.56651 29.6449L9.87995 24.0182C10.7307 23.4621 11.7359 23.1918 12.7501 23.246C13.7644 23.3002 14.7351 23.6763 15.522 24.3198C15.5322 24.3282 15.5423 24.3368 15.5522 24.3455L16.0934 24.8247C16.5791 25.2202 17.186 25.4363 17.8121 25.4363C18.44 25.4363 19.0486 25.219 19.5349 24.8214L26.525 18.7704L26.5436 18.7547C27.3934 18.0541 28.4597 17.671 29.5602 17.671C30.6606 17.671 31.7269 18.0541 32.5767 18.7547L32.5954 18.7705L35.3395 21.1462C35.7622 21.5121 35.8089 22.1521 35.4437 22.5757C35.0786 22.9993 34.4399 23.046 34.0172 22.6801L31.283 20.313C30.7966 19.9153 30.1881 19.6981 29.5602 19.6981C28.9323 19.6981 28.3237 19.9154 27.8374 20.313Z"
          className="iconBGColors"
          fill="#8D98AA"
        />
      </svg>
    </>
  );
};

export default GalleryAddIcon;
