import "./assets/css/App.scss";
import axios from "axios";
import AllRoutes from "./Routes";
import config from "./config";

// For DataTable Start ----------------
import $ from "jquery";
import { ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
// For DataTable End ----------------


function App() {

  // api credientials
  axios.defaults.baseURL = config.baseUrl;
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.defaults.withCredentials = true;

  // if server respond with 401 error, clear session storage and local storage
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  });

  axios.interceptors.request.use(function (config) {
    const userToken = sessionStorage.getItem('userToken') || localStorage.getItem('userToken');
    const chairmanToken = sessionStorage.getItem('chairmanToken') || localStorage.getItem('chairmanToken');
    const adminToken = sessionStorage.getItem('adminToken') || localStorage.getItem('adminToken');

    // Check the API route and attach the appropriate token
    if (config.url.includes('/api/user/')) {
      if (userToken) {
        config.headers.Authorization = `Bearer ${userToken}`;
      }
    } else if (config.url.includes('/api/chairman/')) {
      if (chairmanToken) {
        config.headers.Authorization = `Bearer ${chairmanToken}`;
      }
    } else if (config.url.includes('/api/admin/')) {
      if (adminToken) {
        config.headers.Authorization = `Bearer ${adminToken}`;
      }
    }
    return config;
  });


  // NavBar Scroll Start ----------------
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 25 ||
      document.documentElement.scrollTop > 25
    ) {
      document.getElementById("navBar").style.cssText =
        " margin-top: 0px; transition: 0.1s; scroll-behavior: smooth;";
    } else {
      document.getElementById("navBar").style.cssText =
        " margin-top: 20px; transition: 0.1s; scroll-behavior: smooth;";
    }
  }
  // NavBar Scroll End ----------------

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#example").DataTable();
  });

  // For Initialize Tooltip ----------------
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  const theme = createTheme({
    typography: {
      fontFamily: "Roboto, Kalpurush",
    },
  });

  return (
    <div className="wrapper">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AllRoutes />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
