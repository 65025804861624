import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import EyeIcon from "../../../assets/icons/EyeIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const TaxCollectionList = () => {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#collectedTaxTable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const [taxCollections, setTaxCollections] = useState([]);

  // get all collection list
  const getAllTaxCollections = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/tax-collection`).then(res => {
      if (res.status === 200) {
        setTaxCollections(res.data.tax_collections)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getAllTaxCollections();
    }
  }, [getAllTaxCollections, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">কর পরিশোধকারীদের তালিকা</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    কর পরিশোধকারীদের তালিকা
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/add-tax-collection`); }}>
                  + যোগ করুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
                {taxCollections.length > 0 ?
                  <table id="collectedTaxTable" className="table table-striped" width="100%;">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ক্রমিক</th>
                        <th scope="col">অর্থবছর</th>
                        <th scope="col">নাম</th>
                        <th scope="col">মোবাইল</th>
                        <th scope="col" className="text-right">ডেবিট টাকা</th>
                        <th scope="col" className="text-right">ক্রেডিট টাকা</th>
                        <th scope="col" className="text-right">ব্যালেন্স টাকা</th>
                        {/* <th scope="col" className="text-right">পরিশোধিত টাকা</th>
                        <th scope="col" className="text-right">বকেয়া টাকা</th> */}
                        <th scope="col" className="text-center">রশিদ</th>
                        <th scope="col" className="text-center actionTable">একশন</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taxCollections.map((taxCollection, index) => {

                        // let total_tax = taxCollection.tax_member.house_type_of_tax_member?.map((houseType) => {
                        //   return parseInt(houseType.house_tax)
                        // }).reduce((acc, curr) => acc + curr, 0);

                        return (
                          <tr key={taxCollection._id}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">
                              {taxCollection.economic_year?.name}
                            </td>
                            <td>
                              {taxCollection.tax_member?.name_of_khana_prodhan}
                            </td>
                            <td>
                              {taxCollection.tax_member?.phone}
                            </td>
                            <td className="text-right text-success">
                              {taxCollection.paid_amount}
                            </td>
                            <td className="text-right text-danger">
                              {taxCollection.due_amount}
                            </td>
                            <td className="text-right text-primary">
                              {taxCollection.paid_amount - taxCollection.due_amount}
                            </td>
                            <td className="text-center">
                              <button onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/tax-collection-invoice/print`, { state: { taxCollection: taxCollection }, }); }}
                                className="btn-icon btn-outline-lightblue">
                                প্রিন্ট
                              </button>
                            </td>
                            <td className="text-center">
                              <div className="">
                                <button type="button" className="btn-icon btn-outline-lightblue dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                  Action
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link to={`${role === 'user' ? '' : '/chairman'}/tax-collection-list/view`} state={{ taxCollection: taxCollection }}
                                      className="dropdown-item">
                                      <EyeIcon /> View
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={`${role === 'user' ? '' : '/chairman'}/tax-collection-list/edit`} state={{ taxCollection: taxCollection }}
                                      className="dropdown-item">
                                      <EditIcon /> Edit
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  : loading ? <SpinnerPulse color={'dark'} />
                    : 'কোনো তথ্য পাওয়া যায় নি'}
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>



      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default TaxCollectionList;
