import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import EyeIcon from "../../../assets/icons/EyeIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import axios from "axios";
import CustomSnackbar from "../../../utilities/SnackBar";
import ModalDialog from "../../../utilities/ModalDialog";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import { Grid, MenuItem, TextField } from "@mui/material";

const TradeLicenseRenewList = () => {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#tradeLicenseRenewTable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [role, setRole] = useState('');

  const [economicYears, setEconomicYears] = useState([]);
  const [licenseFees, setLicenseFees] = useState([])
  const [renewTradeLicenseList, setRenewTradeLicenseList] = useState([]);
  const [searchLicenseNo, setSearchLicenseNo] = useState('');
  const [searchedLicense, setSearchedLicense] = useState(null);
  const [selectedLicenseFee, setSelectedLicenseFee] = useState({});
  const [editableRenewLicense, setEditableRenewLicense] = useState({});

  const [openAddRenewModal, setOpenAddRenewModal] = useState(false);
  const [openEditRenewModal, setOpenEditRenewModal] = useState(false);

  // console.log(selectedLicenseFee.id)

  // get all years
  const getEconomicYears = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/economic-year`).then(res => {
      if (res.status === 200) {
        setEconomicYears(res.data.economic_years)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // get all license fees
  const getLicenseFees = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/license-fees`).then(res => {
      if (res.status === 200) {
        setLicenseFees(res.data.fees)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }, [role])

  // get all renew trade license
  const getRenewTradeLicense = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/renew-trade-license`).then(res => {
      if (res.status === 200) {
        setRenewTradeLicenseList(res.data.renewed_licenses)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])


  // search license
  const searchLicense = (e) => {
    e.preventDefault();
    setSearchLoading(true);
    axios.post(`/api/${role}/trade-license/search`, { license_no: searchLicenseNo }).then(res => {
      if (res.status === 200) {
        res.data.trade_license['renew_date'] = convertDate(new Date());
        setSearchedLicense(res.data.trade_license);
      } else {
        setError(res.data.message);
        setSearchedLicense(null);
        setTimeout(() => { setError('') }, 5000);
      }
    }).catch(err => {
      setError(err.response.data.message);
      setSearchedLicense(null);
      setTimeout(() => { setError('') }, 5000);
    }).finally(() => {
      setSearchLoading(false);
    });
  }

  // submit renew license
  const submitRenewLicense = () => {
    setLoading(true);
    axios.post(`/api/${role}/renew-trade-license`, searchedLicense).then(res => {
      if (res.status === 200) {
        getRenewTradeLicense();
        setSuccess(res.data.message)
        setOpenAddRenewModal(false)
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // update renew license
  const updateRenewLicense = () => {
    setLoading(true);
    axios.put(`/api/${role}/renew-trade-license/${editableRenewLicense.id}`, editableRenewLicense).then(res => {
      if (res.status === 200) {
        getRenewTradeLicense();
        setSuccess(res.data.message)
        setOpenEditRenewModal(false)
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getRenewTradeLicense();
      getEconomicYears();
      getLicenseFees();
    }
  }, [getEconomicYears, getLicenseFees, getRenewTradeLicense, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  নবায়নকৃত ট্রেড লাইসেন্সধারী প্রতিষ্ঠানের তালিকা
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">হোম</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    নবায়নকৃত ট্রেড লাইসেন্সধারী প্রতিষ্ঠানের তালিকা
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={() => setOpenAddRenewModal(true)}>
                  + যোগ করুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
                {renewTradeLicenseList.length > 0 ?
                  <table id="tradeLicenseRenewTable" className="table table-striped" width="100%;">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ক্রমিক</th>
                        <th scope="col">প্রতিষ্ঠানের নাম</th>
                        <th scope="col">মালিকের নাম</th>
                        <th scope="col">লাইসেন্স নম্বর</th>
                        <th scope="col">আইডি নং</th>
                        <th scope="col">অর্থ বছর</th>
                        <th scope="col" className="text-center actionTable">একশন</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renewTradeLicenseList.map((tradeLicense, index) => (
                        <tr key={tradeLicense.id}>
                          <td className="text-center">{index + 1}</td>
                          <td>{tradeLicense.trade_license?.business_name}</td>
                          <td>{tradeLicense.trade_license?.owners_name}</td>
                          <td>TRAD/{tradeLicense.trade_license?.license_no}</td>
                          <td>{tradeLicense.trade_license?.nid}</td>
                          <td>{tradeLicense.economic_year?.name}</td>
                          <td className="text-center">
                            <div className="">
                              <button type="button" className="btn-icon btn-outline-lightblue dropdown-toggle font-roboto"
                                data-toggle="dropdown" aria-expanded="false">
                                Action
                              </button>
                              <ul className="dropdown-menu font-roboto">
                                <li>
                                  <Link to={`${role === 'user' ? '' : '/chairman'}/trade-license-list-new/bn/print`} state={{ id: tradeLicense.trade_license?.id }}
                                    className="dropdown-item"><EyeIcon /> View</Link>
                                </li>
                                <li>
                                  <button onClick={() => { setEditableRenewLicense(tradeLicense); setOpenEditRenewModal(true) }}
                                    className="dropdown-item"><EditIcon /> Edit</button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  : loading ? <SpinnerPulse color={'dark'} />
                    : 'কোনো তথ্য পাওয়া যায় নি'}
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* add renew modal */}
      <ModalDialog
        title={'নবায়ন করুন'}
        content={
          <div>
            {/* license search */}
            <form onSubmit={searchLicense}>
              <div className="d-flex align-items-center">
                {/* license no. */}
                <div className="form-group w-100">
                  <label htmlFor="text">লাইসেন্স নং</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">TRAD/</span>
                    </div>
                    <input type="text" class="form-control" placeholder="ABCD/123456/2024" aria-label="Username" aria-describedby="basic-addon1"
                      value={searchLicenseNo} onChange={(e) => setSearchLicenseNo(e.target.value)} />
                  </div>
                </div>

                <div className="ml-2">
                  <button type="submit" className="btn btn-secondary d-flex align-items-center">
                    {searchLoading ? <SpinnerPulse /> : <><i class="fa fa-search mr-1"></i> খুঁজুন</>}
                  </button>
                </div>
              </div>
            </form>

            {/* form fields */}
            {searchedLicense !== null &&
              <Grid container spacing={2}>
                <Grid item xs={12}><div className="border-top mb-3"></div></Grid>

                <Grid item xs={6}>
                  <TextField label="মালিকের নাম" value={searchedLicense.owners_name} size="small" fullWidth disabled />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="প্রতিষ্ঠানের নাম" value={searchedLicense.business_name} size="small" fullWidth disabled />
                </Grid>


                {/* economic year */}
                <Grid item xs={6}>
                  <TextField label="অর্থ বছর" select value={searchedLicense.economic_year_id} size="small" fullWidth
                    onChange={(e) => setSearchedLicense({ ...searchedLicense, economic_year_id: e.target.value })}>
                    {economicYears.map(year => (
                      <MenuItem value={year.id}>{year.name}</MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* license type */}
                <Grid item xs={6}>
                  <TextField label="লাইসেন্স টাইপ" select value={searchedLicense.license_fee_id} size="small" fullWidth
                    onChange={(e) => {
                      let licenseFee = licenseFees.find(fee => fee.id === parseInt(e.target.value));
                      setSearchedLicense({
                        ...searchedLicense,
                        license_fee_id: e.target.value,
                        license_fee: licenseFee.fee,
                        license_vat: (licenseFee.fee * licenseFee.vat_p / 100).toFixed(0),
                      });
                      setSelectedLicenseFee(licenseFee);
                    }}>
                    <MenuItem value={""}>নির্বাচন করুন</MenuItem>
                    {licenseFees.map((fee, index) =>
                      <MenuItem key={index} value={fee.id}>{fee.license_type}</MenuItem>
                    )}
                  </TextField>
                </Grid>

                {/* license fee */}
                <Grid item xs={6}>
                  <TextField label="লাইসেন্স ফি" value={searchedLicense.license_fee} size="small" fullWidth disabled />
                </Grid>

                {/* vat */}
                <Grid item xs={6}>
                  <TextField label="ভ্যাট" value={searchedLicense.license_vat} size="small" fullWidth disabled />
                </Grid>

                {/* permitted capital */}
                <Grid item xs={6}>
                  <TextField label="অনুমোদিত মূলধন" value={searchedLicense.permitted_capital} size="small" fullWidth
                    onChange={(e) => {
                      setSearchedLicense({
                        ...searchedLicense,
                        permitted_capital: e.target.value,
                        capital_tax: (e.target.value * selectedLicenseFee.capital_tax_p / 100).toFixed(0),
                      });
                    }} disabled={selectedLicenseFee.id === undefined} />
                </Grid>

                {/* capital tax */}
                <Grid item xs={6}>
                  <TextField label="মূলধন কর" value={searchedLicense.capital_tax} size="small" fullWidth disabled />
                </Grid>

                {/* renew date */}
                <Grid item xs={6}>
                  <TextField label="নবায়ন তারিখ" type="date" size="small" fullWidth value={searchedLicense.renew_date}
                    onChange={(e) => setSearchedLicense({ ...searchedLicense, renew_date: e.target.value })} />
                </Grid>

              </Grid>
            }
          </div>
        }
        onOpen={openAddRenewModal}
        onClose={() => { setOpenAddRenewModal(false); setSearchedLicense(null) }}
        confirmText={'জমা দিন'}
        onConfirm={submitRenewLicense}
        loading={loading}
      />

      {/* edit renew modal */}
      <ModalDialog
        title={'নবায়ন করুন'}
        content={
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}><div className="border-top mb-3"></div></Grid>

              <Grid item xs={6}>
                <TextField label="মালিকের নাম" value={editableRenewLicense.trade_license?.owners_name} size="small" fullWidth disabled />
              </Grid>
              <Grid item xs={6}>
                <TextField label="প্রতিষ্ঠানের নাম" value={editableRenewLicense.trade_license?.business_name} size="small" fullWidth disabled />
              </Grid>


              {/* economic year */}
              <Grid item xs={6}>
                <TextField label="অর্থ বছর" select value={editableRenewLicense.economic_year_id} size="small" fullWidth
                  onChange={(e) => setEditableRenewLicense({ ...editableRenewLicense, economic_year_id: e.target.value })}>
                  {economicYears.map(year => (
                    <MenuItem value={year.id}>{year.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* license type */}
              <Grid item xs={6}>
                <TextField label="লাইসেন্স টাইপ" select value={editableRenewLicense.license_fee_id} size="small" fullWidth
                  onChange={(e) => {
                    let licenseFee = licenseFees.find(fee => fee.id === parseInt(e.target.value));
                    setEditableRenewLicense({
                      ...editableRenewLicense,
                      license_fee_id: e.target.value,
                      license_fee: licenseFee.fee,
                      license_vat: (licenseFee.fee * licenseFee.vat_p / 100).toFixed(0),
                    });
                    setSelectedLicenseFee(licenseFee);
                  }}>
                  <MenuItem value={""}>নির্বাচন করুন</MenuItem>
                  {licenseFees.map((fee, index) =>
                    <MenuItem key={index} value={fee.id}>{fee.license_type}</MenuItem>
                  )}
                </TextField>
              </Grid>

              {/* license fee */}
              <Grid item xs={6}>
                <TextField label="লাইসেন্স ফি" value={editableRenewLicense.license_fee} size="small" fullWidth disabled />
              </Grid>

              {/* vat */}
              <Grid item xs={6}>
                <TextField label="ভ্যাট" value={editableRenewLicense.license_vat} size="small" fullWidth disabled />
              </Grid>

              {/* permitted capital */}
              <Grid item xs={6}>
                <TextField label="অনুমোদিত মূলধন" value={editableRenewLicense.permitted_capital} size="small" fullWidth
                  onChange={(e) => {
                    setEditableRenewLicense({
                      ...editableRenewLicense,
                      permitted_capital: e.target.value,
                      capital_tax: (e.target.value * selectedLicenseFee.capital_tax_p / 100).toFixed(0),
                    });
                  }} disabled={selectedLicenseFee.id === undefined} />
              </Grid>

              {/* capital tax */}
              <Grid item xs={6}>
                <TextField label="মূলধন কর" value={editableRenewLicense.capital_tax} size="small" fullWidth disabled />
              </Grid>

              {/* renew date */}
              <Grid item xs={6}>
                <TextField label="নবায়ন তারিখ" type="date" size="small" fullWidth value={editableRenewLicense.renew_date}
                  onChange={(e) => setEditableRenewLicense({ ...editableRenewLicense, renew_date: e.target.value })} />
              </Grid>

            </Grid>
          </div>
        }
        onOpen={openEditRenewModal}
        onClose={() => { setOpenEditRenewModal(false); setEditableRenewLicense({}) }}
        confirmText={'জমা দিন'}
        onConfirm={updateRenewLicense}
        loading={loading}
      />


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );


  // convert date function
  function convertDate(inputDate) {
    const date = new Date(inputDate);

    // Get the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');

    // Assemble the formatted date
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
};

export default TradeLicenseRenewList;
