import React from 'react';

const OnlineApplicationHeirAdd = () => {
  return (
    <>
        <div>
          <div></div>
        </div>
    </>
  )
};

export default OnlineApplicationHeirAdd;