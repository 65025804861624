import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const HeirApplicationAdd = () => {

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')
  const navigate = useNavigate();

  const [words, setWords] = useState([])

  let heirOptions = {
    applicants_name: "", fathers_name: "", mothers_name: "", spouse_name: "", nid: "", phone: "", nationality: "বাংলাদেশী", word_id: "", village_id: "",
    post_office: "", upazila: "", district: "", name_of_dead: "", fathers_name_of_dead: "", mothers_name_of_dead: "", spouse_name_of_dead: "",
    nid_of_dead: "", nationality_of_dead: "বাংলাদেশী", word_id_of_dead: "", village_id_of_dead: "", post_office_of_dead: "",
    listOfHeritors: [{ heir_name: "", relationship: "", comment: "", }, { heir_name: "", relationship: "", comment: "", }],
  };
  const [inputHeirApplication, setInputHeirApplication] = useState(heirOptions);

  const handleAdd = () => {
    setInputHeirApplication({
      ...inputHeirApplication,
      listOfHeritors: [
        ...inputHeirApplication.listOfHeritors,
        { heir_name: "", relationship: "", comment: "" },
      ],
    });
  };
  const handleDelete = (e, index) => {
    const list = [...inputHeirApplication.listOfHeritors];
    list.splice(index, 1);
    setInputHeirApplication({ ...inputHeirApplication, listOfHeritors: list });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputHeirApplication({ ...inputHeirApplication, [name]: value });
  };


  // get all words
  const getWords = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/all-words`).then(res => {
      if (res.status === 200) {
        setWords(res.data.words)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])


  // submit heir application
  const submitHeirApplication = () => {
    setLoading(true)
    axios.post(`/api/${role}/heir-application`, inputHeirApplication).then(res => {
      if (res.status === 200) {
        setSuccess(res.data.message)
        setInputHeirApplication(heirOptions)
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getWords();
    }
  }, [getWords, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">ওয়ারিশান সনদের আবেদন</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    ওয়ারিশান সনদের আবেদন
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue"
                  onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/heir-application-list`); }}>
                  সবগুলো দেখুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">

              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center">আবেদনকারীর তথ্য</h4>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">আবেদনকারীর নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="আবেদনকারীর নাম" name="applicants_name"
                    value={inputHeirApplication.applicants_name} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পিতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="পিতার নাম" name="fathers_name"
                    value={inputHeirApplication.fathers_name} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">মাতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="মাতার নাম" name="mothers_name"
                    value={inputHeirApplication.mothers_name} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">স্বামী/স্ত্রী-র নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="স্বামী/স্ত্রী-র নাম" name="spouse_name"
                    value={inputHeirApplication.spouse_name} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">ভোটার আইডি নং</label>
                  <input type="text" className="form-control" id="text" placeholder="ভোটার আইডি নং" name="nid"
                    value={inputHeirApplication.nid} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">মোবাইল নং</label>
                  <input type="text" className="form-control" id="text" placeholder="মোবাইল নং" name="phone"
                    value={inputHeirApplication.phone} onChange={handleChange} />
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">জাতীয়তা</label>
                  <select className="form-control" name="nationality" value={inputHeirApplication.nationality} onChange={handleChange}>
                    <option value={'বাংলাদেশী'}>{'বাংলাদেশী'}</option>
                    <option value={'অন্যান্য'}>{'অন্যান্য'}</option>
                  </select>
                </div>
              </div>

              {/* select word */}
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">ওয়ার্ড নং</label>
                  <select className="form-control" name="word_id" value={inputHeirApplication.word_id}
                    onChange={(e) => setInputHeirApplication({ ...inputHeirApplication, word_id: e.target.value, village_id: '' })}>
                    <option value=''>নির্বাচন করুন</option>
                    {words.map((word, index) =>
                      <option key={index} value={word.id}>{word.word}</option>
                    )}
                  </select>
                </div>
              </div>
              {/* select village of word */}
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">গ্রাম</label>
                  <select className="form-control" name="village_id" value={inputHeirApplication.village_id} onChange={handleChange}
                    disabled={inputHeirApplication.word_id === '' ? true : false}>
                    <option value=''>নির্বাচন করুন</option>
                    {words.find(word => word.id === parseInt(inputHeirApplication.word_id)) &&
                      words.find(word => word.id === parseInt(inputHeirApplication.word_id)).villages.map((village, index) =>
                        <option key={index} value={village.id}>{village.name}</option>
                      )}
                  </select>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">ডাকঘর</label>
                  <input type="text" className="form-control" id="text" placeholder="ডাকঘর" name="post_office"
                    value={inputHeirApplication.post_office} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">উপজেলা</label>
                  <input type="text" className="form-control" id="text" placeholder="উপজেলা" name="upazila"
                    value={inputHeirApplication.upazila} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">জেলা</label>
                  <input type="text" className="form-control" id="text" placeholder="জেলা" name="district"
                    value={inputHeirApplication.district} onChange={handleChange} />
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center">মৃত ব্যক্তির তথ্য</h4>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">মৃত ব্যক্তির নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="মৃত ব্যক্তির নাম" name="name_of_dead"
                    value={inputHeirApplication.name_of_dead} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পিতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="পিতার নাম" name="fathers_name_of_dead"
                    value={inputHeirApplication.fathers_name_of_dead} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">মাতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="মাতার নাম" name="mothers_name_of_dead"
                    value={inputHeirApplication.mothers_name_of_dead} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">স্বামী/স্ত্রী-র নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="স্বামী/স্ত্রী-র নাম" name="spouse_name_of_dead"
                    value={inputHeirApplication.spouse_name_of_dead} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">ভোটার আইডি নং</label>
                  <input type="text" className="form-control" id="text" placeholder="ভোটার আইডি নং" name="nid_of_dead"
                    value={inputHeirApplication.nid_of_dead} onChange={handleChange} />
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">জাতীয়তা</label>
                  <select className="form-control" name="nationality_of_dead" value={inputHeirApplication.nationality_of_dead} onChange={handleChange}>
                    <option value={'বাংলাদেশী'}>{'বাংলাদেশী'}</option>
                    <option value={'অন্যান্য'}>{'অন্যান্য'}</option>
                  </select>
                </div>
              </div>

              {/* select word */}
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">ওয়ার্ড নং</label>
                  <select className="form-control" name="word_id_of_dead" value={inputHeirApplication.word_id_of_dead}
                    onChange={(e) => setInputHeirApplication({ ...inputHeirApplication, word_id_of_dead: e.target.value, village_id: '' })}>
                    <option value=''>নির্বাচন করুন</option>
                    {words.map((word, index) =>
                      <option key={index} value={word.id}>{word.word}</option>
                    )}
                  </select>
                </div>
              </div>
              {/* select village of word */}
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">গ্রাম</label>
                  <select className="form-control" name="village_id_of_dead" value={inputHeirApplication.village_id_of_dead} onChange={handleChange}
                    disabled={inputHeirApplication.word_id_of_dead === '' ? true : false}>
                    <option value=''>নির্বাচন করুন</option>
                    {words.find(word => word.id === parseInt(inputHeirApplication.word_id_of_dead)) &&
                      words.find(word => word.id === parseInt(inputHeirApplication.word_id_of_dead)).villages.map((village, index) =>
                        <option key={index} value={village.id}>{village.name}</option>
                      )}
                  </select>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">ডাকঘর</label>
                  <input type="text" className="form-control" id="text" placeholder="ডাকঘর" name="post_office_of_dead"
                    value={inputHeirApplication.post_office_of_dead} onChange={handleChange} />
                </div>
              </div>


              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center">ওয়ারিশানগনের তথ্য দিন</h4>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
                <table className="table table-striped" width="100%;">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">#</th>
                      <th scope="col" className="text-center">নাম</th>
                      <th scope="col" className="text-center">সম্পর্ক</th>
                      <th scope="col" className="text-center">মন্তব্য</th>
                      <th scope="col" className="text-center actionTable">একশন</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputHeirApplication.listOfHeritors.map((inheritor, index) => (
                      <HeirInformation
                        key={index}
                        inheritor={inheritor}
                        index={index}
                        inputHeirApplication={inputHeirApplication}
                        setInputHeirApplication={setInputHeirApplication}
                        handleAdd={handleAdd}
                        handleDelete={handleDelete}
                      />
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4"></div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <button type="button" className="btn btn-block btn-lightblue" onClick={submitHeirApplication}>
                  {loading ? <SpinnerPulse /> : 'জমা দিন'}
                </button>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4"></div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};



const HeirInformation = ({
  inheritor,
  index,
  setInputHeirApplication,
  inputHeirApplication,
  handleAdd,
  handleDelete,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputHeirApplication({
      ...inputHeirApplication,
      listOfHeritors: inputHeirApplication.listOfHeritors.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      ),
    });
  };

  return (
    <tr>
      <th scope="row" className="text-center">
        {index + 1}
      </th>
      <td className="text-center">
        <input type="text" className="form-control" id="text" placeholder="নাম" name="heir_name"
          value={inheritor.heir_name} onChange={handleChange} />
      </td>
      <td className="text-center">
        <input type="text" className="form-control" id="text" placeholder="সম্পর্ক" name="relationship"
          value={inheritor.relationship} onChange={handleChange} />
      </td>
      <td className="text-center">
        <input type="text" className="form-control" id="text" placeholder="মন্তব্য" name="comment"
          value={inheritor.comment} onChange={handleChange} />
      </td>
      <td className="text-center">
        {index === 0 ? null : (
          <button onClick={(e) => handleDelete(e, index)} type="button" className="btn-icon btn-outline-lightblue font-weight-bold mr-2">-</button>
        )}
        <button onClick={handleAdd} type="button" className="btn-icon btn-outline-lightblue font-weight-bold mr-2">+</button>
      </td>
    </tr>
  );
};

export default HeirApplicationAdd;
