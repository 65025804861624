import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import $ from "jquery";
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx';
import { useEffect, useState } from 'react';
import axios from 'axios';
import FileInput from '../../../../utilities/FileInput';
import CustomSnackbar from '../../../../utilities/SnackBar';

export default function ImportTaxMember() {
  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#taxMemberImportTable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [role, setRole] = useState('')
  const navigate = useNavigate();


  const [uploadedFile, setUploadedFile] = useState('');
  const [inputMemberData, setInputMemberData] = useState([]);


  // console.log(inputMemberData)


  // handle input file
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonResult = XLSX.utils.sheet_to_json(sheet, { header: 1 });


        setInputMemberData([])

        const inputFields = [
          'holding_no', 'name_of_khana_prodhan', 'fathers_name', 'mothers_name', 'spouse_name', 'gender', 'nid', 'date_of_birth', 'phone', 'email', 'occupation', 'religion',
          'number_of_male', 'number_of_female', 'male_citizen', 'female_citizen', 'family_status', 'word', 'village', 'road_no', 'block', 'comment',
        ];

        const inputMemberData = [];
        for (let i = 1; i < jsonResult.length; i++) {
          const rowData = jsonResult[i];
          const obj = {};
          for (let j = 0; j < inputFields.length; j++) {
            obj[inputFields[j]] = rowData[j];
          }

          // if all data of obj is empty then ignore it
          if (Object.values(obj).some(value => value !== undefined)) {
            inputMemberData.push(obj);
          }
        }

        setInputMemberData(inputMemberData)
      };

      reader.readAsBinaryString(file);
    }
  };



  // submit tax marks
  const submitTaxMember = () => {
    if (window.confirm('Are you sure that you are adding this members?')) {
      setSubmitLoading(true)
      axios.post(`/api/${role}/import-tax-member`, inputMemberData).then(res => {
        if (res.status === 200) {
          setSuccess(res.data.message);
          setTimeout(() => { setSuccess('') }, 5000);
          navigate(`${role === 'user' ? '' : '/chairman'}/tax-member-list`);
        } else {
          setError(res.data.message);
          setTimeout(() => { setError('') }, 5000);
        }
        setSubmitLoading(false);
      }).catch(err => {
        setError(err.response.data.message);
        setTimeout(() => { setError('') }, 5000);
        setSubmitLoading(false);
      });
    }
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));
  }, [role]);


  return (
    <Box className='content-wrapper'>
      <Box className='rounded main-bg'>

        {/* Heading section */}
        <Box className='card-header d-flex justify-content-between align-items-end' sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Box className='d-flex align-items-center'>
            <button onClick={() => window.history.back()} className='btn btn-light btn-floating mr-3'>
              <i className='fas fa-arrow-left'></i></button>
            <h5 className='card-title'>করদাতা সদস্য ইমপোর্ট করুন</h5>
          </Box>
        </Box>

        {/* Body section */}
        <Box className='card-body'>
          <Box className="d-flex justify-content-between mb-4">
            {/* download .xlsx file button */}
            <a href='/files/tax_member_import_demo.xlsx' target='_blank' rel="noopener noreferrer" download="tax_member_import_demo.xlsx"
              className='btn btn-outline-dark border-grey mr-2' style={{ textTransform: 'none', fontSize: '14px' }}>
              <i className="fas fa-download mr-2"></i> ডেমো .xlsx ফাইল ডাউনলোড করুন</a>

            {/* file input */}
            <Box className="col-6 col-lg-4">
              <FileInput label={'.xlsx ফাইল অপলোড করুন'} onUpload={(e) => { handleFileChange(e); setUploadedFile(e.target.files[0]) }}
                state={uploadedFile} onDelete={() => { setUploadedFile(''); setInputMemberData([]) }} formats={'.xlsx, .xls'} />
            </Box>
          </Box>

          {/* input data table */}
          {inputMemberData.length > 0 ?
            <Box>
              <Box className="table-responsive">

                <Table id="taxMemberImportTable" className='table-bordered table-sm border-grey'>
                  <TableHead className='sticky-header'>
                    <TableRow>
                      {/* member columns */}
                      {inputMemberData[0] && Object.keys(inputMemberData[0]).map((key, index) => (
                        <TableCell key={index} className="text-center text-capitalize">{key.split('_').join(' ')}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {inputMemberData.map((row, index) => (
                      <TableRow key={index}>
                        {Object.values(row).map((value, index) => (
                          <TableCell key={index} className="text-center">{value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>



              </Box>


              {/* save button */}
              <Box className="text-right mt-3">
                <button className="btn btn-primary" onClick={submitTaxMember}
                  disabled={inputMemberData.length === 0}>
                  {submitLoading ? <span className='spinner-border spinner-border-sm'></span> : 'পরিবর্তন সংরক্ষণ করুন'}</button>
              </Box>
            </Box>
            : <Box className="my-5 text-center text-muted">ডেমো ফাইল ডাউনলোড করুন ➟ ফাইলটি এডিট করুন ➟ অপলোড করুন এবং পুনরায় চেক করুন ➟ পরিবর্তন সংরক্ষণ করুন</Box>
          }
        </Box>
      </Box>


      {/* Utilities */}
      <CustomSnackbar message={error} status={'error'} />
      <CustomSnackbar message={success} status={'success'} />
    </Box>
  )
}
