import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import CustomSnackbar from "../../../utilities/SnackBar";

const CertificateAdd = () => {

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')
  const navigate = useNavigate();

  const [words, setWords] = useState([]);
  const [certificateTypes, setCertificateTypes] = useState([]);

  let options = {
    category_id: "", applicants_name: "", fathers_name: "", mothers_name: "", spouse_name: "", nid: "", phone: "",
    word_id: "", village_id: "", post_office: "", comment: "",
  }
  const [certificateInfo, setCertificateInfo] = useState(options);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCertificateInfo({ ...certificateInfo, [name]: value });
  };


  // get all words
  const getWords = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/all-words`).then(res => {
      if (res.status === 200) {
        setWords(res.data.words)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // get all certificate category
  const getAllCertificateCategory = useCallback(() => {
    setLoading(true)
    axios.get(`/api/${role}/certificate-category`).then(res => {
      if (res.status === 200) {
        setCertificateTypes(res.data.categories)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])


  // submit certificate application
  const submitApplication = () => {
    setLoading(true)
    axios.post(`/api/${role}/certificate`, certificateInfo).then(res => {
      if (res.status === 200) {
        setSuccess(res.data.message)
        setCertificateInfo(options)
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getAllCertificateCategory();
      getWords();
    }
  }, [getAllCertificateCategory, getWords, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">প্রত্যয়নপত্রের জন্য আবেদন </h1>
              </div>
              {/* <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    প্রত্যয়নপত্রের জন্য আবেদন{" "}
                  </li>
                </ol>
              </div> */}
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue"
                  onClick={() => navigate(`${role === 'user' ? '' : '/chairman'}/certificate-types`)}>
                  সবগুলো দেখুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">আবেদনকারীর নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="আবেদনকারীর নাম" name="applicants_name"
                    value={certificateInfo.applicants_name} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">প্রত্যয়নপত্রের ধরন</label>
                  <select className="form-control" name="category_id" value={certificateInfo.category_id} onChange={handleChange}>
                    <option value="">নির্বাচন করুন</option>
                    {certificateTypes.map((category, index) =>
                      <option key={index} value={category.id}>{category.title}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পিতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="পিতার নাম" name="fathers_name"
                    value={certificateInfo.fathers_name} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">মাতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="মাতার নাম" name="mothers_name"
                    value={certificateInfo.mothers_name} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">স্বামী/স্ত্রী-র নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="স্বামী/স্ত্রী-র নাম" name="spouse_name"
                    value={certificateInfo.spouse_name} onChange={handleChange} />
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">ভোটার আইডি নং</label>
                  <input type="number" className="form-control" id="text" placeholder="ভোটার আইডি নং" name="nid"
                    value={certificateInfo.nid} onChange={handleChange} />
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">মোবাইল নম্বর</label>
                  <input type="text" className="form-control" id="text" placeholder="মোবাইল নম্বর" name="phone"
                    value={certificateInfo.phone} onChange={handleChange} />
                </div>
              </div>


              {/* select word */}
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">ওয়ার্ড নং</label>
                  <select className="form-control" name="word_id" value={certificateInfo.word_id}
                    onChange={(e) => setCertificateInfo({ ...certificateInfo, word_id: e.target.value, village_id: '' })}>
                    <option value=''>নির্বাচন করুন</option>
                    {words.map((word, index) =>
                      <option key={index} value={word.id}>{word.word}</option>
                    )}
                  </select>
                </div>
              </div>
              {/* select village of word */}
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">গ্রাম</label>
                  <select className="form-control" name="village_id" value={certificateInfo.village_id} onChange={handleChange}
                    disabled={certificateInfo.word_id === '' ? true : false}>
                    <option value=''>নির্বাচন করুন</option>
                    {words.find(word => word.id === parseInt(certificateInfo.word_id)) &&
                      words.find(word => word.id === parseInt(certificateInfo.word_id)).villages.map((village, index) =>
                        <option key={index} value={village.id}>{village.name}</option>
                      )}
                  </select>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">ডাকঘর</label>
                  <input type="text" className="form-control" id="text" placeholder="ডাকঘর" name="post_office"
                    value={certificateInfo.post_office} onChange={handleChange} />
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">মন্তব্য</label>
                  <input type="text" className="form-control" id="text" placeholder="মন্তব্য" name="comment"
                    value={certificateInfo.comment} onChange={handleChange} />
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 my-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={submitApplication}>
                  {loading ? <SpinnerPulse /> : 'জমা দিন'}
                </button>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4"></div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default CertificateAdd;
