import React, { useCallback, useEffect, useState } from "react";

import "bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import { useLocalStorage } from "usehooks-ts";

const TaxMemberRegisteredListPrint = () => {

  const [userDetails] = useLocalStorage('user', {})
  const location = useLocation();
  const word = location.state.word;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [taxMembers, setTaxMembers] = useState([]);

  // console.log(taxMembers)

  // get all tax members
  const getAllTaxMembers = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/tax-member/${word.id}`).then(res => {
      if (res.status === 200) {
        setTaxMembers(res.data.tax_members)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role, word.id])


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getAllTaxMembers();
    }
  }, [getAllTaxMembers, role]);


  const Print = () => {
    document.getElementById("printBtn").style.cssText = " display: none;";
    document.getElementById("closetBtn").style.cssText = " display: none;";
    window.print();
    document.getElementById("printBtn").style.cssText = " display: block;";
    document.getElementById("closetBtn").style.cssText = " display: block;";
  };

  const Close = () => {
    navigate(-1);
    window.close();
  };

  return (
    <>
      <div className="row main-bg p-2 m-0">
        <div className="col-lg-12 col-md-12 col-sm-12 p-2">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2 p-1">
              <button type="button" className="btn btn-block btn-danger" onClick={Close} id="closetBtn">
                ফিরে যান
              </button>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
            <div className="col-lg-4 col-md-4 col-sm-4 p-1"></div>
            <div className="col-lg-2 col-md-2 col-sm-2 p-1">
              <button type="button" className="btn btn-block btn-lightblue" onClick={Print} id="printBtn">
                প্রিন্ট
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 p-2 text-center">
          <h2>করদাতা সদস্যদের রেজিস্টার তালিকা</h2>
          <h4>{`${userDetails.union.bn_name}, ${userDetails.union.upazila}`}</h4>
          <h4>ওয়ার্ডঃ {word.word}</h4>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 table-responsive mt-5">
          {taxMembers.length > 0 ?
            <table className="table table-striped" width="100%;">
              <thead>
                <tr>
                  <th scope="col" className="text-center">ক্রমিক</th>
                  <th scope="col">হোল্ডিং নং</th>
                  <th scope="col">নাম</th>
                  <th scope="col">বাবার নাম</th>
                  <th scope="col">আইডি নং</th>
                  <th scope="col">গ্রাম</th>
                  <th scope="col">পেশা</th>
                  <th scope="col" className="text-right">কর</th>
                </tr>
              </thead>
              <tbody>
                {taxMembers.map((taxMember, index) => {

                  // total tax count
                  let totalHouseTax = taxMember.house_type_of_tax_member.map((houseType) => {
                    return parseInt(houseType.situation === "করযোগ্য" ? houseType.house_tax.tax : 0)
                  }).reduce((acc, curr) => acc + curr, 0);

                  let totalBusinessTax = taxMember.business_of_tax_member.map((businessType) => {
                    return parseInt(businessType.business_tax.tax)
                  }).reduce((acc, curr) => acc + curr, 0);

                  return (
                    <tr key={taxMember.id}>
                      <td className="text-center">{index + 1}</td>
                      <td>{taxMember.holding_no}</td>
                      <td>{taxMember.name_of_khana_prodhan}</td>
                      <td>{taxMember.fathers_name}</td>
                      <td>{taxMember.nid}</td>
                      <td>{taxMember.village?.name}</td>
                      <td>{taxMember.occupation}</td>
                      <td className="text-right">৳ {totalHouseTax + totalBusinessTax}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            : loading ? <SpinnerPulse color={'dark'} />
              : <div className="text-center text-danger">কোনো সদস্য পাওয়া যায় নি</div>}
        </div>
      </div>


      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default TaxMemberRegisteredListPrint;
