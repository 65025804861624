import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const TaxCollectionAdd = () => {

  const taxCollection = useLocation().state.taxCollection;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const [economicYears, setEconomicYears] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [taxCollectionInfo, setTaxCollectionInfo] = useState(taxCollection);

  let totalTax = parseInt(taxCollection.paid_amount) + parseInt(taxCollection.due_amount);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setTaxCollectionInfo({ ...taxCollectionInfo, [name]: value });
  };

  // console.log(taxCollectionInfo)

  // get all years
  const getEconomicYears = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/economic-year`).then(res => {
      if (res.status === 200) {
        setEconomicYears(res.data.economic_years)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // get all payment methods
  const getPaymentMethods = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/payment-method`).then((res) => {
      if (res.status === 200) {
        setPaymentMethods(res.data.payment_methods);
      }
    }).catch((err) => {
      setError(err.message);
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false);
    });
  }, [role])

  // update tax collection
  const updateTaxCollection = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.put(`/api/${role}/tax-collection/${taxCollectionInfo.id}`, taxCollectionInfo).then(res => {
      if (res.status === 200) {
        setSuccess(res.data.message)
        setTimeout(() => { setSuccess('') }, 5000)
        navigate(-1);
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getEconomicYears();
      getPaymentMethods();
    }
  }, [getEconomicYears, getPaymentMethods, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">কর আদায় এডিট</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">কর আদায় এডিট</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/tax-collection-list`); }}>
                  সবগুলো দেখুন
                </button>
              </div>
            </div>


            {/* Search by NID no */}
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label htmlFor="text">ভোটার আইডি নং</label>
                  <input type="text" className="form-control" id="text" placeholder="ভোটার আইডি নং"
                    value={taxCollectionInfo.tax_member?.nid} readOnly />
                </div>
              </div>
            </div>


            {/* Tax member info */}
            <div className="row mt-5 p-3 rounded main-bg">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">হোল্ডিং নং</label>
                  <input type="text" className="form-control" id="text" placeholder="হোল্ডিং নং"
                    value={taxCollectionInfo.tax_member?.holding_no} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">খানা প্রধানের নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="খানা প্রধানের নাম"
                    value={taxCollectionInfo.tax_member?.name_of_khana_prodhan} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পিতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="পিতার নাম"
                    value={taxCollectionInfo.tax_member?.fathers_name} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">মাতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="মাতার নাম"
                    value={taxCollectionInfo.tax_member?.mothers_name} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">গ্রাম/মহল্লা</label>
                  <input type="text" className="form-control" id="text" placeholder="গ্রাম/মহল্লা"
                    value={taxCollectionInfo.tax_member?.village?.name} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ওয়ার্ড নং</label>
                  <input type="text" className="form-control" id="text" placeholder="ওয়ার্ড নং"
                    value={taxCollectionInfo.tax_member?.village?.word?.word} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">মোবাইল নং</label>
                  <input type="text" className="form-control" id="text" placeholder="মোবাইল নং"
                    value={taxCollectionInfo.tax_member?.phone} readOnly />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">কর টাকা</label>
                  <input type="text" className="form-control" id="text" placeholder="কর টাকা"
                    value={totalTax} readOnly />
                </div>
              </div>


              {/* fillable forms section */}
              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center font-weight-bolder">
                  সর্বমোট কর ৳{totalTax} <small>(বকেয়াসহ)</small>
                </h4>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">অর্থ বছর</label>
                  <select className="form-control" name="economic_year_id" value={taxCollectionInfo.economic_year_id} disabled>
                    <option value="">অর্থ বছর নির্বাচন করুন</option>
                    {economicYears.map((year, index) => (
                      <option key={index} value={year.id}>{year.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পরিশোধিত টাকার পরিমাণ</label>
                  <input type="number" className="form-control" id="text" placeholder="পরিশোধিত টাকার পরিমাণ"
                    name="paid_amount" value={taxCollectionInfo.paid_amount} onChange={(e) => {
                      if (e.target.value > totalTax) {
                        alert('পরিশোধিত টাকার পরিমাণ মোট কর-এর চেয়ে বেশি হতে পারে না');
                        return;
                      }
                      setTaxCollectionInfo({ ...taxCollectionInfo, paid_amount: e.target.value, due_amount: totalTax - e.target.value | 0 });
                    }} />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">বকেয়া টাকার পরিমাণ</label>
                  <input type="text" className="form-control" id="text" placeholder="বকেয়া টাকার পরিমাণ"
                    name="due_amount" value={taxCollectionInfo.due_amount} readOnly />
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">তারিখ</label>
                  <input type="date" className="form-control" id="text" placeholder="তারিখ"
                    name="payment_date" value={taxCollectionInfo.payment_date} onChange={handleChange} />
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <label htmlFor="text">পরিশোধের মাধ্যম</label>
                  <select className="form-control" name="payment_method_id" value={taxCollectionInfo.payment_method_id} onChange={handleChange}>
                    <option value="">নির্বাচন করুন</option>
                    {paymentMethods.map((method, index) => (
                      <option key={index} value={method.id}>{method.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label htmlFor="text">মন্তব্য (যদি থাকে)</label>
                  <textarea type="text" className="form-control" id="text" placeholder="মন্তব্য (যদি থাকে)"
                    name="comment" value={taxCollectionInfo.comment} onChange={handleChange} />
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4"></div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <button type="button" className="btn btn-block btn-lightblue"
                  onClick={updateTaxCollection} disabled={loading | taxCollectionInfo.id === undefined}>
                  {loading ? <SpinnerPulse /> : 'জমা দিন'}
                </button>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4"></div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>



      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default TaxCollectionAdd;
