import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
import EyeIcon from "../../../assets/icons/EyeIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import axios from "axios";
import Swal from 'sweetalert2'
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import CustomSnackbar from "../../../utilities/SnackBar";

const TradeLicenseNewList = () => {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#tradeLicenseListTable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const [tradeLicenseList, setTradeLicenseList] = useState([]);


  // get all trade license
  const getAllTradeLicense = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/trade-license`).then(res => {
      if (res.status === 200) {
        setTradeLicenseList(res.data.trade_licenses)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // permit license
  const permitLicense = (license) => {
    Swal.fire({
      title: 'অনুমোদন দিতে চান?',
      text: `প্রতিষ্ঠানের নাম: ${license.business_name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976D2',
      cancelButtonColor: '#707070',
      confirmButtonText: 'অনুমোদন দিন',
      cancelButtonText: 'বাতিল করুন'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true)
        axios.post(`/api/${role}/trade-license-permit/${license.id}`).then(res => {
          if (res.status === 200) {
            getAllTradeLicense();
            setSuccess(res.data.message);
            setTimeout(() => { setSuccess('') }, 5000);
          } else {
            setError(res.data.message);
            setTimeout(() => { setError('') }, 5000);
          }
        }).catch(err => {
          setError(err.response.data.message)
          setTimeout(() => { setError('') }, 5000);
        }).finally(() => {
          setLoading(false);
        });
      }
    })
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getAllTradeLicense();
    }
  }, [getAllTradeLicense, role]);

  // For Initialize DataTable End ----------------
  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  নতুন ট্রেড লাইসেন্সধারী প্রতিষ্ঠানের তালিকা
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    নতুন ট্রেড লাইসেন্সধারী প্রতিষ্ঠানের তালিকা
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/trade-license-add`); }}>
                  + যোগ করুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
                {tradeLicenseList.length > 0 ?
                  <table id="tradeLicenseListTable" className="table table-striped" width="100%;">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ক্রমিক</th>
                        <th scope="col">প্রতিষ্ঠানের নাম</th>
                        <th scope="col">মালিকের নাম</th>
                        <th scope="col">লাইসেন্স নম্বর</th>
                        <th scope="col">আইডি নং</th>
                        <th scope="col" className="text-center">আবেদন</th>
                        <th scope="col" className="text-center actionTable">একশন</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tradeLicenseList.map((tradeLicense, index) => (
                        <tr key={tradeLicense.id}>
                          <td className="text-center">{index + 1}</td>
                          <td>{tradeLicense.business_name}</td>
                          <td>{tradeLicense.owners_name}</td>
                          <td>TRAD/{tradeLicense.license_no}</td>
                          <td>{tradeLicense.nid}</td>
                          <td className="text-center">
                            {parseInt(tradeLicense.status) === 1
                              ? <span className="badge badge-success px-2">অনুমোদিত</span>
                              : <span className="badge badge-warning px-2" onClick={() => permitLicense(tradeLicense)}
                                style={{ cursor: 'pointer' }}>অপেক্ষমান</span>
                            }
                          </td>
                          <td className="text-center">
                            <div className="">
                              <button type="button" className="btn-icon btn-outline-lightblue dropdown-toggle font-roboto" data-toggle="dropdown" aria-expanded="false">
                                Action
                              </button>
                              <ul className="dropdown-menu font-roboto">
                                <li>
                                  <Link to={`${role === 'user' ? '' : '/chairman'}/trade-license-list-new/bn/print`} state={{ id: tradeLicense.id }}
                                    className="dropdown-item"><EyeIcon /> View</Link>
                                </li>
                                <li>
                                  <Link to={`${role === 'user' ? '' : '/chairman'}/trade-license-list-new/edit`} state={{ id: tradeLicense.id }}
                                    className="dropdown-item"><EditIcon /> Edit</Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  : loading ? <SpinnerPulse color={'dark'} />
                    : 'কোনো তথ্য পাওয়া যায় নি'}
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>



      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default TradeLicenseNewList;
