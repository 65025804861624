import React, { useCallback, useEffect, useState } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import axios from "axios";
import CustomSnackbar from "../../../utilities/SnackBar";
import ModalDialog from "../../../utilities/ModalDialog";
import { TextField } from "@mui/material";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";

const CertificateCategory = () => {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#certificatesTable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [certificateTypes, setCertificateTypes] = useState([]);
  const [inputCertificateType, setInputCertificateType] = useState({ title: '', description: '' });
  const [selectedCategory, setSelectedCategory] = useState({});
  const [deleteCategoryInput, setDeleteCategoryInput] = useState('');

  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
  const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);


  // get all certificate category
  const getAllCertificateCategory = useCallback(() => {
    setLoading(true)
    axios.get(`/api/${role}/certificate-category`).then(res => {
      if (res.status === 200) {
        setCertificateTypes(res.data.categories)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // create category
  const createCaterory = () => {
    setLoading(true)
    axios.post(`/api/${role}/certificate-category`, inputCertificateType).then(res => {
      if (res.status === 200) {
        getAllCertificateCategory()
        setSuccess(res.data.message)
        setOpenAddCategoryModal(false)
        setInputCertificateType({ title: '', description: '' })
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // update category
  const updateCategory = () => {
    setLoading(true)
    axios.put(`/api/${role}/certificate-category/${selectedCategory.id}`, selectedCategory).then(res => {
      if (res.status === 200) {
        getAllCertificateCategory()
        setSuccess(res.data.message)
        setOpenEditCategoryModal(false)
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  // delete category
  const deleteCategory = () => {
    setLoading(true)
    axios.delete(`/api/${role}/certificate-category/${selectedCategory.id}`).then(res => {
      if (res.status === 200) {
        getAllCertificateCategory()
        setSuccess(res.data.message)
        setOpenDeleteCategoryModal(false)
        setDeleteCategoryInput('')
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getAllCertificateCategory();
    }
  }, [getAllCertificateCategory, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">প্রত্যয়নপত্রের ক্যাটাগরি</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">সদস্যদের তালিকা</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}

            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue" onClick={() => setOpenAddCategoryModal(true)}>
                  + যোগ করুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
                {certificateTypes.length > 0 ?
                  <table id="certificatesTable" className="table table-striped" width="100%;">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ক্রমিক</th>
                        <th scope="col">প্রত্যয়নের নাম</th>
                        <th scope="col">প্রত্যয়ন বার্তা</th>
                        <th scope="col" className="text-center actionTable">একশন</th>
                      </tr>
                    </thead>
                    <tbody>
                      {certificateTypes.map((certificate, index) => (
                        <tr key={certificate.id}>
                          <td className="text-center">{index + 1}</td>
                          <td>{certificate.title}</td>
                          <td>{certificate.description}</td>
                          <td className="text-center">
                            <div className="">
                              <button type="button" className="btn-icon btn-outline-lightblue dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                Action
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <div className="dropdown-item" onClick={() => { setSelectedCategory(certificate); setOpenEditCategoryModal(true) }}>
                                    <EditIcon /> Edit
                                  </div>
                                </li>
                                <li>
                                  <div className="dropdown-item" onClick={() => { setSelectedCategory(certificate); setOpenDeleteCategoryModal(true) }}>
                                    <DeleteIcon /> Delete
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  : loading ? <SpinnerPulse color={'dark'} />
                    : 'কোনো তথ্য পাওয়া যায় নি'}
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* add category modal */}
      <ModalDialog
        title={'ক্যাটাগরি যুক্ত করুন'}
        content={
          <div>
            <TextField label="প্রত্যয়নের নাম" fullWidth size="small" margin="normal" value={inputCertificateType.title}
              onChange={(e) => setInputCertificateType({ ...inputCertificateType, title: e.target.value })} />

            <TextField label="প্রত্যয়ন বার্তা (আবেদনকারীর পরিচয়ের পরে দেখানো হবে)" fullWidth size="small" margin="normal" value={inputCertificateType.description}
              onChange={(e) => setInputCertificateType({ ...inputCertificateType, description: e.target.value })} multiline minRows={5} />
          </div>
        }
        onOpen={openAddCategoryModal}
        onClose={() => setOpenAddCategoryModal(false)}
        confirmText={'জমা দিন'}
        onConfirm={createCaterory}
        loading={loading}
      />

      {/* edit category modal */}
      <ModalDialog
        title={'ক্যাটাগরি সম্পাদনা করুন'}
        content={
          <div>
            <TextField label="প্রত্যয়নের নাম" fullWidth size="small" margin="normal" value={selectedCategory.title}
              onChange={(e) => setSelectedCategory({ ...selectedCategory, title: e.target.value })} />

            <TextField label="প্রত্যয়ন বার্তা (আবেদনকারীর পরিচয়ের পরে দেখানো হবে)" fullWidth size="small" margin="normal" value={selectedCategory.description}
              onChange={(e) => setSelectedCategory({ ...selectedCategory, description: e.target.value })} multiline minRows={5} />
          </div>
        }
        onOpen={openEditCategoryModal}
        onClose={() => { setOpenEditCategoryModal(false); setSelectedCategory({}) }}
        confirmText={'জমা দিন'}
        onConfirm={updateCategory}
        loading={loading}
      />

      {/* detete category modal */}
      <ModalDialog
        title={`প্রত্যয়নের ক্যাটাগরি ডিলেট করতে চান?`}
        content={
          <div className='mt-2' style={{ maxWidth: '350px' }}>
            <p className='mb-4 text-danger'>{"এই প্রক্রিয়াটি একবার সম্পন্ন হয়ে গেলে আর পূর্বাবস্থায় ফিরাতে পারবেন না এবং এই ক্যাটাগরির সকল প্রত্যনের রেকর্ড ডিলেট হয়ে যাবে!"}</p>

            {/* type the username to delete account */}
            <p className='mb-2'>নিশ্চিত করতে নিচের ইনপুট ফিল্ডে <b>{selectedCategory.title}</b> টাইপ করুন।</p>
            <TextField placeholder={selectedCategory.title} type="text" value={deleteCategoryInput}
              onChange={(e) => setDeleteCategoryInput(e.target.value)} fullWidth size='small' />
          </div>
        }
        onOpen={openDeleteCategoryModal}
        onClose={() => { setOpenDeleteCategoryModal(false); setDeleteCategoryInput(''); setSelectedCategory({}) }}
        confirmText={'ডিলিট করুন'}
        actionColor={'error'}
        disabledAction={deleteCategoryInput !== selectedCategory.title}
        onConfirm={deleteCategory}
        loading={loading}
      />


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default CertificateCategory;
