import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import EyeIcon from "../assets/icons/EyeIcon";
import EditIcon from "../assets/icons/EditIcon";
import DeleteIcon from "../assets/icons/DeleteIcon";

// For DataTable Start ----------------
import $ from "jquery";
import axios from "axios";
import backendURL from "../consts";
// For DataTable End ----------------

const CertificateList = () => {
  // For Initialize DataTable Start ----------------
  const [certificates, setCertificates] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const response = await axios.get(backendURL + "/api/certificate/all");

        // console.log(response.data);
        setCertificates(response.data.certificates);
      } catch (error) {
        alert(error);
      }
    };
    fetchCertificates();
  }, []);

  // For Initialize DataTable End ----------------
  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">সদস্যদের তালিকা</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">সদস্যদের তালিকা</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button
                  type="button"
                  className="btn btn-block btn-lightblue"
                  onClick={() => {
                    navigate("/CertificateAdd");
                  }}
                >
                  + যোগ করুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
                <table
                  id="example"
                  className="table table-striped"
                  width="100%;"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        ক্রমিক
                      </th>
                      <th scope="col">হোল্ডিং নং</th>
                      <th scope="col">নাম</th>
                      <th scope="col">বাবার নাম</th>
                      <th scope="col">আইডি নং</th>
                      <th scope="col" className="text-center actionTable">
                        একশন
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {certificates.map((certificate, index) => (
                      <tr key={certificate._id}>
                        <td className="text-center">{index + 1}</td>
                        <td>{certificate.wardNo}</td>
                        <td>{certificate.applicantsName}</td>
                        <td>{certificate.fathersName}</td>
                        <td>{certificate.voterIdNo}</td>
                        <td className="text-center">
                          <div className="">
                            <button
                              type="button"
                              className="btn-icon btn-outline-lightblue dropdown-toggle"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Action
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <div
                                  className="dropdown-item"
                                  onClick={() => {
                                    navigate(`/CertificatePrint`, {
                                      state: { id: certificate._id },
                                    });
                                  }}
                                >
                                  <EyeIcon /> View
                                </div>
                              </li>
                              <li>
                                <div
                                  className="dropdown-item"
                                  onClick={() => {
                                    navigate(`/CertificateEdit`, {
                                      state: { id: certificate._id },
                                    });
                                  }}
                                >
                                  <EditIcon /> Edit
                                </div>
                              </li>
                              <li>
                                <div
                                  className="dropdown-item"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure to delete this record?"
                                      )
                                    ) {
                                      axios
                                        .delete(
                                          backendURL +
                                            "/api/certificate/delete/" +
                                            certificate._id
                                        )
                                        .then((res) => {
                                          console.log(res);
                                          console.log(res.data);
                                          window.location.reload();
                                        });
                                    }
                                  }}
                                >
                                  <DeleteIcon /> Delete
                                </div>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    {/* <tr>
                      <th scope="col" className="text-center">
                        ক্রমিক
                      </th>
                      <th scope="col">হোল্ডিং নং</th>
                      <th scope="col">নাম</th>
                      <th scope="col">বাবার নাম</th>
                      <th scope="col">আইডি নং</th>
                      <th scope="col" className="text-center actionTable">
                        একশন
                      </th>
                    </tr> */}
                  </tfoot>
                </table>
              </div>

              {/* <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">Text</label>
                  <input
                    type="text"
                    className="form-control"
                    id="text"
                    placeholder="Text"
                  />
                </div>
              </div> */}
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>
    </>
  );
};

export default CertificateList;
