import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
import EyeIcon from "../../../assets/icons/EyeIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import axios from "axios";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import Swal from "sweetalert2";

const HeirApplicationList = () => {

  // For Initialize DataTable Start ----------------
  $(document).ready(function () {
    $("#heirApplicationTable").DataTable();
  });
  // For Initialize DataTable End ----------------

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')
  const navigate = useNavigate();

  const [heirApplicationlist, setHeirApplicationList] = useState([]);

  // get all heir applications
  const getHeirApplications = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/heir-application`).then(res => {
      if (res.status === 200) {
        setHeirApplicationList(res.data.heir_applications)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // permit application
  const permitApplication = (application) => {
    Swal.fire({
      title: 'অনুমোদন দিতে চান?',
      text: `আবেদনকারীর নাম: ${application.applicants_name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1976D2',
      cancelButtonColor: '#707070',
      confirmButtonText: 'অনুমোদন দিন',
      cancelButtonText: 'বাতিল করুন'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true)
        axios.post(`/api/${role}/heir-application-permit/${application.id}`).then(res => {
          if (res.status === 200) {
            getHeirApplications();
            setSuccess(res.data.message);
            setTimeout(() => { setSuccess('') }, 5000);
          } else {
            setError(res.data.message);
            setTimeout(() => { setError('') }, 5000);
          }
        }).catch(err => {
          setError(err.response.data.message)
          setTimeout(() => { setError('') }, 5000);
        }).finally(() => {
          setLoading(false);
        });
      }
    })
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getHeirApplications();
    }
  }, [getHeirApplications, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">ওয়ারিশান আবেদনের তালিকা</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    ওয়ারিশান আবেদনের তালিকা
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue"
                  onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/heir-application-add`); }}>
                  + যোগ করুন
                </button>
              </div>
            </div>
            <div className="row mt-3 p-3 rounded main-bg">
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive p-4">
                {heirApplicationlist.length > 0 ?
                  <table id="heirApplicationTable" className="table table-striped" width="100%;">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ক্রমিক</th>
                        <th scope="col">আবেদনকারীর নাম</th>
                        <th scope="col">মৃত ব্যাক্তির নাম</th>
                        <th scope="col">মোবাইল</th>
                        <th scope="col" className="text-center">অবস্থান</th>
                        <th scope="col" className="text-center">ওয়ারিশান</th>
                        <th scope="col" className="text-center actionTable">একশন</th>
                      </tr>
                    </thead>
                    <tbody>
                      {heirApplicationlist.map((heir, index) => (
                        <tr key={heir.id}>
                          <td className="text-center">{index + 1}</td>
                          <td>{heir.applicants_name}</td>
                          <td>{heir.name_of_dead}</td>
                          <td>{heir.phone}</td>
                          <td className="text-center">
                            {parseInt(heir.status) === 1
                              ? <span className="badge badge-success px-2">অনুমোদিত</span>
                              : <span className="badge badge-warning px-2" onClick={() => permitApplication(heir)}
                                style={{ cursor: 'pointer' }}>অপেক্ষমান</span>
                            }
                          </td>
                          <td className="text-center">
                            <button onClick={() => navigate(`${role === 'user' ? '' : '/chairman'}/heir-application/print`, { state: { id: heir.id } })}
                              className="btn-icon btn-outline-lightblue">প্রিন্ট</button>
                          </td>
                          <td className="text-center">
                            <div className="">
                              <button type="button" className="btn-icon btn-outline-lightblue dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                Action
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <button onClick={() => navigate(`${role === 'user' ? '' : '/chairman'}/heir-application/view`, { state: { id: heir.id } })}
                                    className="dropdown-item"><EyeIcon /> View</button>
                                </li>
                                <li>
                                  <button onClick={() => navigate(`${role === 'user' ? '' : '/chairman'}/heir-application-list/edit`, { state: { id: heir.id } })}
                                    className="dropdown-item"><EditIcon /> Edit</button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  : loading ? <SpinnerPulse color={'dark'} />
                    : 'কোনো তথ্য পাওয়া যায় নি'}
              </div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default HeirApplicationList;
