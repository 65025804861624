import React from "react";
import ReactDOM from "react-dom";

// Bootstrap CSS
// import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// For DataTable Start ----------------
// import "bootstrap";
import "jquery";
import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-responsive";
// import $ from "jquery";
// For DataTable End ----------------

import "./assets/css/index.scss";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
