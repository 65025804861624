import React from "react";

const OnlineApplicationTradeLicenseAdd = () => {
    return (
        <>
        <div>
          <div></div>
        </div>
        </>
    )
};

export default OnlineApplicationTradeLicenseAdd;