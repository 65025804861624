import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import PlusIcon from "../../assets/icons/PlusIcon";

import "../../assets/css/Dashboard.scss";
import axios from "axios";
import CustomSnackbar from "../../utilities/SnackBar";
import { SpinnerPulse } from "../../utilities/SpinnerPulse";
import { useLocalStorage } from "usehooks-ts";

const UserDashboard = () => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [userDetails] = useLocalStorage('user', {})
  const [dashboardContent, setDashboardContent] = useState({})

  // get dashboard content
  const getDashboardContent = () => {
    setLoading(true);
    axios.get('/api/user/dashboard').then(res => {
      if (res.status === 200) {
        setDashboardContent(res.data.content)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }

  useEffect(() => {
    getDashboardContent();
  }, [])


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">ড্যাশবোর্ড</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">ড্যাশবোর্ড</li>
                </ol>
              </div>
            </div>
          </div>
        </div>


        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row mb-2">
              <div className="col-lg-3 col-6 pb-3">
                <NavLink to="/add-tax-member">
                  <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 h-100 circle-link-lightblue">
                    <div className="circle-div">
                      <div className="main-bg-lightblue text-center circle-icon">
                        <PlusIcon />
                      </div>
                    </div>
                    <div className="circle-div">
                      <div className="main-color-lightblue pt-3">
                        নতুন সদস্য যোগ করুন
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>

              {userDetails.role && userDetails.role === 'UDDOKTA' &&
                <>
                  <div className="col-lg-3 col-6 pb-3">
                    <NavLink to="/add-tax-collection">
                      <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 h-100 circle-link-lightorange">
                        <div className="circle-div">
                          <div className="main-bg-lightorange text-center circle-icon">
                            <PlusIcon />
                          </div>
                        </div>
                        <div className="circle-div">
                          <div className="main-color-lightorange pt-3">
                            কর আদায় ফরম
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>

                  <div className="col-lg-3 col-6 pb-3">
                    <NavLink to="/trade-license-add">
                      <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 h-100 circle-link-darkpurple">
                        <div className="circle-div">
                          <div className="main-bg-darkpurple text-center circle-icon">
                            <PlusIcon />
                          </div>
                        </div>
                        <div className="circle-div">
                          <div className="main-color-darkpurple pt-3">
                            ট্রেড লাইসেন্স তৈরী করুন
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>

                  <div className="col-lg-3 col-6 pb-3">
                    <NavLink to="/heir-application-add">
                      <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 h-100 circle-link-lightgreen">
                        <div className="circle-div">
                          <div className="main-bg-lightgreen text-center circle-icon">
                            <PlusIcon />
                          </div>
                        </div>
                        <div className="circle-div">
                          <div className="main-color-lightgreen pt-3">
                            ওয়ারিশানের আবেদন করুন
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </>
              }


              {loading ? <SpinnerPulse color={'dark'} />
                :
                <>
                  <div className="col-lg-3 col-6 pb-3">
                    <NavLink to="/tax-member-list">
                      <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 h-100 circle-link-lightblue">
                        <div className="circle-div">
                          <div className="main-color-lightblue text-center font-50">
                            {dashboardContent.tax_members}
                          </div>
                        </div>
                        <div className="circle-div">
                          <div className="main-color-lightblue pt-3">মোট সদস্য</div>
                        </div>
                      </div>
                    </NavLink>
                  </div>

                  {userDetails.role && userDetails.role === 'UDDOKTA' &&
                    <>
                      <div className="col-lg-3 col-6 pb-3">
                        <NavLink to="/trade-license-list-new">
                          <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 h-100 circle-link-lightorange">
                            <div className="circle-div">
                              <div className="main-color-lightorange text-center font-50">
                                {dashboardContent.trade_licenses}
                              </div>
                            </div>
                            <div className="circle-div">
                              <div className="main-color-lightorange pt-3">
                                মোট ট্রেড লাইসেন্স
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </div>

                      <div className="col-lg-3 col-6 pb-3">
                        <NavLink to="/heir-application-list">
                          <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 h-100 circle-link-darkpurple">
                            <div className="circle-div">
                              <div className="main-color-darkpurple text-center font-50">
                                {dashboardContent.heir_application}
                              </div>
                            </div>
                            <div className="circle-div">
                              <div className="main-color-darkpurple pt-3">
                                মোট ওয়ারিশানের আবেদন
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </div>

                      <div className="col-lg-3 col-6 pb-3">
                        <NavLink to="/certificate-types">
                          <div className="main-bg rounded text-center pt-4 pb-4 pl-5 pr-5 h-100 circle-link-lightgreen">
                            <div className="circle-div">
                              <div className="main-color-lightgreen text-center font-50">
                                {dashboardContent.certificates}
                              </div>
                            </div>
                            <div className="circle-div">
                              <div className="main-color-lightgreen pt-3">
                                মোট প্রত্যয়নপত্রের আবেদন
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </>
                  }
                </>
              }
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>


      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default UserDashboard;
