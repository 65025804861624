import React, { useState } from "react";
import "../assets/css/Login.scss";
import GovBDLogo from "../assets/images/GovBDLogo.png";
import { useNavigate } from "react-router-dom";
import { SpinnerPulse } from "../utilities/SpinnerPulse";
import axios from "axios";
import CustomSnackbar from "../utilities/SnackBar";

const Login = () => {
  // For Password Show Start ----------------
  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  // For Password Show End ----------------

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [loginCredentials, setLoginCredentials] = useState({ role: 'user', email: '', password: '', remember: false })

  const navigate = useNavigate();

  // console.log(loginCredentials)

  // Login function
  const loginSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios.get('/sanctum/csrf-cookie').then(_response => {
      axios.post(`/api/${loginCredentials.role}/login`, loginCredentials).then(res => {
        if (res.status === 200) {
          if (loginCredentials.remember) {
            localStorage.setItem(`${loginCredentials.role}Token`, res.data.access_token);
          } else {
            sessionStorage.setItem(`${loginCredentials.role}Token`, res.data.access_token);
          }
          localStorage.setItem('user', JSON.stringify(res.data.user));
          localStorage.setItem('role', loginCredentials.role);
          navigate(`/${loginCredentials.role === 'user' ? '' : loginCredentials.role}`);
          window.location.reload();
        } else {
          setError(res.data.message);
          setTimeout(() => { setError('') }, 5000);
        }
      }).catch(err => {
        setError(err.response.data.message);
        setTimeout(() => { setError('') }, 5000);
      }).finally(() => {
        setLoading(false);
      });
    });
  }


  return (
    <>
      <div className="row main-bg p-5 m-0 bgLogin">
        <div className="col-xl-4 col-lg-3 col-md-3 col-sm-2"></div>

        {/* login card */}
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-8 main-bg p-5 card" style={{ borderRadius: '10px' }}>

          <div className="text-center p-3">
            <img src={GovBDLogo} alt="GovBDLogo" style={{ width: "100px" }} />
          </div>
          <div className="pb-3">
            <h2 className="text-center">লগ ইন</h2>
          </div>

          <form onSubmit={loginSubmit}>
            {/* role select */}
            <div className="mb-3">
              <label className='form-label' htmlFor="loginAs">ভূমিকা</label>

              <div className="d-flex align-items-center">
                <div type='button' className={`btn pe-3 border w-100 d-flex justify-content-between align-items-center ${loginCredentials.role === 'user' && 'border-primary'}`}
                  onClick={() => setLoginCredentials({ ...loginCredentials, role: 'user' })} >
                  <span><i className="fas fa-user-edit text-secondary mr-1"></i> উদ্যোক্তা</span>
                  <i className={`far fa-${loginCredentials.role === 'user' ? 'dot-circle text-primary' : 'circle text-grey'}`}></i>
                </div>

                <div type='button' className={`btn pe-3 border w-100 ml-3 d-flex justify-content-between align-items-center ${loginCredentials.role === 'chairman' && 'border-primary'}`}
                  onClick={() => setLoginCredentials({ ...loginCredentials, role: 'chairman' })} >
                  <span><i className="fas fa-user-cog text-secondary mr-1"></i> চেয়ারম্যান</span>
                  <i className={`far fa-${loginCredentials.role === 'chairman' ? 'dot-circle text-primary' : 'circle text-grey'}`}></i>
                </div>
              </div>
            </div>

            {/* email */}
            <div className="form-group">
              <label htmlFor="email">ইমেইল</label>

              <input type="email" className="form-control" id="email" placeholder="ইউজারনেম" value={loginCredentials.email}
                onChange={(e) => setLoginCredentials({ ...loginCredentials, email: e.target.value })} required />
            </div>

            {/* password */}
            <div className="form-group">
              <label htmlFor="password">পাসওয়ার্ড</label>
              <div className="input-group">

                <input type={passwordShown ? "text" : "password"} className="form-control" id="password"
                  placeholder="পাসওয়ার্ড" required value={loginCredentials.password}
                  onChange={(e) => setLoginCredentials({ ...loginCredentials, password: e.target.value })} />

                <span className="input-group-append">
                  <button type="button" className="btn btn-secondary" onClick={togglePassword}>
                    <i className={passwordShown ? "fas fa-eye" : "fas fa-eye-slash"}></i>
                  </button>
                </span>
              </div>
            </div>

            {/* remember me */}
            <label htmlFor="remember" className='form-label mb-3'>
              <input className="mr-2" type="checkbox" id="remember" checked={loginCredentials.remember}
                onChange={(e) => setLoginCredentials({ ...loginCredentials, remember: e.target.checked })} />
              আমাকে লগইন করে রাখুন
            </label>

            <div className="pt-1 pb-2">
              <button type="submit" className="btn btn-block btn-lightblue" disabled={loading}>
                {loading ? <SpinnerPulse /> : 'প্রবেশ করুন'}
              </button>
            </div>
          </form>
        </div>
        <div className="col-xl-4 col-lg-3 col-md-3 col-sm-2"></div>
      </div>



      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};

export default Login;
