import React, { useCallback, useEffect, useState } from "react";
import "../../../assets/css/TaxCollectionWordWiseListPrint.scss";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import CustomSnackbar from "../../../utilities/SnackBar";
import { SingleTaxPrintPage } from "./SingleTaxPrintPage";

const TaxCollectionUserWiseListPrint = () => {

  const taxMemberInfo = useLocation().state.tax_member;

  const [userDetails] = useLocalStorage('user', {});

  const [error, setError] = useState('')
  const [role, setRole] = useState('')

  const [economicYear, setEconomicYear] = useState({});
  const [houseTaxes, setHouseTaxes] = useState([])

  // get current economic year
  const getEconomicYear = useCallback(() => {
    axios.get(`/api/${role}/current-economic-year`).then(res => {
      if (res.status === 200) {
        setEconomicYear(res.data.current_year)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    });
  }, [role]);

  // get all houseTaxes
  const getHouseTaxes = useCallback(() => {
    axios.get(`/api/${role}/house-taxes`).then(res => {
      if (res.status === 200) {
        setHouseTaxes(res.data.taxes)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    });
  }, [role])


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getEconomicYear();
      getHouseTaxes();
    }
  }, [getEconomicYear, getHouseTaxes, role]);

  return (
    <>
      <div className="row main-bg pl-5 pr-5 m-0">
        {/* ---------------- Single Page Start ---------------- */}
        <SingleTaxPrintPage
          taxMember={taxMemberInfo}
          userDetails={userDetails}
          economicYear={economicYear}
          houseTaxes={houseTaxes}
        />
        {/* ---------------- Single Page End ---------------- */}
      </div>


      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
    </>
  );
};


export default TaxCollectionUserWiseListPrint;
