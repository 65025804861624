import React from "react";

const EyeIcon = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.00004 8.16671C7.64437 8.16671 8.16671 7.64437 8.16671 7.00004C8.16671 6.35571 7.64437 5.83337 7.00004 5.83337C6.35571 5.83337 5.83337 6.35571 5.83337 7.00004C5.83337 7.64437 6.35571 8.16671 7.00004 8.16671Z"
          stroke="#47B5FD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="iconBGColors"
        />
        <path
          d="M12.8333 6.99996C11.2775 9.72238 9.33329 11.0833 6.99996 11.0833C4.66663 11.0833 2.72238 9.72238 1.16663 6.99996C2.72238 4.27754 4.66663 2.91663 6.99996 2.91663C9.33329 2.91663 11.2775 4.27754 12.8333 6.99996Z"
          stroke="#47B5FD"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="iconBGColors"
        />
        <defs>
          <clipPath id="clip0_51_3893">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default EyeIcon;
