import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import UserIcon from "../../assets/images/UserIcon.png";
import DashboardIcon from "../../assets/icons/DashboardIcon";
import SettingIcon from "../../assets/icons/SettingIcon";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import OnlineApplicationIcon from "../../assets/icons/OnlineApplicationIcon";
import TaxMemberIcon from "../../assets/icons/TaxMemberIcon";
import TaxRecieveIcon from "../../assets/icons/TaxRecieveIcon";
import TradeLicenseIcon from "../../assets/icons/TradeLicenseIcon";
import HeirIcon from "../../assets/icons/HeirIcon";
import AffidavitIcon from "../../assets/icons/AffidavitIcon";
import IncomeExpenseIcon from "../../assets/icons/IncomeExpenseIcon";
import BirthcertificateIcon from "../../assets/icons/BirthcertificateIcon";
import ReportIcon from "../../assets/icons/ReportIcon";
import AdvantageListIcon from "../../assets/icons/AdvantageListIcon";
import BettermentProjectIcon from "../../assets/icons/BettermentProjectIcon";
import BeneficiaryIcon from "../../assets/icons/BeneficiaryIcon";
import axios from "axios";
import { useLocalStorage } from "usehooks-ts";
import config from "../../config";
import CustomSnackbar from "../../utilities/SnackBar";
import PasswordChangeDialog from "../../utilities/PasswordChangeDialog";

export default function UserSideNav() {

  const [error, setError] = useState()
  const [userDetails] = useLocalStorage('user', {})
  const [fetchedUser, setFetchedUser] = useState({})
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState({});
  const [openChangePass, setOpenChangePass] = useState(false);

  // console.log(userDetails)

  // get user details
  const getUser = useCallback(() => {
    axios.get('/api/user/profile').then(res => {
      if (res.status === 200) {
        setFetchedUser(res.data.user)
        if (fetchedUser.mustChangePass) {
          setOpenChangePass(true)
        }
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    });
  }, [fetchedUser.mustChangePass])


  // Logout function
  const handleLogOut = () => {
    axios.post('/api/user/logout').then(res => {
      if (res.status === 200) {
        localStorage.clear()
        sessionStorage.clear()
        navigate('/login')
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    });
  }


  // Check if the current route matches a specific keyword
  const checkActiveMenu = useCallback((menuKeyword) => {
    return location.pathname.includes(menuKeyword);
  }, [location.pathname]);

  useEffect(() => {
    setOpenMenu({
      onlineApplication: checkActiveMenu("online-application"),
      taxMember: checkActiveMenu("tax-member"),
      taxCollection: checkActiveMenu("tax-collection"),
      tradeLicense: checkActiveMenu("trade-license"),
      heirApplication: checkActiveMenu("heir-application"),
      settings: checkActiveMenu("settings"),
      certificate: checkActiveMenu("certificate"),
    });

    getUser()
  }, [getUser, checkActiveMenu])

  // const toggleMenu = (menu) => {
  //   setOpenMenu((prevState) => {
  //     return { ...prevState, [menu]: !prevState[menu] };
  //   });
  // };

  return (
    <div>
      <aside className="main-sidebar main-sidebar-custom sidebar-light-primary elevation-4s">
        <a href="/" className="brand-link text-center border-1-lightclay">
          <img src={logo} alt="SmartUp_Logo" style={{ width: "50px" }} />
        </a>

        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex align-items-center border-1-lightclay">
            <div className="ml-1">
              <img src={userDetails.photo !== null ? `${config.baseUrl}/storage/${userDetails.photo}` : UserIcon} alt="User_Image" className="userImg elevation-2" />
            </div>
            <div className="info">
              <p className="m-0 d-block font-14 text-hide-ellipsis">
                {userDetails.name}
              </p>
              <p className="m-0 p-0 font-12 text-muted">উদ্যোক্তা</p>
            </div>
            <div className="ml-auto mt-2">
              <button className="btn d-block px-1" onClick={handleLogOut} data-toggle="tooltip" title="Log out">
                <LogoutIcon />
              </button>
            </div>
          </div>

          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview" role="menu" data-accordion="false">

              {/* dashboard */}
              <li className="nav-item">
                <NavLink to="/" className="nav-link" end>
                  <DashboardIcon />
                  <p className="ml-3">ড্যাশবোর্ড</p>
                </NavLink>
              </li>

              {/* online appplication */}
              {userDetails.role && userDetails.role === 'UDDOKTA' &&
                <li className={`nav-item ${openMenu["onlineApplication"] ? "menu-is-opening menu-open" : ""}`}>
                  <span role="button" className="nav-link">
                    <OnlineApplicationIcon />
                    <p className="ml-3">
                      অনলাইন আবেদন
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </span>
                  <ul className={`nav nav-treeview ${openMenu["onlineApplication"] ? "d-block" : ""}`}>
                    <li className="nav-item">
                      <NavLink to="/trade-license-add" className="nav-link">
                        <p className="ml-3">ট্রেড লাইসেন্স</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/heir-application-add" className="nav-link">
                        <p className="ml-3">ওয়ারিশান সনদ</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/certificate-add" className="nav-link">
                        <p className="ml-3">প্রত্যয়ন পত্র</p>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              }

              {/* tax member */}
              <li className={`nav-item ${openMenu["taxMember"] ? "menu-is-opening menu-open" : ""}`}>
                <span role="button" className="nav-link">
                  <TaxMemberIcon />
                  <p className="ml-3">
                    করদাতা সদস্য
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </span>
                <ul className={`nav nav-treeview ${openMenu["taxMember"] ? "d-block" : ""}`}>
                  <li className="nav-item">
                    <NavLink to="/add-tax-member" className="nav-link">
                      <p className="ml-3">নতুন সদস্য যোগ করুন</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/tax-member-list" className="nav-link">
                      <p className="ml-3">সবগুলো দেখুন</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/tax-member-registered-list" className="nav-link">
                      <p className="ml-3">রেজিস্টার তালিকা</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              {/* certificates, tax collection, trade license, heir certificate */}
              {userDetails.role && userDetails.role === 'UDDOKTA' &&
                <>
                  {/* tax collection */}
                  <li className={`nav-item ${openMenu["taxCollection"] ? "menu-is-opening menu-open" : ""}`}>
                    <span role="button" className="nav-link">
                      <TaxRecieveIcon />
                      <p className="ml-3">
                        কর আদায়
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </span>
                    <ul className={`nav nav-treeview ${openMenu["taxCollection"] ? "d-block" : ""}`}>
                      <li className="nav-item">
                        <NavLink to="/add-tax-collection" className="nav-link">
                          <p className="ml-3">কর আদায় করুন</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/tax-collection-list" className="nav-link">
                          <p className="ml-3">পরিশোধের তালিকা</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/tax-collection-list-word-wise"
                          className="nav-link"
                        >
                          <p className="ml-3">ওয়ার্ডভিত্তিক বিল তালিকা</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/tax-collection-list-person-wise"
                          className="nav-link"
                        >
                          <p className="ml-3">ব্যাক্তিভিত্তিক বিল তালিকা</p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  {/* trade license */}
                  <li className={`nav-item ${openMenu["tradeLicense"] ? "menu-is-opening menu-open" : ""}`}>
                    <span role="button" className="nav-link">
                      <TradeLicenseIcon />
                      <p className="ml-3">
                        ট্রেড লাইসেন্স‍‌
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </span>
                    <ul className={`nav nav-treeview ${openMenu["tradeLicense"] ? "d-block" : ""}`}>
                      <li className="nav-item">
                        <NavLink to="/trade-license-add" className="nav-link">
                          <p className="ml-3">নতুন ট্রেড লাইসেন্স তৈরী</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/trade-license-list-new" className="nav-link">
                          <p className="ml-3">নতুন ট্রেড লাইসেন্স তালিকা</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/trade-license-list-renew" className="nav-link">
                          <p className="ml-3">নবায়নকৃত ট্রেড লাইসেন্স </p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/trade-license-list-yearly" className="nav-link">
                          <p className="ml-3">রেজিস্টার তালিকা</p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  {/* heir certificate */}
                  <li className={`nav-item ${openMenu["heirApplication"] ? "menu-is-opening menu-open" : ""}`}>
                    <span role="button" className="nav-link">
                      <HeirIcon />
                      <p className="ml-3">
                        ওয়ারিশান সনদ
                        <i className="fas fa-angle-left right"></i>
                      </p>
                    </span>
                    <ul className={`nav nav-treeview ${openMenu["heirApplication"] ? "d-block" : ""}`}>
                      <li className="nav-item">
                        <NavLink to="/heir-application-add" className="nav-link">
                          <p className="ml-3">আবেদন করুন</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/heir-application-list" className="nav-link">
                          <p className="ml-3">আবেদন দেখুন</p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  {/* certificates */}
                  <li className={`nav-item ${openMenu["certificate"] ? "menu-is-opening menu-open" : ""}`}>
                    <span role="button" className="nav-link">
                      <AffidavitIcon />
                      <p className="ml-3">প্রত্যয়নপত্র</p>
                      <i className="fas fa-angle-left right"></i>
                    </span>
                    <ul className={`nav nav-treeview ${openMenu["certificate"] ? "d-block" : ""}`}>
                      <li className="nav-item">
                        <NavLink to="/certificate-add" className="nav-link">
                          <p className="ml-3">নতুন প্রত্যয়নপত্র তৈরি</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/certificate-category" className="nav-link">
                          <p className="ml-3">প্রত্যয়নপত্রের ধরন</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/certificate-types" className="nav-link">
                          <p className="ml-3">প্রত্যয়নপত্রের তালিকা</p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              }

              {/* settings */}
              <li className={`nav-item ${openMenu["settings"] ? "menu-is-opening menu-open" : ""}`}>
                <span role="button" className="nav-link">
                  <SettingIcon />
                  <p className="ml-3">
                    সেটিংস
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </span>
                <ul className={`nav nav-treeview ${openMenu["settings"] ? "d-block" : ""}`}>
                  <li className="nav-item">
                    <NavLink to="/software-settings" className="nav-link">
                      <p className="ml-3">সফটওয়্যার সেটিংস</p>
                    </NavLink>
                  </li>
                  {userDetails.role && userDetails.role === 'UDDOKTA' &&
                    <>
                      <li className="nav-item">
                        <NavLink to="/SettingsWebsite" className="nav-link">
                          <p className="ml-3">ওয়েবসাইট সেটিংস</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/union-settings" className="nav-link">
                          <p className="ml-3">ইউনিয়ন সেটিংস</p>
                        </NavLink>
                      </li>
                    </>
                  }
                </ul>
              </li>

              {/* income-expense, birth certificate, report, */}
              {userDetails.role && userDetails.role === 'UDDOKTA' &&
                <>
                  <li className="nav-item">
                    <NavLink to="/ComingSoon4" className="nav-link">
                      <IncomeExpenseIcon />
                      <p className="ml-3">আয়-ব্যয় হিসাব</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/ComingSoon5" className="nav-link">
                      <BirthcertificateIcon />
                      <p className="ml-3">জন্মনিবন্ধন</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/ComingSoon6" className="nav-link">
                      <ReportIcon />
                      <p className="ml-3">রিপোর্ট</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/ComingSoon7" className="nav-link">
                      <AdvantageListIcon />
                      <p className="ml-3">উপকারভোগীর তালিকা</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/ComingSoon8" className="nav-link">
                      <BettermentProjectIcon />
                      <p className="ml-3">উন্নয়নমূলক প্রকল্প</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/ComingSoon9" className="nav-link">
                      <BeneficiaryIcon />
                      <p className="ml-3">ভাতাভোগী</p>
                    </NavLink>
                  </li>
                </>
              }

            </ul>
          </nav>
        </div>

      </aside>



      {/* utilities */}
      <CustomSnackbar message={error} status={'error'} />
      <PasswordChangeDialog level={'user'} onOpen={openChangePass} onClose={() => { getUser(); setOpenChangePass(false) }}
        mustChange={fetchedUser.mustChangePass ? true : false} />
    </div>
  );
}

