import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { SpinnerPulse } from "../../../utilities/SpinnerPulse";
import CustomSnackbar from "../../../utilities/SnackBar";

const TaxMemberAdd = () => {

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('')
  const [role, setRole] = useState('')
  const navigate = useNavigate();

  const [words, setWords] = useState([])
  const [houseTaxes, setHouseTaxes] = useState([])
  const [businessTaxes, setBusinessTaxes] = useState([])
  let taxMemberOptions = {
    word_id: "", village_id: "", holding_no: "", name_of_khana_prodhan: "", fathers_name: "", mothers_name: "", spouse_name: "",
    gender: "পুরুষ", nid: "", date_of_birth: convertDate(new Date()), phone: "", occupation: "", religion: "ইসলাম",
    number_of_male: "", number_of_female: "", road_no: "", block: "",
    houses_info: [{ house_tax_id: '', number_of_room: 1, situation: "করযোগ্য" }], business_info: [], comment: "",
    previous_holding_no: "", email: "", merital_status: "বিবাহিত", male_citizen: "", female_citizen: "", digital_birth_reg: "আছে", bank_account: "আছে",
    money_trnsfer: "", telecommunication: "", paved_bathroom: "আছে", tubewell: "আছে", foreigner: "নাই", electricity: "আছে", tv: "আছে", tv_color: "রঙিন",
    dish_line: "আছে", internet: "আছে", digital_device: "", internet_usage: "", educational_qualification: "", family_status: "", freedom_fighter: "",
    healthcare: "", govt_facility: "",
  }
  const [taxMemberInfo, setTaxMemberInfo] = useState(taxMemberOptions);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTaxMemberInfo({ ...taxMemberInfo, [name]: value });
  };

  // handle house add remove
  const handleAddHouse = () => {
    setTaxMemberInfo({
      ...taxMemberInfo,
      houses_info: [
        ...taxMemberInfo.houses_info,
        { house_tax_id: "", number_of_room: 1, situation: "করযোগ্য" },
      ],
    });
  };
  const handleRemoveHouse = (e, index) => {
    const list = [...taxMemberInfo.houses_info];
    list.splice(index, 1);
    setTaxMemberInfo({
      ...taxMemberInfo,
      houses_info: list,
    });
  };

  // handle business add remove
  const handleAddBusiness = () => {
    setTaxMemberInfo({
      ...taxMemberInfo,
      business_info: [
        ...taxMemberInfo.business_info,
        { business_tax_id: "" },
      ],
    });
  };
  const handleRemoveBusiness = (e, index) => {
    const list = [...taxMemberInfo.business_info];
    list.splice(index, 1);
    setTaxMemberInfo({
      ...taxMemberInfo,
      business_info: list,
    });
  };

  // console.log(taxMemberInfo)

  // handle checkbox change
  const handleCheckboxChange = (event, field) => {
    const { id, checked } = event.target;
    setTaxMemberInfo((prevState) => {
      const newValue = checked
        ? prevState[field].length ? `${prevState[field]}, ${id}` : id
        : prevState[field].split(', ').filter(item => item !== id).join(', ');

      return {
        ...prevState,
        [field]: newValue,
      };
    });
  };


  // get all words
  const getWords = useCallback(() => {
    setLoading(true);
    axios.get(`/api/${role}/all-words`).then(res => {
      if (res.status === 200) {
        setWords(res.data.words)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    });
  }, [role])

  // get all house taxes
  const getHouseTaxes = useCallback(() => {
    axios.get(`/api/${role}/house-taxes`).then(res => {
      if (res.status === 200) {
        setHouseTaxes(res.data.taxes)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    });
  }, [role])

  // get all business taxes
  const getBusinessTaxes = useCallback(() => {
    axios.get(`/api/${role}/business-taxes`).then(res => {
      if (res.status === 200) {
        setBusinessTaxes(res.data.taxes)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    });
  }, [role])

  // store tax member
  const storeTaxMember = () => {
    setLoading(true);
    axios.post(`/api/${role}/tax-member`, taxMemberInfo).then(res => {
      if (res.status === 200) {
        setSuccess(res.data.message)
        setTaxMemberInfo(taxMemberOptions)
        setTimeout(() => { setSuccess('') }, 5000)
      } else {
        setError(res.data.message)
        setTimeout(() => { setError('') }, 5000)
      }
    }).catch(err => {
      setError(err.response.data.message)
      setTimeout(() => { setError('') }, 5000)
    }).finally(() => {
      setLoading(false)
    })
  }


  useEffect(() => {
    setRole(localStorage.getItem('role'));

    if (role !== '') {
      getWords();
      getHouseTaxes();
      getBusinessTaxes();
    }
  }, [getBusinessTaxes, getHouseTaxes, getWords, role]);


  return (
    <>
      <div className="content-wrapper">
        {/* ---------------- Content Header Page Header ---------------- */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">খানা প্রধানের তথ্য</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/">হোম</NavLink>
                  </li>
                  <li className="breadcrumb-item active">খানা প্রধানের তথ্য</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- Main Content Start ---------------- */}
        <section className="content">
          <div className="container-fluid">
            {/* ---------------- Container Start ---------------- */}
            <div className="row">

              <div className="col-lg-3 col-md-3 col-sm-3 ml-auto mb-2 mb-sm-0">
                <button type="button" className="btn btn-block btn-outline-dark"
                  onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/add-tax-member/import`); }}>
                  ইমপোর্ট করুন
                </button>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <button type="button" className="btn btn-block btn-lightblue"
                  onClick={() => { navigate(`${role === 'user' ? '' : '/chairman'}/tax-member-list`); }}>
                  সবগুলো দেখুন
                </button>
              </div>
            </div>


            <div className="row mt-3 p-3 rounded main-bg">
              {/* select word */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ওয়ার্ড নং</label>
                  <select className="form-control" name="word_id" value={taxMemberInfo.word_id}
                    onChange={(e) => setTaxMemberInfo({ ...taxMemberInfo, word_id: e.target.value, village_id: '' })}>
                    <option value=''>নির্বাচন করুন</option>
                    {words.map((word, index) =>
                      <option key={index} value={word.id}>{word.word}</option>
                    )}
                  </select>
                </div>
              </div>
              {/* select village of word */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">গ্রাম</label>
                  <select className="form-control" name="village_id" value={taxMemberInfo.village_id} onChange={handleChange}
                    disabled={taxMemberInfo.word_id === '' ? true : false}>
                    <option value=''>নির্বাচন করুন</option>
                    {words.find(word => word.id === parseInt(taxMemberInfo.word_id)) &&
                      words.find(word => word.id === parseInt(taxMemberInfo.word_id)).villages.map((village, index) =>
                        <option key={index} value={village.id}>{village.name}</option>
                      )}
                  </select>
                </div>
              </div>

              {/* road block no. */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">রোড নং</label>
                  <input type="text" className="form-control" id="text" placeholder="রোড নং *" name="road_no"
                    value={taxMemberInfo.road_no} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ব্লক</label>
                  <input type="text" className="form-control" id="text" placeholder="ব্লক *" name="block"
                    value={taxMemberInfo.block} onChange={handleChange} />
                </div>
              </div>

              {/* name of khana prodhan */}
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">খানা প্রধানের নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="খানা প্রধানের নাম" name="name_of_khana_prodhan"
                    value={taxMemberInfo.name_of_khana_prodhan} onChange={handleChange} />
                </div>
              </div>

              {/* holding */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">হোল্ডিং নম্বর</label>
                  <input type="text" className="form-control" id="text" placeholder="হোল্ডিং নম্বর" name="holding_no"
                    value={taxMemberInfo.holding_no} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">আগের হোল্ডিং নম্বর</label>
                  <input type="text" className="form-control" id="text" placeholder="আগের হোল্ডিং নম্বর" name="previous_holding_no"
                    value={taxMemberInfo.previous_holding_no} onChange={handleChange} />
                </div>
              </div>

              {/* father mother name */}
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পিতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="পিতার নাম" name="fathers_name"
                    value={taxMemberInfo.fathers_name} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">মাতার নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="মাতার নাম" name="mothers_name"
                    value={taxMemberInfo.mothers_name} onChange={handleChange} />
                </div>
              </div>

              {/* spouse name */}
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">স্বামী/স্ত্রী-র নাম</label>
                  <input type="text" className="form-control" id="text" placeholder="স্বামী/স্ত্রী-র নাম" name="spouse_name"
                    value={taxMemberInfo.spouse_name} onChange={handleChange} />
                </div>
              </div>

              {/* nid no. */}
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ভোটার আইডি নং</label>
                  <input type="number" className="form-control" id="text" placeholder="ভোটার আইডি নং" name="nid"
                    value={taxMemberInfo.nid} onChange={handleChange} />
                </div>
              </div>

              {/* gender */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">লিঙ্গ</label>
                  <select className="form-control" name="gender" value={taxMemberInfo.gender} onChange={handleChange}>
                    <option value={"পুরুষ"}>পুরুষ</option>
                    <option value={"নারী"}>নারী</option>
                    <option value={"অন্যান্য"}>অন্যান্য</option>
                  </select>
                </div>
              </div>

              {/* date of birth */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">জন্ম তারিখ</label>
                  <input type="date" className="form-control" id="text" placeholder="জন্ম তারিখ" name="date_of_birth"
                    value={taxMemberInfo.date_of_birth} onChange={handleChange} />
                </div>
              </div>

              {/* phone email */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">মোবাইল নং</label>
                  <input type="text" className="form-control" id="text" placeholder="মোবাইল নং" name="phone"
                    value={taxMemberInfo.phone} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ই-মেইল (যদি থাকে)</label>
                  <input type="text" className="form-control" id="text" placeholder="ই-মেইল" name="email"
                    value={taxMemberInfo.email} onChange={handleChange} />
                </div>
              </div>

              {/* religion */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ধর্ম</label>
                  <select className="form-control" name="religion" value={taxMemberInfo.religion} onChange={handleChange}>
                    <option value={"ইসলাম"}>ইসলাম</option>
                    <option value={"হিন্দু"}>হিন্দু</option>
                    <option value={"বৌদ্ধ"}>বৌদ্ধ</option>
                    <option value={"খ্রিস্টান"}>খ্রিস্টান</option>
                    <option value={"অন্যান্য"}>অন্যান্য</option>
                  </select>
                </div>
              </div>

              {/* merital status */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">বৈবাহিক অবস্থা</label>
                  <select className="form-control" name="merital_status" value={taxMemberInfo.merital_status} onChange={handleChange}>
                    <option value={"বিবাহিত"}>বিবাহিত</option>
                    <option value={"অবিবাহিত"}>অবিবাহিত</option>
                  </select>
                </div>
              </div>

              {/* number of male female */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পুরুষ সংখ্যা</label>
                  <input type="number" className="form-control" id="text" placeholder="পুরুষ সংখ্যা" name="number_of_male"
                    value={taxMemberInfo.number_of_male} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">নারী সংখ্যা</label>
                  <input type="number" className="form-control" id="text" placeholder="নারী সংখ্যা" name="number_of_female"
                    value={taxMemberInfo.number_of_female} onChange={handleChange} />
                </div>
              </div>

              {/* number of male female citizens */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পুরুষ ভোটার সংখ্যা</label>
                  <input type="number" className="form-control" id="text" placeholder="পুরুষ ভোটার সংখ্যা" name="male_citizen"
                    value={taxMemberInfo.male_citizen} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">নারী ভোটার সংখ্যা</label>
                  <input type="number" className="form-control" id="text" placeholder="নারী ভোটার সংখ্যা" name="female_citizen"
                    value={taxMemberInfo.female_citizen} onChange={handleChange} />
                </div>
              </div>

              {/* digital birth registration */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ডিজিটাল জন্ম নিবন্ধন</label>
                  <select type="text" className="form-control" name="digital_birth_reg"
                    value={taxMemberInfo.digital_birth_reg} onChange={handleChange} >
                    <option value="আছে">আছে</option>
                    <option value="নাই">নাই</option>
                  </select>
                </div>
              </div>

              {/* bank account */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ব্যাংক হিসাব</label>
                  <select type="text" className="form-control" name="bank_account"
                    value={taxMemberInfo.bank_account} onChange={handleChange} >
                    <option value="আছে">আছে</option>
                    <option value="নাই">নাই</option>
                  </select>
                </div>
              </div>

              {/* money transfer */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>আর্থিক লেনদেন</label>
                <div className="border rounded d-flex flex-wrap py-2 px-1 mb-3 bg-light">

                  {["বিকাশ", "নগদ", "রকেট", "উপায়", "অন্যান্য"].map(item => (
                    <div className="form-check mx-2" key={item}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        checked={taxMemberInfo.money_trnsfer.includes(item)}
                        onChange={(e) => handleCheckboxChange(e, "money_trnsfer")}
                      />
                      <label className="form-check-label" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* telecommunication */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>টেলিকমিউনিকেশন</label>
                <div className="border rounded d-flex flex-wrap py-2 px-1 mb-3 bg-light">

                  {["টেলিটক", "গ্রামীনফোন", "এয়ারটেল", "রবি", "বাংলালিংক", "টিএনটি"].map(item => (
                    <div className="form-check mx-2" key={item}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        checked={taxMemberInfo.telecommunication.includes(item)}
                        onChange={(e) => handleCheckboxChange(e, "telecommunication")}
                      />
                      <label className="form-check-label" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* paved bathroom, tubewell */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">পাকা বাথরুম</label>
                  <select type="text" className="form-control" name="paved_bathroom"
                    value={taxMemberInfo.paved_bathroom} onChange={handleChange} >
                    <option value="আছে">আছে</option>
                    <option value="নাই">নাই</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">নলকূপ</label>
                  <select type="text" className="form-control" name="tubewell"
                    value={taxMemberInfo.tubewell} onChange={handleChange} >
                    <option value="আছে">আছে</option>
                    <option value="নাই">নাই</option>
                  </select>
                </div>
              </div>

              {/* foreigner */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">বিদেশে থাকে / প্রবাসী</label>
                  <select type="text" className="form-control" name="foreigner"
                    value={taxMemberInfo.foreigner} onChange={handleChange} >
                    <option value="আছে">আছে</option>
                    <option value="নাই">নাই</option>
                  </select>
                </div>
              </div>

              {/* electricity */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">বিদ্যুৎ সংযোগ</label>
                  <select type="text" className="form-control" name="electricity"
                    value={taxMemberInfo.electricity} onChange={handleChange} >
                    <option value="আছে">আছে</option>
                    <option value="নাই">নাই</option>
                  </select>
                </div>
              </div>


              {/* tv, color, dish line */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">টিভি</label>
                  <select type="text" className="form-control" name="tv"
                    value={taxMemberInfo.tv} onChange={handleChange} >
                    <option value="আছে">আছে</option>
                    <option value="নাই">নাই</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">টিভি কালার</label>
                  <select type="text" className="form-control" name="tv_color"
                    value={taxMemberInfo.tv_color} onChange={handleChange} >
                    <option value="সাদা-কালো">সাদা-কালো</option>
                    <option value="রঙিন">রঙিন</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ডিস লাইন</label>
                  <select type="text" className="form-control" name="dish_line"
                    value={taxMemberInfo.dish_line} onChange={handleChange} >
                    <option value="আছে">আছে</option>
                    <option value="নাই">নাই</option>
                  </select>
                </div>
              </div>

              {/* internet */}
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="text">ইন্টারনেট</label>
                  <select type="text" className="form-control" name="internet"
                    value={taxMemberInfo.internet} onChange={handleChange} >
                    <option value="আছে">আছে</option>
                    <option value="নাই">নাই</option>
                  </select>
                </div>
              </div>

              {/* digital devices */}
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label>ডিজিটাল ডিভাইস</label>
                <div className="border rounded d-flex flex-wrap py-2 px-1 mb-3 bg-light">

                  {["নরমাল মোবাইল", "স্মার্টফোন", "কম্পিউটার", "ল্যাপটপ", "স্মার্ট টিভি", "সিসি ক্যামেরা"].map(item => (
                    <div className="form-check mx-2" key={item}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        checked={taxMemberInfo.digital_device.includes(item)}
                        onChange={(e) => handleCheckboxChange(e, "digital_device")}
                      />
                      <label className="form-check-label" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* internet usage */}
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label>ইন্টারনেট ব্যবহার</label>
                <div className="border rounded d-flex flex-wrap py-2 px-1 mb-3 bg-light">

                  {["ফেসবুক", "ইউটিউব", "অনলাইন-শপিং", "ই-মেইল", "ইমো", "হোয়াটসঅ্যাপ", "অন্যান্য "].map(item => (
                    <div className="form-check mx-2" key={item}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        checked={taxMemberInfo.internet_usage.includes(item)}
                        onChange={(e) => handleCheckboxChange(e, "internet_usage")}
                      />
                      <label className="form-check-label" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* educational qualification */}
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label>শিক্ষাগত যোগ্যতা</label>
                <div className="border rounded d-flex flex-wrap py-2 px-1 mb-3 bg-light">

                  {["স্ব-শিক্ষিত", "প্রাথমিক", " মাধ্যমিক", "উচ্চ-মাধ্যমিক", "উচ্চতর ডিগ্রী"].map(item => (
                    <div className="form-check mx-2" key={item}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        checked={taxMemberInfo.educational_qualification.includes(item)}
                        onChange={(e) => handleCheckboxChange(e, "educational_qualification")}
                      />
                      <label className="form-check-label" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Family satus */}
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label>পরিবারের অবস্থা</label>
                <div className="border rounded d-flex flex-wrap py-2 px-1 mb-3 bg-light">

                  {["হতদরিদ্র", "নিম্ন-মধ্যবিত্ত", " মধ্যবিত্ত", "উচ্চ-মধ্যবিত্ত", "উচ্চবিত্ত"].map(item => (
                    <div className="form-check mx-2" key={item}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        checked={taxMemberInfo.family_status.includes(item)}
                        onChange={(e) => handleCheckboxChange(e, "family_status")}
                      />
                      <label className="form-check-label" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* freedom fighter */}
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label>মুক্তিযোদ্ধা</label>
                <div className="border rounded d-flex flex-wrap py-2 px-1 mb-3 bg-light">

                  {["বীর উত্তম", "বীর বিক্রম", "বীর প্রতীক", "বীরাঙ্গনা", "বীর মুক্তিযোদ্ধা"].map(item => (
                    <div className="form-check mx-2" key={item}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        checked={taxMemberInfo.freedom_fighter.includes(item)}
                        onChange={(e) => handleCheckboxChange(e, "freedom_fighter")}
                      />
                      <label className="form-check-label" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* health care and medical */}
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label>স্বাস্থ্যসেবা ও চিকিৎসা</label>
                <div className="border rounded d-flex flex-wrap py-2 px-1 mb-3 bg-light">

                  {[" সরকারি হাসপাতাল", "বেসরকারি হাসপাতাল", "কমিউনিটি ক্লিনিক", "হোমিও চিকিৎসা", "আয়ুর্বেদিক চিকিৎসা"].map(item => (
                    <div className="form-check mx-2" key={item}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        checked={taxMemberInfo.healthcare.includes(item)}
                        onChange={(e) => handleCheckboxChange(e, "healthcare")}
                      />
                      <label className="form-check-label" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* govt facilities */}
              <div className="col-lg-12 col-md-12 col-sm-12">
                <label>সরকারি সুবিধা</label>
                <div className="border rounded d-flex flex-wrap py-2 px-1 mb-3 bg-light">

                  {["ভিজিএফ কার্ড", "বয়স্ক ভাতা", "মুক্তিযোদ্ধা ভাতা", "প্রতিবন্ধী ভাতা", "মাতৃত্বকালীন ভাতা", "বিধবা ভাতা"].map(item => (
                    <div className="form-check mx-2" key={item}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        checked={taxMemberInfo.govt_facility.includes(item)}
                        onChange={(e) => handleCheckboxChange(e, "govt_facility")}
                      />
                      <label className="form-check-label" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* occupation */}
              <div className="col-lg-12 col-md-12 col-sm-12">
                <label>পেশা বা আয়ের উৎস</label>
                <div className="border rounded d-flex flex-wrap py-2 px-1 mb-3 bg-light">

                  {["চাকুরী (সরকারি)", "চাকুরী (বেসরকারি)", "শিক্ষক", "কৃষক", "ব্যবসা", "দলিল লেখক", "প্রকৌশলী", "আইনজীবী", "চিকিৎসক", "নারী উদ্যোক্তা", "শিল্প উদ্যোক্তা", "প্রবাসী", "কনসালটেন্সি", "ঠিকাদার", "মৎস্য চাষী", "গাড়ি চালক"].map(item => (
                    <div className="form-check mx-2" key={item}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        checked={taxMemberInfo.occupation.includes(item)}
                        onChange={(e) => handleCheckboxChange(e, "occupation")}
                      />
                      <label className="form-check-label" htmlFor={item}>
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>




              {/*------------------House Info and taxes-----------------*/}
              <div className="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3">
                <h4 className="text-center">বসত ঘর/অবকাঠামোর ধরন</h4>
              </div>

              {/* Dynamic house table */}
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
                <table className="table table-striped" width="100%;">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">#</th>
                      <th scope="col" className="text-center">বাড়ির ধরন</th>
                      <th scope="col" className="text-center">ঘরের সংখ্যা</th>
                      <th scope="col" className="text-center">অবস্থা</th>
                      <th scope="col" className="text-right">কর</th>
                      <th scope="col" className="text-center actionTable">একশন</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taxMemberInfo.houses_info.map((houseInfo, index) => (
                      <HouseTableRow
                        key={index}
                        index={index}
                        houseInfo={houseInfo}
                        taxOfHouse={houseInfo.situation === "করযোগ্য" ?
                          houseTaxes.find(tax => tax.id === parseInt(houseInfo.house_tax_id))?.tax : 0}
                        taxMemberInfo={taxMemberInfo}
                        setTaxMemberInfo={setTaxMemberInfo}
                        handleAdd={handleAddHouse}
                        handleRemove={handleRemoveHouse}
                        houseTypeTaxes={houseTaxes}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th> </th>
                      <th> </th>
                      <th> </th>
                      <th scope="row" className="text-center">মোট কর</th>
                      <th scope="row" className="text-right">
                        ৳ {taxMemberInfo.houses_info.reduce((acc, houseInfo) => acc +
                          parseInt(houseInfo.situation === "করযোগ্য" ?
                            houseTaxes.find(tax => tax.id === parseInt(houseInfo.house_tax_id))?.tax : 0), 0)}
                      </th>
                      <th className="text-center"> </th>
                    </tr>
                  </tfoot>
                </table>
              </div>


              {/*------------------Business Info and taxes-----------------*/}
              <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-3">
                <h4 className="text-center">ব্যবসায়িক করের উৎস
                  {taxMemberInfo.business_info.length === 0 &&
                    <button className="btn btn-outline-info btn-sm ml-3" onClick={handleAddBusiness}>+ যুক্ত করুন</button>}
                </h4>
              </div>

              {/* Dynamic business table */}
              <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
                <table className="table table-striped" width="100%;">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">#</th>
                      <th scope="col" className="text-center">ব্যবসার ধরন</th>
                      <th scope="col" className="text-right">কর</th>
                      <th scope="col" className="text-center actionTable">একশন</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taxMemberInfo.business_info.length > 0 ?
                      taxMemberInfo.business_info.map((businessInfo, index) => (
                        <BusinessTableRow
                          key={index}
                          index={index}
                          businessInfo={businessInfo}
                          taxOfHouse={businessTaxes.find(tax => tax.id === parseInt(businessInfo.business_tax_id))?.tax}
                          taxMemberInfo={taxMemberInfo}
                          setTaxMemberInfo={setTaxMemberInfo}
                          handleAdd={handleAddBusiness}
                          handleRemove={handleRemoveBusiness}
                          businessTypeTaxes={businessTaxes}
                        />
                      ))
                      : <tr>
                        <td className="text-center" colSpan={4}>কোনো ব্যবসা যুক্ত করা হয়নি</td>
                      </tr>
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <th> </th>
                      <th scope="row" className="text-center">মোট কর</th>
                      <th scope="row" className="text-right">
                        ৳ {taxMemberInfo.business_info.reduce((acc, businessInfo) => acc +
                          parseInt(businessTaxes.find(tax => tax.id === parseInt(businessInfo.business_tax_id))?.tax), 0)}
                      </th>
                      <th className="text-center"> </th>
                    </tr>
                  </tfoot>
                </table>
              </div>


              {/* comment */}
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="form-group">
                  <label htmlFor="text">মন্তব্য (যদি থাকে)</label>
                  <textarea type="text" className="form-control" id="text" placeholder="মন্তব্য (যদি থাকে)" name="comment"
                    value={taxMemberInfo.comment} onChange={handleChange} />
                </div>
              </div>

              {/* submit button */}
              <div className="col-lg-4 col-md-4 col-sm-4"></div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <button type="button" className="btn btn-block btn-lightblue" disabled={loading} onClick={storeTaxMember}>
                  {loading ? <SpinnerPulse /> : 'জমা দিন'}
                </button>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4"></div>
            </div>
            {/* ---------------- Container End ---------------- */}
          </div>
        </section>
        {/* ---------------- Main Content End ---------------- */}
      </div>



      {/* utilities */}
      <CustomSnackbar message={success} status={'success'} />
      <CustomSnackbar message={error} status={'error'} />
    </>
  );


  // convert date function
  function convertDate(inputDate) {
    const date = new Date(inputDate);

    // Get the components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');

    // Assemble the formatted date
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
};


// house table row
const HouseTableRow = ({ index, houseInfo, taxOfHouse, taxMemberInfo, setTaxMemberInfo, handleAdd, handleRemove, houseTypeTaxes }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTaxMemberInfo({
      ...taxMemberInfo,
      houses_info: taxMemberInfo.houses_info.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      ),
    });
  };

  return (
    <tr key={0}>
      <th scope="row" className="text-center">
        {index + 1}
      </th>
      <td className="text-center">
        <select className="form-control" name="house_tax_id" value={houseInfo.house_tax_id} onChange={handleChange}>
          <option value=''>নির্বাচন করুন</option>
          {houseTypeTaxes.map((tax, index) =>
            <option key={index} value={tax.id}>{tax.house_type}</option>
          )}
        </select>
      </td>
      <td className="text-center">
        <input type="number" className="form-control" id="text" placeholder="ঘরের সংখ্যা" name="number_of_room"
          value={houseInfo.number_of_room} onChange={handleChange} />
      </td>
      <td className="text-center">
        <select className="form-control" name="situation" value={houseInfo.situation} onChange={handleChange}>
          <option value={"করযোগ্য"}>করযোগ্য</option>
          <option value={"করযোগ্য নয়"}>করযোগ্য নয়</option>
        </select>
      </td>
      <td className="text-right">৳ {taxOfHouse}</td>
      <td className="text-center">
        {index === 0 ? null : (
          <button type="button" className="btn-icon btn-outline-lightblue font-weight-bold mr-2"
            onClick={(e) => handleRemove(e, index)}>-</button>
        )}
        <button type="button" className="btn-icon btn-outline-lightblue font-weight-bold mr-2"
          onClick={handleAdd}>+</button>
      </td>
    </tr>
  );
};


// business table row
const BusinessTableRow = ({ index, businessInfo, taxOfHouse, taxMemberInfo, setTaxMemberInfo, handleAdd, handleRemove, businessTypeTaxes }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTaxMemberInfo({
      ...taxMemberInfo,
      business_info: taxMemberInfo.business_info.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      ),
    });
  };

  return (
    <tr key={0}>
      <th scope="row" className="text-center">
        {index + 1}
      </th>
      <td className="text-center">
        <select className="form-control" name="business_tax_id" value={businessInfo.business_tax_id} onChange={handleChange}>
          <option value=''>নির্বাচন করুন</option>
          {businessTypeTaxes.map((tax, index) =>
            <option key={index} value={tax.id}>{tax.business_type}</option>
          )}
        </select>
      </td>
      <td className="text-right">৳ {taxOfHouse}</td>
      <td className="text-center">
        <button type="button" className="btn-icon btn-outline-lightblue font-weight-bold mr-2"
          onClick={(e) => handleRemove(e, index)}>-</button>

        <button type="button" className="btn-icon btn-outline-lightblue font-weight-bold mr-2"
          onClick={handleAdd}>+</button>
      </td>
    </tr>
  );
};

export default TaxMemberAdd;
