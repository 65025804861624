import React from "react";

const EditIcon = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            d="M8.16663 1.75V4.08333C8.16663 4.23804 8.22808 4.38642 8.33748 4.49581C8.44688 4.60521 8.59525 4.66667 8.74996 4.66667H11.0833"
            stroke="#47B5FD"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="iconBGColors"
          />
          <path
            d="M9.91663 12.25H4.08329C3.77387 12.25 3.47713 12.1271 3.25833 11.9083C3.03954 11.6895 2.91663 11.3928 2.91663 11.0833V2.91667C2.91663 2.60725 3.03954 2.3105 3.25833 2.09171C3.47713 1.87292 3.77387 1.75 4.08329 1.75H8.16663L11.0833 4.66667V11.0833C11.0833 11.3928 10.9604 11.6895 10.7416 11.9083C10.5228 12.1271 10.226 12.25 9.91663 12.25Z"
            stroke="#47B5FD"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="iconBGColors"
          />
          <path
            d="M5.83329 10.5L8.74996 7.58334C8.90467 7.42863 8.99158 7.2188 8.99158 7.00001C8.99158 6.78121 8.90467 6.57138 8.74996 6.41667C8.59525 6.26196 8.38542 6.17505 8.16663 6.17505C7.94783 6.17505 7.738 6.26196 7.58329 6.41667L4.66663 9.33334V10.5H5.83329Z"
            stroke="#47B5FD"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="iconBGColors"
          />
        <defs>
          <clipPath id="clip0_51_3895">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default EditIcon;
