import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { SpinnerPulse } from './SpinnerPulse'

export default function ModalDialog({ title, content, confirmText, onConfirm, loading, onOpen, onClose, isFullScreen, maxWidth, actionColor, disabledAction }) {

  return (
    <Dialog open={onOpen} onClose={onClose} fullScreen={isFullScreen} maxWidth={maxWidth ?? 'xs'}>
      <DialogTitle id="modal-dialog-title" className='d-flex justify-content-between'>
        {title}
        {/* floating action button for close */}
        <button className='btn btn-sm bg-light' onClick={onClose}>
          <i className="fa fa-times"></i>
        </button>
      </DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions className='pr-4 pb-3'>
        <Button onClick={onClose} className='bg-light'>বন্ধ করুন</Button>
        {confirmText &&
          <Button onClick={onConfirm} variant="contained" color={actionColor} disabled={disabledAction | loading}>
            {loading ? <SpinnerPulse /> : confirmText}
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}
