import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../user/layout/Footer";
import UserTopNav from "../../user/layout/TopNav";
import ChairmanSideNav from "./SideNav"

const ChairmanLayout = () => {
  return (
    <div>
      <UserTopNav />
      <ChairmanSideNav />

      <Outlet />

      <Footer />
    </div>
  );
};

export default ChairmanLayout;
