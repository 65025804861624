import React from "react";

const TaxRecieveIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.40913 4.27544H13.5908V4.97927H4.40913V4.27544ZM4.40913 6.74998H13.5908V7.45381H4.40913V6.74998ZM4.40913 9.22452H13.5908V9.92835H4.40913V9.22452ZM15.9041 12.8562V3.33984C15.9041 2.42107 15.5546 1.58591 14.9915 0.980753C14.4284 0.375591 13.6512 0 12.7963 0H2.0957V12.8561L15.9041 12.8562Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9042 13.2079H0V20.0001H18V13.2079H15.904H15.9042ZM3.80339 14.7779H6.99546V15.6801H5.92395V18.4302H4.8739V15.6801H3.80319V14.7779H3.80339ZM9.23952 17.8274H8.04306L7.87855 18.4302H6.80549L8.08264 14.7779H9.2276L10.5055 18.4302H9.40634L9.23929 17.8274H9.23952ZM9.01945 17.0375L8.64364 15.7245L8.27017 17.0375H9.01945ZM10.6077 14.7781H11.7638L12.3667 15.9015L12.9506 14.7781H14.0964L13.0391 16.5469L14.196 18.4305H13.0161L12.3459 17.257L11.6733 18.4305H10.5006L11.6733 16.5274L10.6075 14.7781H10.6077Z"
          className="iconBGColor"
          fill="#9A9AA9"
        />
      </svg>
    </>
  );
};

export default TaxRecieveIcon;
